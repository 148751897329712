<template>
	<div class="container">
		<div v-for="floor in floors" :key="floor" style="border-radius: 1em; overflow: hidden; margin: .5em; float: left; width: 4em; height: 4em; background-color: #0a53be">
			<img :src=floor style="width: 100%; height: 100%; object-fit: cover" v-on:click="this.selectFloor(floor)">
		</div>
	</div>
</template>


<script>
import {eventBus} from "@/main";
import Room from "../building-elements/Room.js";

export default {
	name: "FurnitureOptions",
	props: {
		room: Room
	},
	data() {
		return {
			floors: [],
		}
	},
	mounted() {
		this.floors.push('/floors/floor_1.jpg');
		this.floors.push('/floors/floor_2.jpg');
		this.floors.push('/floors/floor_3.jpg');
		this.floors.push('/floors/floor_4.jpg');
		this.floors.push('/floors/floor_5.jpg');
		this.floors.push('/floors/floor_6.jpg');
		this.floors.push('/floors/floor_7.jpg');
		this.floors.push('/floors/floor_8.jpg');
		this.floors.push('/floors/floor_9.jpg');
		this.floors.push('/floors/floor_10.jpg');
		this.floors.push('/floors/floor_11.jpg');
		this.floors.push('/floors/floor_12.jpg');
		this.floors.push('/floors/floor_13.jpg');
		this.floors.push('/floors/floor_14.jpg');
		this.floors.push('/floors/floor_15.jpg');
		this.floors.push('/floors/floor_16.jpg');
		this.floors.push('/floors/floor_17.jpg');
		this.floors.push('/floors/floor_18.png');
	},
	methods: {
		selectFloor(floor) {
			this.room.setFloorPattern(floor);
		}
	}
}
</script>

<style scoped>
.asset-container {
	border-radius: .51em;
	background-color: #7ca6bf;
}

.asset-container:hover:not(.asset-container-active) {
	background-color: #91b1c4;
}

.asset-container-active {
	background-color: #b6c9d4;
}

</style>
