import {getUser} from "@/components/common/auth/getUser";
import {upsert} from "@/api/RequestHandler";
import {supabase} from "@/api/supabase";

export const user_store = {
	state: () => ({
		user: null
	}),
	mutations: {
		setUser(state, payload) {
			state.user = payload
		},
		upsertUser(state, payload) {
			supabase.auth.updateUser({
				data: {first_name: payload.first_name, last_name: payload.last_name, communication: payload.communication}
			}).then(res => {
				state.user = res.user
			});
		},
		deleteUser(state) {
			supabase.rpc('deleteUser')
				.then(res => {
					state.user = null
					window.location.href = '/'
				})
		}
	},
	getters: {
		getUser(state) {
			if(!state.user) {
				getUser().then(({data, error}) => {
					state.user = data.user
				})
			}

			return state.user
		}
	}
}
