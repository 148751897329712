import InputHandler from "@/components/editor/features/InputHandler";
import Point from "@/components/editor/simple-geometry/Point";
import { BuiltInFurniture } from "@/components/editor/building-elements/BuiltInFurniture";
import {	getSnappingPointAndWallAndNormal } from "@/components/editor/features/SnappingHelper";
import { getAngleBetweenVectors } from "@/components/editor/simple-geometry/Operations";

export default class BIFurnitureHandler extends InputHandler {

	constructor() {
		super();
		this.thing = new BuiltInFurniture(0, 0);
	}

	handleMouseDown(e, data) {
		const p = new Point(
			data.canvas.panX + e.offsetX / data.canvas.scaleFactor,
			data.canvas.panY + e.offsetY / data.canvas.scaleFactor
		);

		const snap = getSnappingPointAndWallAndNormal(data.floorplan, p, true);
		this.thing.position = snap.point;
		data.canvas.drawSvg(this.thing);
	}

	handleMouseUp(e, data) {
		const p = new Point(
			data.canvas.panX + e.offsetX / data.canvas.scaleFactor,
			data.canvas.panY + e.offsetY / data.canvas.scaleFactor
		);

		const snap = getSnappingPointAndWallAndNormal(data.floorplan, p, true);
		this.thing.position = snap.point;
		data.space.push(this.thing);

		this.thing = new BuiltInFurniture(0,0);
	}

	handleMouseMove(e, data) {
		const p = new Point(
			data.canvas.panX + e.offsetX / data.canvas.scaleFactor,
			data.canvas.panY + e.offsetY / data.canvas.scaleFactor
		);

		const snap = getSnappingPointAndWallAndNormal(data.floorplan, p, true);

		if(snap.normal) {
			const angle = getAngleBetweenVectors(this.thing.direction, snap.normal);
			this.thing.rotation = angle;
		}

		this.thing.position = snap.point;
		data.canvas.drawSvg(this.thing);
	}
}
