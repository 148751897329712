import InputHandler from "@/components/editor/features/InputHandler";
import Point from "@/components/editor/simple-geometry/Point";
import {Furniture} from "@/components/editor/building-elements/Furniture";
import {getAngleBetweenPointsInRadians, getAngleBetweenVectors} from "@/components/editor/simple-geometry/Operations";

export default class FurnitureRotateHandler extends InputHandler {

	handleMouseDown(p, element) {
		this.angle = getAngleBetweenPointsInRadians(p, element.position) - element.rotation;
	}

	handleMouseUp(p, element) {}

	handleMouseMove(p, element) {
		const newAngle = getAngleBetweenPointsInRadians(p, element.position);
		element.rotation = newAngle - this.angle;

		element.rotation = snapAngle(element.rotation, .2)
	}
}

function snapAngle(angle, snapFactor) {
	// Ensure angle is between 0 and -2 * PI
	while (angle > 0) {
		angle -= 2 * Math.PI;
	}
	while (angle < -2 * Math.PI) {
		angle += 2 * Math.PI;
	}

	// Snap to the nearest 0.5 * PI if within snapFactor range
	for (let i = 0; i >= -8; i--) {
		const targetAngle = 0.25 * Math.PI * i;
		const angleDifference = Math.abs(angle - targetAngle);

		if (angleDifference <= snapFactor) {
			angle = targetAngle;
			break;
		}
	}

	return angle;
}
