<template>
	<div style="background-color: #F4F4F4; padding: 2em 8% 0 8%; height: 100vh; overflow-y: scroll">
		<div>
			<a href="/">
				<img src="../../assets/logo.png" style="width: 12em; margin-bottom: 8em">
			</a>
		</div>
		<div
			style="filter: drop-shadow(0 0 25px #C6C6C6); border-radius: 1em; font-family: 'Poppins'; background-color: white; padding: 4em 9%; margin-bottom: 5em">
			<h3 style="font-family: 'Poppins SemiBold'; font-size: 3em; margin-bottom: .5em">
				Stay updated
			</h3>

			<form v-on:submit="submitForm()">
				<div class="form-group">
					<label for="exampleInputEmail1">First name</label>
					<input type="text" class="form-control" id="firstname" aria-describedby="emailHelp" placeholder="" style="margin-bottom: 1em">
				</div>
				<div class="form-group">
					<label for="exampleInputEmail1">Last name</label>
					<input type="text" class="form-control" id="lastname" aria-describedby="emailHelp" placeholder="" style="margin-bottom: 1em">
				</div>
				<div class="form-group">
					<label for="exampleInputEmail1">Email address</label>
					<input type="email" class="form-control" id="email-field" aria-describedby="emailHelp" placeholder="" style="margin-bottom: 1.5em">
				</div>
				<button type="submit" class="btn btn-primary" style="background-color: rgb(94,71,159); border: 0">Submit</button>
			</form>
		</div>
	</div>
</template>

<script>
export default {
	name: "NewsletterSignup",
	methods: {
		submitForm() {
			var firstname = document.getElementById('firstname').value;
			var lastname = document.getElementById('lastname').value;
			var email = document.getElementById('email-field').value;

			fetch('https://zmadrxukebkoijfkuyzr.supabase.co/rest/v1/newsletter-signups', {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'apikey': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InptYWRyeHVrZWJrb2lqZmt1eXpyIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzA2MDQwNTAsImV4cCI6MTk4NjE4MDA1MH0.EuXDEdcoA0jUAUOF9wPN2StkDy1SyBBTwKsjfvSClLk'
				},
				body: JSON.stringify({"first_name": firstname, "last_name": lastname, "email": email})
			})

			this.$router.push('/newsletter-confirmation');

			// var url = window.location.href.split('/');
			// url[4] = 'newsletter-confirm';
			// window.location.href = url.join('/');
		}

	}
}

</script>

<style scoped>

</style>
