<template>
	<div ref="toolbar" class="toolbar">
		<router-link to="/app" id="return-button" v-if="!options">
			<i class='bx bxs-chevron-left' style="margin-right: .4rem"></i>
			Home
		</router-link>
		<div v-on:click="removeOptions" id="return-button" v-if="options">
			<i class='bx bxs-chevron-left' style="margin-right: .4rem"></i>
			Back
		</div>

			<div id="optionsContainer" ref="optionsContainer" style="width: 100%">
				<component :is="options"></component>
			</div>

		<div id="tools" v-if="showIcons || !options">
			<div id="window" class="tool-icon-container" v-on:click="emitAction('window')">
				<i class='bx bx-square bx-sm tool-icon' style="align-content: center"></i>
				Add window
			</div>
			<div id="door" class="tool-icon-container" v-on:click="emitAction('door')">
				<i class='bx bx-door-open bx-sm tool-icon' style="align-content: center"></i>
				Add door
			</div>
			<div id="furniture" class="tool-icon-container" v-on:click="emitAction('furniture')">
				<img class='bx bx-sm tool-icon' src="../../assets/icons/furniture.svg"/>
				<div style="float: left; vertical-align: center">
					Add furniture
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {eventBus} from "../../main";

export default {
	name: "tool-bar",
	activeItem: undefined,
	data() {
		return {
			activeTool: null,
			options: null,
			optionsContainer: null,
			showIcons: true,
		}
	},
	methods: {
		emitAction(actionKey) {
			// if(historyHandler.reverseHistory.length === 0) {
			// 	document.getElementById('redo').classList.add('tool-icon-inactive');
			// } else {
			// 	document.getElementById('redo').classList.remove('tool-icon-inactive');
			// }

			if (actionKey !== 'redo' && actionKey !== 'undo' && actionKey !== 'trash') {
				if (this.activeItem) {
					this.activeItem.classList.remove('tool-icon-container-active')
				}

				if (this.activeTool === actionKey) {
					eventBus.emit('changeMode', 'select');
					this.activeTool = null;
				} else {
					eventBus.emit('changeMode', actionKey);
					this.activeTool = actionKey;
					document.getElementById(actionKey).classList.add('tool-icon-container-active');
					this.activeItem = document.getElementById(actionKey);
				}
			} else {
				eventBus.emit('historyEvent', actionKey);
			}
		},
		showOptions(options) {
			this.options = options;
			this.toggleExtended()
		},
		removeOptions() {
			this.options = null;
			this.toggleCollapsed();
			this.activeTool = null;
			if (this.activeItem) {
				this.activeItem.classList.remove('tool-icon-container-active')
			}
			this.activeItem = null;
		},
		toggleExtended(event) {
			if (this.optionsContainer.classList.contains('toolbar-extended')) {
				this.optionsContainer.classList.remove('toolbar-extended');
				this.optionsContainer.classList.add('toolbar-retracted');
			} else {
				this.optionsContainer.classList.add('toolbar-extended');
				this.optionsContainer.classList.remove('toolbar-retracted');
			}
		},
		toggleCollapsed() {
			this.optionsContainer.classList.remove('toolbar-extended');
			this.optionsContainer.classList.add('toolbar-retracted');
		},
		onResize() {
			if (window.innerWidth < 800) {
				this.showIcons = true;
			} else {
				this.showIcons = false;
			}
		}
	},
	mounted() {
		this.optionsContainer = this.$refs.optionsContainer;

		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		})
	}
}
</script>

<style scoped>
.toolbar {
	background-color: white;
}

#return-button {
	margin-left: 1rem;
	font-family: 'Poppins';
	font-weight: bolder;
	font-size: 1.5rem;
	cursor: pointer !important;
	text-decoration: none;
	color: black;
}

.tool-icon-container-active {
	background-color: rgb(235, 242, 255);
}

.tool-icon-container {
	font-family: 'Poppins';
}

@media screen and (min-width: 800px) {
	#return-button {
		display: block;
		margin-bottom: 2rem;
	}

	.toolbar {
		padding: 1rem 0;
	}

	.tool-icon-container {
		display: flex;
		padding: 0.5em 1rem;
		margin-bottom: .3rem;
		height: 2.5em;
		-webkit-user-select: none; /* Safari */
		-ms-user-select: none; /* IE 10 and IE 11 */
		user-select: none; /* Standard syntax */
	}

	.tool-icon-container * {
		margin-right: 5%;
	}

	.tool-icon-container:hover:not(.tool-icon-container-active) {
		background-color: rgb(195, 228, 247);
	}
}

@media screen and (max-width: 800px) {
	#return-button {
		display: none;
	}

	@keyframes example {
		from {max-height: 0;}
		to {max-height: 50vh;}
	}

	@keyframes example2 {
	}

	.toolbar-extended {
		position: relative;
		overflow-y: scroll;
		padding-top: 1em;
		animation-name: example;
		animation-duration: .6s;
		animation-fill-mode: forwards;
	}

	.toolbar-retracted {

		animation-name: example2;
		animation-duration: .6s;
	}

	#tools {
		padding: .5rem 1em;
		display: flex;
		align-items: center;
		position: relative;
		bottom: 0;
	}

	.tool-icon-container {
		font-size: .7rem;
		width: 33%;
		text-align: center;
		align-items: center;
		vertical-align: center;
		padding: 0.5em 0;
		justify-content: center;
		border-radius: 1em;
	}

	.tool-icon-container i, div {
		width: 100%;
	}

	.tool-icon-container img {
		width: 1em;
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: .2rem;
	}
}
</style>
