import AbstractBuildingElement from "@/components/editor/building-elements/AbstractBuildingElement";
import Point from "@/components/editor/simple-geometry/Point";
import Segment from "@/components/editor/simple-geometry/Segment";
import Polygon from "polygon";
import {
	addPaddingToLine,
	addThicknessToLine,
	extendSegment,
	orderPointsHorizontalVertical
} from '../simple-geometry/Operations'
import { drawPolygon, fillPolygon } from "@/components/editor/drawing/DrawingHelper";

export default class Wall extends AbstractBuildingElement {

	constructor(x1, y1, x2, y2, thickness) {
		super();
		this.position = new Segment(
			new Point(x1, y1),
			new Point(x2, y2),
		);
		this.thickness = thickness;
		this.windows = [];
		this.doors = [];
	}

	drawOutline(context) {
		const ordered = orderPointsHorizontalVertical(this.getPoints());

		for (let i = 0; i < ordered.length; i += 2) {
			const line = new Segment(ordered[i], ordered[i+1]);
			const shape = addThicknessToLine(line.ps.x, line.ps.y, line.pe.x, line.pe.y, this.thickness / 2);
			drawPolygon(context, shape);
		}
	}

	drawFilling(context, floorPattern) {
		const ordered = orderPointsHorizontalVertical(this.getPoints());

		for (let i = 0; i < ordered.length; i += 2) {
			const line = new Segment(ordered[i], ordered[i+1]);
			const shape = addThicknessToLine(line.ps.x, line.ps.y, line.pe.x, line.pe.y, this.thickness / 2);
			fillPolygon(context, shape);
		}

		this.windows.forEach(window => window.drawOutline(context, this.thickness));
		this.windows.forEach(window => window.drawFilling(context, this.thickness));
		this.doors.forEach(door => door.drawFilling(context, this.thickness, floorPattern));
		this.doors.forEach(door => door.drawOutline(context, this.thickness));
	}

	getGeometry() {
		return addPaddingToLine(this.position.ps.x, this.position.ps.y, this.position.pe.x, this.position.pe.y, this.thickness / 2)
	}

	getPoints() {
		const points = [];
		const extended = extendSegment(this.position, this.thickness / 2);
		points.push(extended.ps, extended.pe);
		this.doors.forEach(door => points.push(door.position.ps, door.position.pe))
		return points;
	}

	getGeometryAsPolygon() {
		const geometry = this.getGeometry();
		return new Polygon([
			[geometry[0].x, geometry[0].y],
			[geometry[1].x, geometry[1].y],
			[geometry[2].x, geometry[2].y],
			[geometry[3].x, geometry[3].y]
		]);
	}

	getPath() {
		return this.position;
	}
}
