import {supabase} from "@/api/supabase";

export async function getResource(url) {
	return await supabase
		.from(url)
		.select('*');
}


export async function upsert(url, payload) {
	return supabase
		.from(url)
		.upsert(payload)
		.select();
}
