<template>
	<div>
		<h2 style="font-weight: 600; font-family: 'Poppins'; font-size: 1.3em; margin-bottom: 1.5rem">
			Welcome to Roomy!
		</h2>
		<div>
			In order to provide you with the best experience, we'd like to know a little more about you.
		</div>
		<br>
		<button style="background-color: #57039F; border-color: #57039F" class="btn btn-primary" v-on:click="$router.push('/app/welcome/2')">Get started</button>
	</div>
</template>


<script>
export default {
	name: "WelcomeMessage"
}
</script>


<style scoped>

</style>
