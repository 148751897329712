import InputHandler from "@/components/editor/features/InputHandler";
import Point from "@/components/editor/simple-geometry/Point";
import {	getSnappingPointAndWallAndNormal } from "@/components/editor/features/SnappingHelper";
import { getAngleBetweenVectors } from "@/components/editor/simple-geometry/Operations";
import FurnitureOptions from "@/components/editor/features/FurnitureOptions";
import {Furniture} from "@/components/editor/building-elements/Furniture";
import {eventBus} from "@/main";
import {createComponent} from "@/components/common/ComponentCreator";

export default class FurnitureHandler extends InputHandler {

	constructor() {
		super();
		this.options = createComponent(FurnitureOptions, {})

		eventBus.on('selectFurniture', (args) => {
			console.log(args)
			this.item = new Furniture(0, 0, 0, 'https://zmadrxukebkoijfkuyzr.supabase.co/storage/v1/object/public/assets/' + args.asset_path, args);
		});
	}

	handleMouseDown(e, data, floorplan) {
		if(this.item) {
			const p = new Point(
				data.canvas.panX + e.offsetX / data.canvas.scaleFactor,
				data.canvas.panY + e.offsetY / data.canvas.scaleFactor
			);

			const snap = getSnappingPointAndWallAndNormal(floorplan, p, true);

			if(snap.normal) {
				const angle = getAngleBetweenVectors(this.item.direction, snap.normal);
				this.item.rotation = angle;
			}

			this.item.position = snap.point;
			data.canvas.drawSvg(this.item);
		}
	}

	handleMouseUp(e, data, floorplan) {
		if(this.item) {
			const p = new Point(
				data.canvas.panX + e.offsetX / data.canvas.scaleFactor,
				data.canvas.panY + e.offsetY / data.canvas.scaleFactor
			);

			const snap = getSnappingPointAndWallAndNormal(floorplan, p, true);

			this.item.position = snap.point;
			this.item.calculatePath();
			data.space.push(this.item);
			data.space.sort((a, b) => a.item.height - b.item.height);
			eventBus.emit('placeFurniture');

			this.item = null;
		}
	}

	handleMouseMove(e, data, floorplan) {
		if(this.item) {
			const p = new Point(
				data.canvas.panX + e.offsetX / data.canvas.scaleFactor,
				data.canvas.panY + e.offsetY / data.canvas.scaleFactor
			);

			const snap = getSnappingPointAndWallAndNormal(floorplan, p, true);

			if(snap.normal) {
				const angle = getAngleBetweenVectors(this.item.direction, snap.normal);
				this.item.rotation = angle;
			}

			this.item.position = snap.point;
			data.canvas.drawSvg(this.item);
		}
	}

	getOptions() {
		return this.options;
	}
}
