<template>
	<div>
		<div style="text-align: center; margin-bottom: 2rem">
			<h1>Password Updated</h1>
		</div>
		<form action="/app">
			<div class="form-group">
				<div>
					Your password was updated successfully.
				</div>
				<button type="submit" class="btn">Continue</button>
			</div>
		</form>
	</div>
</template>

<script>
export default {
	name: "password-update-success",
}
</script>

<style scoped>
ion-text h1 {
	text-align: center;
	font-size: 2em;
}

.form-group {
	margin-top: .5em;
}

button {
	margin-top: 1em;
	margin-bottom: 1em;
}

button {
	background-color: rgb(87, 3, 159);
	border-color: rgb(87, 3, 159);
	color: white;
}

button:hover {
	background-color: rgb(134, 82, 179);
	border-color: rgb(134, 82, 179);
	color: white;
}
</style>
