<template>
	<div ref="canvases" id="canvases" class="canvases" style="position: relative; width: 100%; height: 100%"></div>
</template>

<script>
import {
	drawCross,
	drawDirectedSegment,
	drawRooms,
	drawPolygon,
	fillPolygon, drawRectangle, drawArc, fillCircle
} from "@/components/editor/drawing/DrawingHelper";
import Floorplan from "@/components/editor/building-elements/Floorplan";
import Point from "@/components/editor/simple-geometry/Point";
import {onMobile} from "@/components/layout/MobileDetection.js";

export const layerIndices = {
	BACKGROUND: 0,
	FLOORSPACE: 1,
	OUTLINES: 2,
	FILLINGS: 3,
	OPENINGS: 4,
	FURNITURE: 5,
	MEASUREMENTS_TEXT: 6,
	CURSOR: 7
}

export default {
	name: "floorplan-canvas",
	props: {
		space: Array,
		floorplan: Floorplan,
		drawBg: Boolean,
	},
	data() {
		return {
			canvas: null,
			contexts: {},
			panX: 0,
			panY: 0,
			scaleFactor: 1.00,
			floorPattern: null,
			img: null
		}
	},
	watch: {
		floorplan() {
			this.drawAll();
		}
	},
	methods: {
		zoomMobile(zoomOrigin, zoomFactor) {
			if((this.scaleFactor < 0.3 && zoomFactor < 0) ||
				(this.scaleFactor > 5 && zoomFactor > 0)) {
				return;
			}

			// Get mouse offset.
			const mousex = zoomOrigin.x;
			const mousey = zoomOrigin.y;
			// Normalize mouse wheel movement to +1 or -1 to avoid unusual jumps.

			// Compute zoom factor.
			const zoom = zoomFactor;

			// Translate so the visible origin is at the context's origin.
			translateLayers(this.contexts, this.panX, this.panY);

			// Compute the new visible origin. Originally the mouse is at a
			// distance mouse/scale from the corner, we want the point under
			// the mouse to remain in the same place after the zoom, but this
			// is at mouse/new_scale away from the corner. Therefore we need to
			// shift the origin (coordinates of the corner) to account for this.
			this.panX -= mousex/(this.scaleFactor*zoom) - mousex/this.scaleFactor;
			this.panY -= mousey/(this.scaleFactor*zoom) - mousey/this.scaleFactor;

			// Scale it (centered around the origin due to the trasnslate above).
			scaleLayers(this.contexts, zoom, zoom);
			// Offset the visible origin to it's proper position.
			translateLayers(this.contexts, -this.panX, -this.panY);

			// Update scale and others.
			this.scaleFactor *= zoom;

			this.clear();
			this.drawAll();
		},
		zoom(zoomOrigin, zoomFactor) {
			if((this.scaleFactor < 0.3 && zoomFactor < 0) ||
				(this.scaleFactor > 5 && zoomFactor > 0)) {
				return;
			}

			// Get mouse offset.
			const mousex = zoomOrigin.x;
			const mousey = zoomOrigin.y;
			// Normalize mouse wheel movement to +1 or -1 to avoid unusual jumps.

			// Compute zoom factor.
			const zoom = Math.exp(zoomFactor * 0.2);

			// Translate so the visible origin is at the context's origin.
			translateLayers(this.contexts, this.panX, this.panY);

			// Compute the new visible origin. Originally the mouse is at a
			// distance mouse/scale from the corner, we want the point under
			// the mouse to remain in the same place after the zoom, but this
			// is at mouse/new_scale away from the corner. Therefore we need to
			// shift the origin (coordinates of the corner) to account for this.
			this.panX -= mousex/(this.scaleFactor*zoom) - mousex/this.scaleFactor;
			this.panY -= mousey/(this.scaleFactor*zoom) - mousey/this.scaleFactor;

			// Scale it (centered around the origin due to the trasnslate above).
			scaleLayers(this.contexts, zoom, zoom);
			// Offset the visible origin to it's proper position.
			translateLayers(this.contexts, -this.panX, -this.panY);

			// Update scale and others.
			this.scaleFactor *= zoom;

			this.clear();
			this.drawAll();
		},
		clear() {
			this.contexts.forEach(layer => {
				layer.clearRect(this.panX, this.panY, this.canvas.width / this.scaleFactor, this.canvas.height / this.scaleFactor);
			});
		},
		drawGeometryOutlines() {
			if(this.space) {
				this.space.forEach(element => {
					element.drawOutline(this.contexts[layerIndices.OUTLINES]);
				})
			}
		},
		drawWindow(outline, positionSegment) {
			drawPolygon(this.contexts[layerIndices.OPENINGS], outline);
			fillPolygon(this.contexts[layerIndices.OPENINGS], outline, '#FFFFFF');
			drawDirectedSegment(this.contexts[layerIndices.OPENINGS], positionSegment, 2);
		},
		drawDoor(door, wallThickness) {
			door.drawOutline(this.contexts[layerIndices.OPENINGS], wallThickness);
			door.drawFilling(this.contexts[layerIndices.OPENINGS], wallThickness);
		},
		drawGeometryFillings() {
			if(this.space) {
				this.space.forEach(element => {
					element.drawFilling(this.contexts[layerIndices.FILLINGS], this.floorPattern);
				});
			}
		},
		drawRoom(room) {
			room.drawFilling(this.contexts[layerIndices.FLOORSPACE], this.contexts[layerIndices.MEASUREMENTS_TEXT], this.scaleFactor);
			room.walls.forEach(wall => {
				wall.drawFilling(this.contexts[layerIndices.FILLINGS]);
				wall.drawOutline(this.contexts[layerIndices.OUTLINES]);
			});
		},
		drawWall(buildingElement) {
			buildingElement.drawOutline(this.contexts[layerIndices.OUTLINES]);
			buildingElement.drawFilling(this.contexts[layerIndices.FILLINGS]);
		},
		drawSnappingPoint(snappingPoint, e) {
			if(snappingPoint) {
				const p = new Point(
					this.panX + e.offsetX / this.scaleFactor,
					this.panY + e.offsetY / this.scaleFactor
				);

				if(snappingPoint.x !== p.x || snappingPoint.y !== p.y) {
					drawCross(this.contexts[layerIndices.CURSOR], snappingPoint, '#000000', 10 / this.scaleFactor, 1 / this.scaleFactor);
				}
			}
		},
		drawSvg(furniture) {
			// console.log('shit')
			// // this.img = new Image();
			// // this.img.onload = function() {
			// // 	console.log('test')
			// // };
			// // this.img.src = '/assets/furniture/toilet.svg';
			// testImage.onload = function () {
			// 	console.log('asdfjlka;jfds')
			// }
			//
			furniture.drawOutline(this.contexts[layerIndices.FURNITURE]);

			// const img = new Image();
			// img.onload = function() {
			// 	console.log('test')
			// };
			// img.src = '@/assets/furniture/toilet.svg';

			// drawSvg(this.contexts[layerIndices.FURNITURE], this.img, new Point(100, 100));
		},
		drawMeasurements() {
			// const measurementHelper = new MeasurementHelper();
			// measurementHelper.drawMeasurements(this.context, this.space, this.scaleFactor, this.anchor);
		},
		drawBackground() {
			const p = new Point(
				this.panX,
				this.panY
			);
			drawRectangle(this.contexts[layerIndices.BACKGROUND], p, this.canvas.width / this.scaleFactor, this.canvas.height / this.scaleFactor, '#E4E4E4')

			let pointDistance = 50;
			const start = new Point(
				Math.round(this.panX / pointDistance) * pointDistance,
				Math.round(this.panY / pointDistance) * pointDistance
			);

			if (this.scaleFactor < 0.5) {
				pointDistance *= 2;
			}
			if (this.scaleFactor > 5) {
				pointDistance /= 5;
			}

			for (let i = 0; i < 80; i++) {
				for (let j = 0; j < 80; j++) {
					const p = new Point(
						start.x + i * pointDistance,
						start.y + j * pointDistance
					);

					fillCircle(this.contexts[layerIndices.BACKGROUND], p, 3/this.scaleFactor, '#C9C9C9')
				}
			}

			fillCircle(this.contexts[layerIndices.BACKGROUND], start, 2/this.scaleFactor, '#C9C9C9')
		},
		drawAll() {
			if(this.drawBg) {
				this.drawBackground();
			}

			if(this.floorplan) {
				this.floorplan.draw(this.contexts[layerIndices.FLOORSPACE], this.contexts[layerIndices.MEASUREMENTS_TEXT], this.scaleFactor);
				this.floorplan.getWalls().forEach(wall => {
					wall.drawFilling(this.contexts[layerIndices.FILLINGS], this.floorPattern);
					wall.drawOutline(this.contexts[layerIndices.OUTLINES]);
				});
			}

			this.drawGeometryOutlines();
			this.drawGeometryFillings();
			this.drawMeasurements();
		},
	},
	created() {
		this.$store.dispatch("fetchFurniture");
	},
	mounted() {
		new ResizeObserver(() => {
			const canvasParent = this.$refs.canvases;

			if(this.floorplan && this.floorplan.walls) {
				const floorplanCenter = getFloorplanCenter(this.floorplan);
				this.panX = floorplanCenter.x / this.scaleFactor - canvasParent.offsetWidth/2;
				this.panY = floorplanCenter.y / this.scaleFactor - canvasParent.offsetHeight/2;
			}

			// this.scaleFactor = 2;

			resizeCanvases(canvasParent, this.panX, this.panY, this.scaleFactor, this.contexts);

			if(this.floorplan && this.floorplan.walls) {
				const floorplanDimensions = getFloorplanDimensions(this.floorplan);
				const initialZoom = Math.min(canvasParent.offsetWidth / floorplanDimensions.width, canvasParent.offsetHeight / floorplanDimensions.height);

				this.zoomMobile({x: canvasParent.offsetWidth/2, y: canvasParent.offsetHeight/2}, initialZoom * 0.8);
			}

			this.drawAll();
		}).observe(document.getElementById('canvases'))

		this.contexts = createAllCanvases(this.$refs.canvases);
		this.canvas = document.getElementById('0');
		this.context = this.canvas.getContext("2d");

		const image = new Image();
		image.src = '/floors/floor_1.jpg';
		this.floorPattern = image;

		this.panX = 0;
		this.panY = 0;
		this.scaleFactor = 1;
	}
}

function resizeCanvases(parentDiv, panX, panY, scaleFactor, contexts) {
	const canvasParent = parentDiv;
	const canvases = canvasParent.querySelectorAll('canvas');

	canvases.forEach(canvas => {
		canvas.width = canvasParent.offsetWidth;
		canvas.height = canvasParent.offsetHeight;
	});
	console.log(canvasParent.offsetWidth)


	contexts.forEach(context => {
		context.translate(-panX, -panY);
	})
}

function createAllCanvases(parentDiv) {
	const contexts = [];
	Object.values(layerIndices).forEach(index => contexts.push(createCanvas(parentDiv, index)));
	return contexts;
}

function createCanvas(parentDiv, canvasLayerIndex) {
	const canvas = document.createElement('canvas');
	canvas.id = canvasLayerIndex.toString();
	// const mobile = onMobile();
	canvas.style.zIndex = canvasLayerIndex;
	canvas.style.position = "absolute";

	parentDiv.append(canvas);

	return canvas.getContext("2d");
}

function getFloorplanDimensions(floorplan) {
	let lowerXbound = Infinity;
	let higherXbound = 0;
	let lowerYbound = Infinity;
	let higherYbound = 0;

	floorplan.rooms.forEach(room => {
		room.walls.forEach((wall) => {
			lowerXbound = Math.min(lowerXbound, wall.position.ps.x, wall.position.pe.x);
			higherXbound = Math.max(higherXbound, wall.position.ps.x, wall.position.pe.x);
			lowerYbound = Math.min(lowerYbound, wall.position.ps.y, wall.position.pe.y);
			higherYbound = Math.max(higherYbound, wall.position.ps.y, wall.position.pe.y);
		});
	});

	return {
		width: higherXbound - lowerXbound,
		height: higherYbound - lowerYbound
	}
}

function getFloorplanCenter(floorplan) {
	let lowerXbound = Infinity;
	let higherXbound = 0;
	let lowerYbound = Infinity;
	let higherYbound = 0;

	floorplan.rooms.forEach(room => {
		room.walls.forEach((wall) => {
			lowerXbound = Math.min(lowerXbound, wall.position.ps.x, wall.position.pe.x);
			higherXbound = Math.max(higherXbound, wall.position.ps.x, wall.position.pe.x);
			lowerYbound = Math.min(lowerYbound, wall.position.ps.y, wall.position.pe.y);
			higherYbound = Math.max(higherYbound, wall.position.ps.y, wall.position.pe.y);
		});
	});

	return new Point((lowerXbound + higherXbound) / 2, (lowerYbound + higherYbound) / 2);
}

function convertRemToPixels(rem) {
	return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

function translateLayers(layers, xDistance, yDistance) {
	layers.forEach(layer => layer.translate(xDistance, yDistance));
}

function scaleLayers(layers, xZoomFactor, yZoomFactor) {
	layers.forEach(layer => layer.scale(xZoomFactor, yZoomFactor));
}
</script>

<style scoped>

</style>
