<template>
	<ion-modal :class="this.state" class="cond-modal" id="testing" :can-dismiss="true" ref="modal" @willDismiss="closeModal" :is-open="isOpen">
		<div v-on:click="closeModal()">
			<i class='bx bx-x' style="cursor: pointer; font-size: 3rem; top: 1rem; right: 1rem; position: absolute; z-index: 10000"></i>
		</div>
		<ion-content class="">
			<div v-if="login">
				<login-form @switchTo="(destination) => content=destination"></login-form>
				<ion-text>
					<div class="" style="margin-top: -2.9rem; margin-left: 8rem">
						Or <a href="#" v-on:click="switchToRegister($event, false)">register instead</a>
					</div>
				</ion-text>
			</div>
			<div v-if="register">
				<registration-form @switchTo="(destination) => content=destination"></registration-form>
				<ion-text>
					<p style="margin-top: -2.9rem; margin-left: 8rem">
						Or <a href="#" v-on:click="switchToRegister($event, true)">login instead</a>
					</p>
				</ion-text>
			</div>
			<div v-if="reset">
				<reset-password-form @switchTo="(destination) => content=destination"></reset-password-form>
				<ion-text>
					<p style="margin-top: -2.9rem; margin-left: 10rem">
						Or <a href="#" v-on:click="switchToRegister($event, true)">login instead</a>
					</p>
				</ion-text>
			</div>
			<div v-if="state === 'update-password'">
				<new-password-form @switchTo="(destination) => content=destination"></new-password-form>
			</div>
			<div v-if="state === 'reset-request-sent'">
				<reset-request-sent @switchTo="(destination) => content=destination"></reset-request-sent>
			</div>
			<div v-if="state === 'password-updated'">
				<password-update-success @switchTo="(destination) => content=destination"></password-update-success>
			</div>
			<div v-if="content === 'confirm-email'">
				<email-confirm-prompt></email-confirm-prompt>
			</div>
		</ion-content>
	</ion-modal>
</template>

<script>
import {IonContent, IonModal, IonText} from "@ionic/vue";
import LoginForm from "@/components/common/auth/modal-states/LoginForm.vue";
import RegistrationForm from "@/components/common/auth/modal-states/RegistrationForm.vue";
import EmailConfirmPrompt from "@/components/common/auth/modal-states/EmailConfirmPrompt.vue";
import ResetPasswordForm from "@/components/common/auth/modal-states/ResetPasswordForm.vue";
import NewPasswordForm from "@/components/common/auth/modal-states/NewPasswordForm.vue";
import ResetRequestSent from "@/components/common/auth/modal-states/ResetRequestSent.vue";
import PasswordUpdateSuccess from "@/components/common/auth/modal-states/NewPasswordSuccess.vue";

export default {
	name: "AuthModal",
	components: {
		PasswordUpdateSuccess,
		ResetRequestSent,
		NewPasswordForm,
		ResetPasswordForm, EmailConfirmPrompt, RegistrationForm, IonText, LoginForm, IonModal, IonContent},
	data() {
		return {
			state: '',
			isOpen: false,
			login: true,
			register: false,
			reset: false,
			confirmEmail: false,
			canDismiss: false,
			presentingElement: undefined,
			content: null,
		};
	},
	computed: {
		mode() {
			if (this.$route.query.modal) {
				return this.$route.query.modal;
			}
			return [...this.state]
		}
	},
	watch: {
		mode() {
			if(!this.mode) {
				this.isOpen = false;
				this.state = '';
			} else if(this.mode === 'login') {
				this.login = true;
				this.register = false;
				this.confirmEmail = false;
				this.reset = false;
				this.isOpen = true;
				this.state = 'login';
			} else if(this.mode === 'register') {
				this.login = false;
				this.register = true;
				this.confirmEmail = false
				this.reset = false;
				this.isOpen = true;
				this.state = 'register';
			} else if(this.mode === 'confirm-email') {
				this.login = false;
				this.register = false;
				this.confirmEmail = true;
				this.reset = false;
				this.isOpen = true;
				this.state = 'confirm-email';
			} else if(this.mode === 'reset') {
				this.login = false;
				this.register = false;
				this.confirmEmail = false;
				this.reset = true;
				this.isOpen = true;
				this.state = 'reset';
			} else if(this.mode.includes('update-password')) {
				this.login = false;
				this.register = false;
				this.confirmEmail = false;
				this.reset = false;
				this.isOpen = true;
				this.state = 'update-password';
			} else if(this.mode.includes('reset-request-sent')) {
				this.login = false;
				this.register = false;
				this.confirmEmail = false;
				this.reset = false;
				this.isOpen = true;
				this.state = 'reset-request-sent';
			} else if(this.mode.includes('password-updated')) {
				this.login = false;
				this.register = false;
				this.confirmEmail = false;
				this.reset = false;
				this.isOpen = true;
				this.state = 'password-updated';
			}
		}
	},
	methods: {
		switchToRegister(event, toLogin) {
			this.$router.push('?modal=' + (toLogin ? 'login' : 'register'));
			this.isOpen = true;
			event.preventDefault();
			this.login = toLogin;
		},
		closeModal() {
			this.$router.replace({'modal': null});
			this.isOpen = false;
			this.$router.replace({'modal': null});
		},
	},
}
</script>



<style scoped>
ion-modal {
	--border-radius: .3em;
	--ion-font-family: Poppins;
	--padding-start: 3rem;
	--padding-top: 3rem;
	--padding-end: 3rem;
}

ion-content {
	--border-radius: .3em;
	--ion-font-family: Poppins;
	--padding-start: 2rem;
	--padding-top: 2rem;
	--padding-end: 2rem;

}

.login {
	--height: 380px;
}

.register {
	--height: 580px;
}

.reset {
	--height: 300px;
}

.update-password {
	--height: 300px;
}

.reset-request-sent {
	--height: 200px;
}

.password-updated {
	--height: 250px;
}

</style>
