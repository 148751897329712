<template>
	<div style="overflow-y: scroll; background-color: #F4F4F4; height: 100vh">
		<div style="height: 1900px; position:relative;">
<!--			<canvas id="canvas3d" width="1920"></canvas>-->
			<div id="buffer" style="; background-color: white; height: 5rem"></div>
			<img id="bg" alt="Background image" src="../../assets/landing_page_bg.jpg"/>

			<div style="position: absolute; left: 8%; top: 2em; width: 84vw">
				<div style="margin-bottom: 8em">

					<nav class="navbar navbar-expand-lg navbar-light bg-light position-absolute" style="z-index: 2; width: 84vw; padding: .7em; border-radius: 1em; background-color: #EEEEEE !important;">
						<div class="container-fluid">
							<img src="../../assets/logo.png" alt="Logo of Roomy" style="width: 8em; float: left">
							{{$route.query.login}}

							<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
								<span class="navbar-toggler-icon"></span>
							</button>
							<div class="collapse navbar-collapse" id="navbarNavDropdown" style="flex-grow: 0">
								<ul class="navbar-nav" style="float: right">
									<li class="nav-item" style="">
										<button v-on:click="switchToRegister($event, true)" type="button" class="btn btn-light" id="open-modal" style="float: right; margin-top: .5em; margin-left: 1.5em; padding-left: 2em; padding-right: 2em; font-family: 'Poppins'; font-weight: 400">Login</button>
									</li>
									<li class="nav-item">
										<button v-on:click="switchToRegister($event, false)" type="button" class="btn btn-primary" id="open-modal" style="float:right; margin-top: .5em; margin-left: 1.5em; padding-left: 2em; padding-right: 2em; border: 0; background-color: #57039F; font-family: 'Poppins'">Register</button>
									</li>
								</ul>
							</div>
						</div>
					</nav>

					<h1 id="slogan" style="padding-top: 10rem; width: 70%; max-width: 40em; line-height: 1.1em; font-family: 'Fraunces'; color: #00000080; margin-bottom: 10rem">
						Make yourself a(t) home
					</h1>

					<p style="width: 30%; min-width: 15em; font-family: 'Poppins'; font-size: 1.2em; margin-bottom: 2rem">
						Roomy is your personal Interior Designer. Select your preferences, browse through countless
						suggestions and find the perfect match for your home.
					</p>

					<button
						onclick="window.location.href='/newsletter';"
						type="button" class="btn btn-primary"
						style="font-family: 'Poppins SemiBold'; font-size: 1.2em; padding-left: 2rem; padding-right: 2rem; background-color: rgb(94,71,159); border: 0">
						Stay informed
					</button>
				</div>

				<div
					style="filter: drop-shadow(0 0 25px #C6C6C6); border-radius: 1em; font-family: 'Poppins'; background-color: white; padding: 4em 9%; margin-bottom: 5em">
					<div class="container">
						<div class="row">
							<div class="col-lg">
								<div style="padding: 1.5em 0">
									<div style="; height: 15em; background-color: #ebf1f7; border-radius: 1em; box-shadow: inset 0 0 1.5em #9da2a6;">
										<img src="../../assets/floorplan.png" style="position: relative; top: -.5em; width: 14em; transform: rotate(330deg); display: block; margin-left: auto; margin-right: auto;">
									</div>
								</div>
							</div>
							<div class="col-lg">
								<h2 style="font-family: 'Poppins'; font-weight: bolder; font-size: 3em">
									Your home, your style
								</h2>
								<p>
									From inspiration to execution, Roomy can help you
									to make your home an awesome space that feels like, well, you.
								</p>
								<div id="style_buttons" style="margin-top: .5em">
									<button type="button" class="btn" style="background-color: lightblue">
										Minimalist
									</button>
									<button type="button" class="btn" style="background-color: darkred; color: white">
										Mediterranean
									</button>
									<button type="button" class="btn" style="background-color: bisque">
										Country House
									</button>
									<button type="button" class="btn" style="background-color: gray; color: white">
										Industrial
									</button>
									<button disabled type="button" class="btn" style=" color: black; border: 1px solid grey">
										And many more
									</button>
								</div>

							</div>
						</div>


					</div>


					<div class="row" style="margin-top: 5em">
						<div class="col-lg">
							<h2 style="font-family: 'Poppins'; font-weight: bolder; font-size: 3em">
								Fast, simple and free
							</h2>
							<p style="margin-bottom: 3em">
								Don't waste your time with endless browsing and searching. Roomy will find the perfect design for you.
							</p>
						</div>
						<div class="col-lg">
							<div style="padding: 1.5em 0">
								<div style="background-color: #fff6e8; border-radius: 1em; box-shadow: inset 0 0 1.5em #b3aa9d;">
									<img src="../../assets/screen.png" alt="Screenshot of Roomy on a mobile device" style="position: relative; top: -1em; width: 8em; transform: rotate(20deg); display: block; margin-left: auto; margin-right: auto;">
								</div>
							</div>
						</div>
					</div>

					<div class="row" style="margin-top: 5em">
						<div class="col-lg">
							<div style="padding: 1.5em 0">
								<div style="height: 17em">
									<img src="../../assets/interior.jpg" alt="Well designed interior" style="width: 100%; height: 100%; object-fit: cover; border-radius: 1em; box-shadow: inset 0 0 1.5em #b3aa9d;">
								</div>
							</div>
						</div>
						<div class="col-lg">
							<h2 style="font-family: 'Poppins'; font-weight: bolder; font-size: 3em">
								Get notified
							</h2>
							<p style="margin-bottom: 2em">
								Be among the first to try out Roomy and get notified when we launch.
							</p>
							<button
								onclick="window.location.href='/newsletter';"
								type="button" class="btn btn-primary"
								style="font-family: 'Poppins SemiBold'; margin-bottom: 3em; font-size: 1.2em; padding-left: 2rem; padding-right: 2rem; background-color: rgb(94,71,159); border: 0">
								Stay informed
							</button>
						</div>
					</div>
				</div>

				<div id="supported_section" class="container" style="margin-bottom: 5em">
					<h3 style="font-family: 'Poppins'; font-weight: bolder; font-size: 3em; text-align: center; margin-bottom: 1em">
						Supported by
					</h3>
					<div class="row">
						<div class="col" style="text-align: center">
							<img src="../../assets/aws_logo.png" alt="Logo of the Austrian Economic Service" style="max-width: 80%; max-height: 6em">
						</div>
						<div class="col" style="text-align: center">
							<img src="../../assets/austrianstartups_logo.png" alt="Logo of Austrian Startups" style="max-width: 80%; max-height: 6em">
						</div>
					</div>
				</div>

				<div id="contact_section" class="container">
					<h3 style="font-family: 'Poppins'; font-weight: bolder; font-size: 3em; text-align: center; margin-bottom: 1em">
						Get in touch
					</h3>
					<div class="row">
						<div class="col" style="text-align: right">
							<a href="https://www.linkedin.com/company/roomy-app" aria-label="Our LinkedIn Page">
								<i class='bx bxl-linkedin-square' style="align-content: center; color: black"></i>
							</a>
						</div>
						<div class="col" style="text-align: center">
							<a href="https://www.instagram.com/roomy.app/" aria-label="Our Instagram Account">
								<i class='bx bxl-instagram-alt tool-icon' style="align-content: center; color: black"></i>
							</a>
						</div>
						<div class="col" style="text-align: left">
							<a href="https://www.facebook.com/people/Roomyapp/100092016125382/" aria-label="Our Facebook Page">
								<i class='bx bxl-facebook-square tool-icon' style="align-content: center; color: black"></i>
							</a>
						</div>
					</div>
					<div class="row">
						<div class="col" style="text-align: center; margin-top: 1rem; font-size: 1rem; font-family: 'Poppins'; cursor: pointer" v-on:click="$router.push({name: 'imprint'})">
							Imprint
						</div>


					</div>
				</div>

				<div style="height: 8em"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "LandingPage",
	data() {
		return {
			isOpen: false,
			login: true,
			canDismiss: false,
			presentingElement: undefined,
		};
	},
	computed: {
		user() {
			return this.$store.getters.getUser;
		},
	},
	watch: {
		user() {
			if (this.user) {
				if(!window.location.href.includes('?modal=update-password')){
					this.$router.push('/app');
				}
			}
		}
	},
	methods: {
		switchToRegister(event, toLogin) {
			this.$router.push('?modal=' + (toLogin ? 'login' : 'register'));
		},
	},
	mounted() {
		if (this.user) {
			if(!window.location.href.includes('?modal=update-password')){
				this.$router.push('/app');
			}
		}
	}
}

</script>

<style scoped>
#style_buttons button {
	margin-right: 1em;
	margin-top: .7em;
}

@media screen and (min-width: 800px) {
	#bg {
		margin-top: -40vw;
	}
}

@media screen and (max-width: 1500px) {
	#slogan {
		font-size: 8vw;
	}
}
@media screen and (max-width: 500px) {
	#slogan {
		font-size: 3em;
	}

	#style_buttons button {
		font-size: 75%;
	}
}

@media screen and (min-width: 1500px) {
	#slogan {
		font-size: 150px;
	}

	#style_buttons button {
		font-size: 1em;
	}
}

@media screen and (max-width: 1000px) {
	i {
		font-size: 10vw;
	}
}

@media screen and (min-width: 1000px) {
	i {
		font-size: 100px;
	}
}

</style>
