<template>
	<div>
		<div v-for="vendor in vendors" :key="vendor.uuid" v-on:click="$router.push({ name: 'vendorAssortment', params: {vendor_uuid: vendor.uuid }})" style="height: 7rem; background-color: white; margin: 1rem; border-radius: .3rem; border: 1px solid gray">
			<div style="width: 80%; height: 100%; text-align: center; float: left">
				<img v-bind:src="vendor.logo_url" style="max-width: 80%; max-height: 80%; align-content: center; position: relative; top: 50%; -ms-transform: translateY(-50%); transform: translateY(-50%);">
			</div>
			<div style="width: 20%; height: 100%; text-align: center; float: left">
				<i class='bx bxs-chevron-right' style="font-size: 3rem; align-content: center; position: relative; top: 50%; -ms-transform: translateY(-50%); transform: translateY(-50%);"></i>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Vendors-Page",
	computed: {
		vendors() {
			return this.$store.getters.getVendors;
		},
	},
};
</script>

<style scoped>

</style>
