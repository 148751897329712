<template>
	<div>
		Thanks for registering!
		Please check your email for a confirmation link.
	</div>
</template>

<script>
export default {
	name: "EmailConfirmPrompt"
}
</script>


<style scoped>

</style>
