<template>
	<div id="tools">
		<div v-on:click="deleteDoor" class="tool-icon-container tool-icon-container-lg">
			<i class='bx bx-sm bx-trash tool-icon' style="width: 1em"/>
			Delete
		</div>
		<div class="tools-row">
			<div v-on:click="switchDoorSide" class="tool-icon-container tool-icon-container-sm">
				<img class='bx bx-sm tool-icon' src="../../../assets/icons/mirror_door.svg" style="width: 1em"/>
				Switch Left/Right
			</div>
			<div v-on:click="switchDoorInOut" class="tool-icon-container tool-icon-container-sm">
				<img class='bx bx-sm tool-icon' src="../../../assets/icons/mirror_door_inout.svg" style="width: 1em"/>
				Switch In/Out
			</div>

		</div>
	</div>
</template>

<script>
import Door from "@/components/editor/building-elements/Door.js";
import Wall from "@/components/editor/building-elements/Wall.js";
import {eventBus} from "@/main.js";

export default {
	name: "EditDoorOptions",
	props: {
		door: Door,
		wall: Wall
	},
	data() {
		return {}
	},
	mounted() {

	},
	methods: {
		switchDoorSide() {
			this.door.switchSide();
			eventBus.emit('redraw');
		},
		switchDoorInOut() {
			this.door.switchInOut();
			eventBus.emit('redraw');
		},
		deleteDoor() {
			const doors = this.wall.doors;
			doors.splice(doors.indexOf(this.door), 1);
			eventBus.emit('redraw');
			eventBus.emit('changeMode', 'select');
		},
	}
}
</script>

<style scoped>
.tool-icon-container {
	font-family: 'Poppins';
}

@media screen and (min-width: 800px) {
	.tool-icon-container {
		display: flex;
	//align-items: center; vertical-align: center;
		padding: 0.5em 1rem;
		margin-bottom: .3rem;
	//justify-content: center; border-radius: 1em;
		height: 2.5em;
		-webkit-user-select: none; /* Safari */
		-ms-user-select: none; /* IE 10 and IE 11 */
		user-select: none; /* Standard syntax */
	}

	.tool-icon-container * {
		margin-right: 5%;
	}

	.tool-icon-container:hover:not(.tool-icon-container-active) {
		background-color: rgb(195, 228, 247);
	}
}

@media screen and (max-width: 800px) {
	#tools {
		padding: .5rem 1em;
		padding-bottom: 1em;
		align-items: center;
		position: relative;
		bottom: 0;
		border-bottom: 1px solid gray;
	}

	.tools-row {
		display: flex;
	}

	.tool-icon-container {
		font-size: .7rem;
		text-align: center;
		align-items: center;
		vertical-align: center;
		padding: 0.5em 0;
		justify-content: center;
		border-radius: 1em;
	}

	.tool-icon-container-sm {
		width: 50%;
	}
	.tool-icon-container-lg {
		width: 100%;
	}

	.tool-icon-container i, div {
		width: 100%;
	}

	.tool-icon-container img, i {
		width: 1em;
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: .2rem;
	}

	.tool-icon-inactive {
		color: #999999;
	}
}
</style>
