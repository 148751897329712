import LandingPageLayout from "@/components/layout/LandingPageLayout.vue";
import AppLayout from "@/components/layout/AppLayout.vue";

import App from './App.vue'
import Home from './pages/app/Home.vue'
import Designer from './pages/app/Designer.vue'
import EditorOverview from "@/pages/app/EditorOverview.vue";
import LandingPage from "./pages/landing-page/LandingPage.vue";
import Newsletter from "./pages/landing-page/Newsletter.vue";
import RoomPresets from "@/pages/app/RoomPresets.vue";
import Vendors from "@/pages/app/VendorOverview.vue";
import VendorAssortment from "@/pages/app/VendorAssortment.vue";
import ProductPage from "@/pages/app/ProductPage.vue";
import ImprintPage from "@/pages/etc/Imprint.vue";
import Profile from "@/pages/app/profile/Profile.vue";
import NotFound from './pages/NotFound'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import { BootstrapVue3 } from "bootstrap-vue-3";
import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';


import * as Vue from 'vue'
import * as VueRouter from 'vue-router'
import VueMatomo from 'vue-matomo';


import HistoryHandler from "./components/editor/features/HistoryHandler";
import mitt from "mitt";
import {create_store} from "@/store/store";
import {supabase} from "@/api/supabase";
import NewsletterConfirm from "@/pages/landing-page/NewsletterConfirm.vue";
import Welcome from "@/pages/app/Welcome.vue";
import WelcomeMessage from "@/pages/app/welcome/WelcomeMessage.vue";
import StyleSelection from "@/pages/app/welcome/StyleSelection.vue";
import HomeTypeSelection from "@/pages/app/welcome/HomeTypeSelection.vue";
import InhabitantsSelection from "@/pages/app/welcome/InhabitantsSelection.vue";
import RoomSelection from "@/pages/app/welcome/RoomSelection.vue";
import ReadyMessage from "@/pages/app/welcome/ReadyMessage.vue";
import PrivacyPolicy from "@/pages/etc/PrivacyPolicy.vue";
import TermsOfService from "@/pages/etc/TermsOfService.vue";

export const eventBus = mitt();
export const historyHandler = new HistoryHandler();


const routes = [
	{
		path: '/',
		component: LandingPageLayout,
		children: [
			{
				path: '',
				component: LandingPage
			},
			{
				path: '/newsletter',
				component: Newsletter
			},
			{
				path: '/newsletter-confirmation',
				component: NewsletterConfirm
			},
			{
				path: '/imprint',
				name: 'imprint',
				component: ImprintPage
			},
			{
				path: '/privacy',
				name: 'privacy',
				component: PrivacyPolicy
			},
			{
				path: '/terms',
				name: 'terms',
				component: TermsOfService
			},
		]
	},
	{
		path: '/app',
		component: AppLayout,
		children: [
			{
				path: '',
				name: 'home',
				component: Home
			},
			{
				path: 'designer',
				name: 'designer',
				component: Designer
			},
			{
				path: 'editor-overview',
				name: 'editor-overview',
				component: EditorOverview
			},
			{
				path: 'presets',
				name: 'presets',
				component: RoomPresets
			},
			{
				path: 'vendors',
				name: 'vendors',
				component: Vendors,
			},
			{
				path: 'vendors/:vendor_uuid',
				name: 'vendorAssortment',
				props: true,
				component: VendorAssortment,
			},
			{
				path: 'products/:product_uuid',
				name: 'productPage',
				props: true,
				component: ProductPage,
			},
			{
				path: 'profile',
				name: 'profile',
				component: Profile,
			},
			{
				path: 'welcome',
				name: 'welcome',
				component: Welcome,
				children: [
					{
						path: '',
						component: WelcomeMessage,
					},
					{
						path: '1',
						component: WelcomeMessage,
					},
					{
						path: '2',
						component: StyleSelection,
					},
					{
						path: '3',
						component: HomeTypeSelection,
					},
					{
						path: '4',
						component: InhabitantsSelection,
						props: true,
					},
					{
						path: '5',
						component: RoomSelection,
					},
					{
						path: '6',
						component: ReadyMessage,
					},
				]
			}
		]
	},
]

const router = VueRouter.createRouter({
	history: VueRouter.createWebHistory(),
	routes
})

router.beforeEach(async (to, from) => {
	const { data, error } = await supabase.auth.getSession()

	if(!data.session && (
		to.name === 'designer' ||
		to.name === 'presets' ||
		to.name === 'profile'
	)) {
		return '?modal=login';
	}

	if(data.session && !data.session.user.user_metadata.welcome_done && (
		to.path.includes('app') &&
		!to.path.includes('welcome')
	)) {
		return '/app/welcome';
	}
})

const roomyStore = create_store;

const app = Vue.createApp(App);
app.use(router);
app.use(roomyStore)
app.use(BootstrapVue3);
app.use(IonicVue);
app.use(VueMatomo, {
	host: 'https://roomydesign.matomo.cloud',
	siteId: 1,
	router
});
app.component(historyHandler);
app.mount('#app');


