import InputHandler from "@/components/editor/features/InputHandler";
import Wall from "@/components/editor/building-elements/Wall";
import {eventBus, historyHandler} from "@/main";
import {getSnappingPoint} from "@/components/editor/features/SnappingHelper";
import Point from "@/components/editor/simple-geometry/Point";
import WallOptions from "@/components/editor/features/WallOptions";
import {createComponent} from "@/components/common/ComponentCreator";

export default class WallHandler extends InputHandler {

	constructor() {
		super();
		this.options = createComponent(WallOptions, {});
	}

	handleMouseDown(e, data) {
		const p = new Point(
			data.canvas.panX + e.offsetX / data.canvas.scaleFactor,
			data.canvas.panY + e.offsetY / data.canvas.scaleFactor
		);

		this.anchor = getSnappingPoint(data.floorplan, p);

		const wall = new Wall(
			this.anchor.x,
			this.anchor.y,
			this.anchor.x,
			this.anchor.y,
			15
		);

		data.canvas.drawWall(wall, null);
		data.canvas.drawSnappingPoint(this.anchor, e);
	}

	handleMouseUp(e, data) {
		const p = new Point(
			data.canvas.panX + e.offsetX / data.canvas.scaleFactor,
			data.canvas.panY + e.offsetY / data.canvas.scaleFactor
		);

		let snap = getSnappingPoint(data.floorplan, p);

		if(this.anchor && snap.equals(this.anchor)) {
			snap = p;
		}

		if (this.anchor && !this.anchor.equals(snap)) {
			let wall;

			if (Math.abs(this.anchor.x - snap.x) > Math.abs(this.anchor.y - snap.y)) {
				wall = new Wall(
					this.anchor.x,
					this.anchor.y,
					snap.x,
					this.anchor.y,
					15
				);
			} else {
				wall = new Wall(
					this.anchor.x,
					this.anchor.y,
					this.anchor.x,
					snap.y,
					15
				);
			}
			console.log(wall.position.ps.x + " " + wall.position.ps.y + " " + wall.position.pe.x + " " + wall.position.pe.y);

			data.floorplan.addWall(wall);
			historyHandler.clearReverseHistory();
			eventBus.emit('historyChangedEvent');
			const newRooms = [];

			data.floorplan.rooms = data.floorplan.rooms.concat(newRooms);
			this.anchor = null;
		}

		data.canvas.drawSnappingPoint(snap, e);
	}

	handleMouseMove(e, data) {
		const p = new Point(
			data.canvas.panX + e.offsetX / data.canvas.scaleFactor,
			data.canvas.panY + e.offsetY / data.canvas.scaleFactor
		);

		let snap = getSnappingPoint(data.floorplan, p);

		if(this.anchor && snap.equals(this.anchor)) {
			snap = p;
		}

		if(this.anchor) {
			let wall;

			if (Math.abs(this.anchor.x - snap.x) > Math.abs(this.anchor.y - snap.y)) {
				wall = new Wall(
					this.anchor.x,
					this.anchor.y,
					snap.x,
					this.anchor.y,
					15
				);
			} else {
				wall = new Wall(
					this.anchor.x,
					this.anchor.y,
					this.anchor.x,
					snap.y,
					15
				);
			}

			data.canvas.drawWall(wall, null);
		}

		data.canvas.drawSnappingPoint(snap, e);
	}

	getOptions() {
		return this.options;
	}
}
