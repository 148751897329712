export default class Vector {

	/**
	 * @param x {int}
	 * @param y {int}
	 */
	constructor(x, y) {
		this.x = x;
		this.y = y;
	}

	/**
	 * @param segment {DirectedSegment}
	 * @returns {Vector}
	 */
	static createFromDirectedSegment(segment) {
		const magnitudeOnX = segment.end.x - segment.start.x;
		const magnitudeOnY = segment.end.y - segment.start.y;
		return new Vector(magnitudeOnX, magnitudeOnY);
	}

	getMagnitude() {
		return Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2));
	}

	getUnitVector() {
		const magnitude = this.getMagnitude();
		return new Vector(this.x/magnitude, this.y/magnitude);
	}

	getNormalVector() {
		return new Vector(this.y, -this.x);
	}

	multiply(num) {
		return new Vector(this.x * num, this.y * num);
	}

	equals(other) {
		return this.x === other.x && this.y === other.y;
	}
}
