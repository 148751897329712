import {eventBus} from "@/main";
import Floorplan from "@/components/editor/building-elements/Floorplan";

export default class HistoryHandler {
	constructor() {
		this.reverseHistory = [];
	}

	undo(space) {
		if(space.length > 0) {
			this.reverseHistory.push([space.pop()]);
			eventBus.emit('historyChangedEvent');
		}
	}

	redo(space) {
		if(this.reverseHistory.length > 0) {
			space.push(this.reverseHistory.pop()[0]);
			eventBus.emit('historyChangedEvent');
		}
	}

	trash(data) {
		console.log('trash')
		data.floorplan.walls = new Map();
		data.floorplan.rooms = [];
		data.floorplan = new Floorplan();
	}

	clearReverseHistory() {
		this.reverseHistory = [];
		eventBus.emit('historyChangedEvent');
	}
}
