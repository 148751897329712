export default class AbstractBuildingElement {
	constructor() {
		this.selected = false;

		if (this.constructor === AbstractBuildingElement) {
			throw new Error("Abstract classes can't be instantiated.");
		}
	}

	drawOutline() {
		throw new Error("Method 'drawOutline()' must be implemented.");
	}

	drawFilling() {
		throw new Error("Method 'drawFilling()' must be implemented.");
	}

	getPath() {
		throw new Error("Method 'getPath()' must be implemented.");
	}
}
