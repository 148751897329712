<template>
	<div>
<!--		<div class="option-title">-->
<!--			Wall thickness-->
<!--		</div>-->
<!--		<div>-->
<!--			5cm-->
<!--			<input id="slider" type="range" min="5" max="100" v-model="wallThickness">-->
<!--			100cm-->
<!--		</div>-->

	</div>

</template>

<script>
export default {
	name: "WallOptions",
	data() {
		return {
			wallThickness: 15
		}
	},
}
</script>

<style scoped>
#slider {
	width: 63%;
}

.option-title {
	margin-bottom: 0.5em;
}

</style>
