import Point from "@/components/editor/simple-geometry/Point";

export default class Line {

	/**
	 * @param point1 {Point}
	 * @param point2 {Point}
	 */
	constructor(point1, point2) {
		this.p1 = point1;
		this.p2 = point2;
	}

	/**
	 * gets the intersection point of 2 lines
	 * @param otherLine: {Line}
	 * @returns {Point}
	 */
	getIntersectionPointWith(otherLine) {
		// uses determinant formula to get the intersection point
		const x = ((this.p1.x * this.p2.y - this.p1.y * this.p2.x) *
				(otherLine.p1.x - otherLine.p2.x) - (this.p1.x - this.p2.x) *
				(otherLine.p1.x * otherLine.p2.y - otherLine.p1.y * otherLine.p2.x)) /
			((this.p1.x - this.p2.x) * (otherLine.p1.y - otherLine.p2.y) -
				(this.p1.y - this.p2.y) * (otherLine.p1.x - otherLine.p2.x));

		const y = ((this.p1.x * this.p2.y - this.p1.y * this.p2.x) *
				(otherLine.p1.y - otherLine.p2.y) - (this.p1.y - this.p2.y) *
				(otherLine.p1.x * otherLine.p2.y - otherLine.p1.y * otherLine.p2.x)) /
			((this.p1.x - this.p2.x) * (otherLine.p1.y - otherLine.p2.y) -
				(this.p1.y - this.p2.y) * (otherLine.p1.x - otherLine.p2.x));

		return new Point(x, y);
	}

	getAngle() {
		const angle = Math.atan2(this.p2.y - this.p1.y, this.p2.x - this.p1.x);
		return angle >= 0 ? angle : angle + 2 * Math.PI;
	}
}
