import {getResource} from "@/api/RequestHandler";

export const styles_store = {
	state: () => ({
		styles: []
	}),
	mutations: {
		setStyles (state, payload) {
			state.styles = payload
		}
	},
	getters: {
		getStyles(state) {
			if(state.styles.length === 0) {
				getResource('interior_style').then(({data, error}) => {
					state.styles = data
				})
			}

			return state.styles
		}
	}
}
