<template>
	<div style="background-color: #F4F4F4; padding: 2em 8% 0 8%; height: 100vh; overflow-y: scroll">
		<div>
			<a href="/public">
				<img src="../../assets/logo.png" style="width: 12em; margin-bottom: 8em">
			</a>
		</div>
		<div
			style="filter: drop-shadow(0 0 25px #C6C6C6); border-radius: 1em; font-family: 'Poppins'; background-color: white; padding: 4em 9%; margin-bottom: 5em">
			<h3 style="font-family: 'Poppins'; font-weight: 600; font-size: 3em; margin-bottom: .5em">
				Contact Information
			</h3>

			<p>
				Nico Plattner
			</p>
			<p>
				Raffaelgasse 25<br>Staircase 1, Top 14<br> 1200 Vienna<br>Austria
			</p>
			<p>
				nico.plattner@roomy.design
			</p>
			<button v-on:click="$router.back()" class="btn btn-primary" style="background-color: rgb(94,71,159); border: 0">Return</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "ImprintPage",
}

</script>

<style scoped>

</style>
