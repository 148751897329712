import InputHandler from "@/components/editor/features/InputHandler";
import Point from "@/components/editor/simple-geometry/Point";
import {Furniture} from "@/components/editor/building-elements/Furniture";
import FurnitureMoveHandler from "@/components/editor/features/FurnitureMoveHandler";
import SelectFloorOptions from "@/components/editor/features/SelectFloorOptions.vue";
import {createComponent} from "@/components/common/ComponentCreator";
import {eventBus} from "../../../main";
import FurnitureRotateHandler from "@/components/editor/features/FurnitureRotateHandler";
import EditDoorHandler from "@/components/editor/features/EditDoorHandler";
import EditDoorOptions from "@/components/editor/features/EditDoorOptions.vue";
import MoveWallHandler from "@/components/editor/features/MoveWallHandler";
import EditWindowOptions from "@/components/editor/features/EditWindowOptions.vue";
import WindowScaleHandler from "@/components/editor/features/WindowScaleHandler";

export default class SelectHandler extends InputHandler {

	constructor() {
		super();
		this.options = null;
		this.prevSelected = null;
		this.selected = null;
	}

	handleMouseDown(e, data, floorplan) {
		this.prevSelected = this.selected;
		this.selected = null;

		const p = new Point(
			data.canvas.panX + e.offsetX / data.canvas.scaleFactor,
			data.canvas.panY + e.offsetY / data.canvas.scaleFactor
		);

		for (let i = 0; i < data.space.length; i++) {
			const element = data.space[i];

			if(element.detectRotate && element.detectRotate(p) && element instanceof Furniture && element.selected) {
				this.subsequentHandler = new FurnitureRotateHandler();
				this.element = element;
				element.selected = true;
				this.subsequentHandler.handleMouseDown(p, this.element);
				return;
			} else {
				element.selected = false;
			}

			if(element.getPath().containsPoint(p) && element instanceof Furniture) {
				this.subsequentHandler = new FurnitureMoveHandler();
				this.element = element;
				element.selected = true;
				this.subsequentHandler.handleMouseDown(p, this.element);
				return;
			} else {
				element.selected = false;
			}
		}

		let elementSelected = false;

		floorplan.rooms.map(room => room.walls).flat().forEach(wall => {
			wall.windows.filter(w => w.selected).forEach(window => {
				const selectDistance = 10;
				if(p.distanceTo(window.position.ps) < selectDistance) {
					elementSelected = true;
					this.subsequentHandler = new WindowScaleHandler();
					this.subsequentHandler.handleMouseDown(window.position.ps, window);
				} else if(p.distanceTo(window.position.pe) < selectDistance) {
					elementSelected = true;
					this.subsequentHandler = new WindowScaleHandler();
					this.subsequentHandler.handleMouseDown(window.position.pe, window);
				}
			});

			if(!elementSelected && wall.getGeometryAsPolygon().containsPoint(p)) {

				wall.doors.forEach(door => {
					if(door.getGeometryAsPolygon(wall.thickness).containsPoint(p)) {
						this.options = createComponent(EditDoorOptions, {door: door, wall: wall})
						elementSelected = true;
						door.selected = true;
						this.selected = door;
					} else {
						door.selected = false;
					}
				})

				wall.windows.forEach(window => {
					if(window.getGeometryAsPolygon(wall.thickness).containsPoint(p)) {
						this.options = createComponent(EditWindowOptions, {window: window, wall: wall})
						elementSelected = true;
						window.selected = true;
						this.selected = window;
					} else {
						window.selected = false;
					}
				})

				if(!elementSelected) {
					this.subsequentHandler = new MoveWallHandler();
					this.subsequentHandler.handleMouseDown(p, wall, floorplan);
					elementSelected = true;
				}
			}
		});

		if(!elementSelected) {
			floorplan.rooms.forEach(room => {
				if(room.getPath().containsPoint(p)) {
					room.selected = true;
					elementSelected = true;
					this.options = createComponent(SelectFloorOptions, {room: room})
				} else {
					room.selected = false;
				}
			});
		}
		this.prevSelected ? this.prevSelected.selected = false : null;

		if(!elementSelected) {
			this.options = null;
		}

		eventBus.emit('changeMode', null); // this triggers the option reload
	}

	handleMouseUp(e, data, floorplan) {
		if(this.subsequentHandler) {
			const p = new Point(
				data.canvas.panX + e.offsetX / data.canvas.scaleFactor,
				data.canvas.panY + e.offsetY / data.canvas.scaleFactor
			);

			this.subsequentHandler.handleMouseUp(p, this.element);
			this.subsequentHandler = null;
			this.element = null;
		}
	}

	handleMouseMove(e, data, floorplan) {
		if(this.subsequentHandler) {
			const p = new Point(
				data.canvas.panX + e.offsetX / data.canvas.scaleFactor,
				data.canvas.panY + e.offsetY / data.canvas.scaleFactor
			);

			this.subsequentHandler.handleMouseMove(p, this.element);
		}
	}

	getOptions() {
		return this.options;
	}
}
