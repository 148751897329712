<template>
	<div ref="canvasParent" style="width: 100%; height: 100%">
		<floorplan-canvas
			v-bind:class="{ grab: mode === 'pan' }"
			height="500"
			ref="canvas"
			v-bind:draw-bg="false"
			v-bind:space="space"
			v-bind:floorplan="floorplan">
		</floorplan-canvas>
	</div>
</template>

<script>
import {eventBus} from "@/main";
import Floorplan from "@/components/editor/building-elements/Floorplan";
import FloorplanCanvas from "@/components/editor/drawing/Canvas";

export default {
	name: "simple-viewer-component",
	components: {FloorplanCanvas},
	props: {
		floorplan: Floorplan,
		space: Array,
	},
	data() {
		return {
			canvas: null,
			wallThickness: 15,
			mode: 'room',
			handler: null,
			zoomPanHandler: null,
			floorPattern: null
		}
	},
	mounted() {
		this.canvas = this.$refs.canvas;

		eventBus.on('redraw', (args) => {
			this.canvas.clear();
			this.canvas.drawAll();
		});

		this.canvas.drawAll();
	}
}
</script>

<style scoped>
.grab {
	cursor: grab;
}

</style>
