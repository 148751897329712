import Point from "@/components/editor/simple-geometry/Point";

export default class Segment {

	/**
	 * @param point1 {Point}
	 * @param point2 {Point}
	 */
	constructor(point1, point2) {
		this.ps = point1;
		this.pe = point2;
	}

	/**
	 * checks if 2 segments are equal
	 * @param otherSegment {Segment}
	 * @returns {boolean}
	 */
	equals(otherSegment) {
		return (otherSegment.ps.equals(this.ps) && otherSegment.pe.equals(this.pe))
		|| (otherSegment.ps.equals(this.pe) && otherSegment.pe.equals(this.ps));
	}

	/**
	 * @returns {number}
	 */
	getLength() {
		const lengthOnX = Math.abs(this.ps.x - this.pe.x);
		const lengthOnY = Math.abs(this.ps.y - this.pe.y);
		return Math.sqrt(Math.pow(lengthOnX, 2) + Math.pow(lengthOnY, 2));
	}

	/**
	 * checks if 2 segments have a point in common
	 * @param otherSegment {Segment}
	 * @returns {boolean}
	 */
	crosses(otherSegment) {
		if(this.ps.equals(otherSegment.ps) || this.ps.equals(otherSegment.pe) || this.pe.equals(otherSegment.ps) || this.pe.equals(otherSegment.pe)) {
			return true; // shared endpoint means they cross
		}
		if(Math.abs((this.ps.x - this.pe.x) / (this.ps.y - this.pe.y)) === Math.abs((otherSegment.ps.x - otherSegment.pe.x) / (otherSegment.ps.y - otherSegment.pe.y))) {
			return false; // same slope means parallel means they don't cross
		}

		// solution using dot product
		const dx0 = this.pe.x - this.ps.x;
		const dx1 = otherSegment.pe.x - otherSegment.ps.x;
		const dy0 = this.pe.y - this.ps.y;
		const dy1 = otherSegment.pe.y - otherSegment.ps.y;
		const p0 = dy1 * (otherSegment.pe.x - this.ps.x) - dx1 * (otherSegment.pe.y - this.ps.y);
		const p1 = dy1 * (otherSegment.pe.x - this.pe.x) - dx1 * (otherSegment.pe.y - this.pe.y);
		const p2 = dy0 * (this.pe.x - otherSegment.ps.x) - dx0 * (this.pe.y - otherSegment.ps.y);
		const p3 = dy0 * (this.pe.x - otherSegment.pe.x) - dx0 * (this.pe.y - otherSegment.pe.y);

		return (p0*p1<=0) && (p2*p3<=0)
	}

	/**
	 * check if point is on segment
	 * @param point {Point}
	 * @returns {boolean}
	 */
	containsPoint(point) {
		const length = this.getLength();
		const lengthToPoint = new Segment(this.ps, point).getLength();
		const lengthFromPoint = new Segment(this.pe, point).getLength();
		return lengthToPoint + lengthFromPoint === length;
	}

	/**
	 * check if segment contains other segment
	 * @param otherSegment {Segment}
	 * @returns {boolean}
	 */
	containsSegment(otherSegment) {
		return this.containsPoint(otherSegment.ps) && this.containsPoint(otherSegment.pe);
	}

	/**
	 * checks if segment overlaps other segment
	 * @param otherSegment {Segment}
	 * @returns {boolean}
	 */
	overlaps(otherSegment) {
		const overlap = this.containsPoint(otherSegment.ps) || this.containsPoint(otherSegment.pe) || otherSegment.containsPoint(this.ps) || otherSegment.containsPoint(this.pe);
		return overlap && !this.crosses(otherSegment);
	}

	/**
	 * @param otherSegment {Segment}
	 * @returns {Point}
	 */
	getCrossingPointWith(otherSegment) {
		if(!this.crosses(otherSegment)) {
			return null;
		}

		if(this.ps.equals(otherSegment.ps) || this.ps.equals(otherSegment.pe)) {
			return this.ps;
		} else if(this.pe.equals(otherSegment.ps) || this.pe.equals(otherSegment.pe)) {
			return this.pe;
		}

		// uses bezier parameters for calculation
		const t = ((this.ps.x - otherSegment.ps.x) * (otherSegment.ps.y - otherSegment.pe.y) -
				(this.ps.y - otherSegment.ps.y) * (otherSegment.ps.x - otherSegment.pe.x)) /
			((this.ps.x - this.pe.x) * (otherSegment.ps.y - otherSegment.pe.y) -
				(this.ps.y - this.pe.y) * (otherSegment.ps.x - otherSegment.pe.x));

		const x = this.ps.x + t * (this.pe.x - this.ps.x);
		const y = this.ps.y + t * (this.pe.y - this.ps.y);

		return new Point(x, y);
	}
}
