<template>
	<div>
		<h2 style="font-weight: 600; font-family: 'Poppins'; font-size: 1.3em; margin-bottom: 1.5rem">
			Who else lives in your home?
		</h2>
		<div class="container" style="margin: 0; padding: 0">
			<div class="row g-3" style="margin-bottom: 2rem">
				<div class="col-6">
					<div style="background-color: #E2E0DB; padding: 1rem; border-radius: .3rem" @click="partner = !partner">
						<div class="form-check">
							<input v-model="partner" class="form-check-input" type="checkbox" id="flexCheckDefault">
							<label class="form-check-label">
								My partner
							</label>
						</div>
					</div>
				</div>
			</div>
			<div class="row g-3">
				<div class="col-6">
					<div style="background-color: #E2E0DB; padding: 1rem; border-radius: .3rem">
						<div>
							<label class="form-check-label">
								Babies
							</label>
							<div style="float: right; display: flex; font-size: 1.2em">
								<i class='bx bxs-plus-circle' style="font-size: 1.5em; margin: 0 0.2em; cursor: pointer" @click="babies += 1"></i>
								<div>
									{{babies}}
								</div>
								<i class="bx bxs-minus-circle" style="font-size: 1.5em; margin: 0 0.2em; cursor: pointer" @click="babies -= (babies > 0 ? 1 : 0)"></i>
							</div>
						</div>
					</div>
				</div>
				<div class="col-6">
					<div style="background-color: #E2E0DB; padding: 1rem; border-radius: .3rem">
						<div>
							<label class="form-check-label">
								Toddlers
							</label>
							<div style="float: right; display: flex; font-size: 1.2em">
								<i class='bx bxs-plus-circle' style="font-size: 1.5em; margin: 0 0.2em; cursor: pointer" @click="toddlers += 1"></i>
								<div>
									{{toddlers}}
								</div>
								<i class="bx bxs-minus-circle" style="font-size: 1.5em; margin: 0 0.2em; cursor: pointer" @click="toddlers -= (toddlers > 0 ? 1 : 0)"></i>
							</div>
						</div>
					</div>
				</div>
				<div class="col-6">
					<div style="background-color: #E2E0DB; padding: 1rem; border-radius: .3rem">
						<div>
							<label class="form-check-label">
								Preschoolers
							</label>
							<div style="float: right; display: flex; font-size: 1.2em">
								<i class='bx bxs-plus-circle' style="font-size: 1.5em; margin: 0 0.2em; cursor: pointer" @click="preschoolers += 1"></i>
								<div>
									{{preschoolers}}
								</div>
								<i class="bx bxs-minus-circle" style="font-size: 1.5em; margin: 0 0.2em; cursor: pointer" @click="preschoolers -= (preschoolers > 0 ? 1 : 0)"></i>
							</div>
						</div>
					</div>
				</div>
				<div class="col-6">
					<div style="background-color: #E2E0DB; padding: 1rem; border-radius: .3rem">
						<div>
							<label class="form-check-label">
								Middleschoolers
							</label>
							<div style="float: right; display: flex; font-size: 1.2em">
								<i class='bx bxs-plus-circle' style="font-size: 1.5em; margin: 0 0.2em; cursor: pointer" @click="middleschoolers += 1"></i>
								<div>
									{{middleschoolers}}
								</div>
								<i class="bx bxs-minus-circle" style="font-size: 1.5em; margin: 0 0.2em; cursor: pointer" @click="middleschoolers -= (middleschoolers > 0 ? 1 : 0)"></i>
							</div>
						</div>
					</div>
				</div>
				<div class="col-6">
					<div style="background-color: #E2E0DB; padding: 1rem; border-radius: .3rem">
						<div>
							<label class="form-check-label">
								Teenagers
							</label>
							<div style="float: right; display: flex; font-size: 1.2em">
								<i class='bx bxs-plus-circle' style="font-size: 1.5em; margin: 0 0.2em; cursor: pointer" @click="teens += 1"></i>
								<div>
									{{teens}}
								</div>
								<i class="bx bxs-minus-circle" style="font-size: 1.5em; margin: 0 0.2em; cursor: pointer" @click="teens -= (teens > 0 ? 1 : 0)"></i>
							</div>
						</div>
					</div>
				</div>
				<div class="col-6">
					<div style="background-color: #E2E0DB; padding: 1rem; border-radius: .3rem">
						<div>
							<label class="form-check-label">
								Young adults
							</label>
							<div style="float: right; display: flex; font-size: 1.2em">
								<i class='bx bxs-plus-circle' style="font-size: 1.5em; margin: 0 0.2em; cursor: pointer" @click="youngAdults += 1"></i>
								<div>
									{{youngAdults}}
								</div>
								<i class="bx bxs-minus-circle" style="font-size: 1.5em; margin: 0 0.2em; cursor: pointer" @click="youngAdults -= (youngAdults > 0 ? 1 : 0)"></i>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
		<button style="margin: 1rem  1rem 0 0" class="btn btn-secondary" v-on:click="navigate('/app/welcome/3')">Go Back</button>
		<button style="background-color: #57039F; border-color: #57039F; margin-top: 1rem" class="btn btn-primary" v-on:click="navigate('/app/welcome/5')">Continue</button>
	</div>
</template>


<script>
export default {
	name: "FamilySelection",
	data() {
		return {
			partner: false,
			babies: 0,
			toddlers: 0,
			preschoolers: 0,
			middleschoolers: 0,
			teens: 0,
			youngAdults: 0,
		}
	},
	computed: {
		familyInStore() {
			return this.$store.getters.getFamily;
		}
	},
	watch: {
		familyInStore: {
			handler(newVal) {
				if(newVal) {
					this.partner = newVal.partner ? newVal.partner : 0;
					this.babies = newVal.babies ? newVal.babies : 0;
					this.toddlers = newVal.toddlers ? newVal.toddlers : 0;
					this.preschoolers = newVal.preschoolers ? newVal.preschoolers : 0;
					this.middleschoolers = newVal.middleschoolers ? newVal.middleschoolers : 0;
					this.teens = newVal.teens ? newVal.teens : 0;
					this.youngAdults = newVal.youngAdults ? newVal.youngAdults : 0;
				}
			},
			immediate: true,
		}
	},
	methods: {
		navigate(newRoute) {
			this.$store.commit('setFamily', {
				partner: this.partner,
				babies: this.babies,
				toddlers: this.toddlers,
				preschoolers: this.preschoolers,
				middleschoolers: this.middleschoolers,
				teens: this.teens,
				youngAdults: this.youngAdults,
			});
			this.$router.push(newRoute);
		},
	},
}
</script>


<style scoped>

</style>
