<template>
	<div>
		<div style="text-align: center; margin-bottom: 2rem">
			<h1>Reset Password</h1>
		</div>
		<form v-on:submit="sendPasswordResetRequest($event)" class="needs-validation" novalidate>
			<div class="form-group">
				<label for="email">Email</label>
				<input v-model="email" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" required>
				<div class="invalid-feedback">
					Email address invalid.
				</div>
			</div>
			<button type="submit" class="btn">Reset password</button>
		</form>
	</div>
</template>

<script>
import {supabase} from "@/api/supabase.js";

export default {
	name: "reset-password-form",
	data() {
		return {
			email: '',
		};
	},
	methods: {
		async sendPasswordResetRequest(event) {
			event.preventDefault()

			const form = event.target
			let formValid = true
			Array.from(form.elements).forEach((input) => {
				// Check if the input is valid
				if(!input.validity.valid) {
					// If the input is not valid, add 'is-invalid' class to show the error message
					input.classList.add('is-invalid');
					formValid = false;
				} else {
					// If the input is valid, remove 'is-invalid' class to hide the error message
					input.classList.remove('is-invalid');
				}
			});

			if(!formValid) {
				return;
			}

			const { data, error } = await supabase.auth.resetPasswordForEmail(this.email, {
				redirectTo: window.location.origin + '/?modal=update-password',
			})

			if(error) {
				// setLMsg(error.message)
			} else {
				this.$router.push('?modal=reset-request-sent');
			}
		}
	}
}
</script>

<style scoped>
ion-text h1 {
	text-align: center;
	font-size: 2em;
}

.form-group {
	margin-top: .5em;
}

button {
	margin-top: 1em;
	margin-bottom: 1em;
}

button {
	background-color: rgb(87, 3, 159);
	border-color: rgb(87, 3, 159);
	color: white;
}

button:hover {
	background-color: rgb(134, 82, 179);
	border-color: rgb(134, 82, 179);
	color: white;
}
</style>
