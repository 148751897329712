import Polygon from "polygon";
import Point from "./Point";
import Segment from "./Segment";
import {difference, union} from "polygon-clipping";
import DirectedSegment from "./DirectedSegment";
import Vector from "./Vector";
import polylabel from "polylabel";

export function orderPointsHorizontalVertical(points) {
	return points.sort((p1, p2) => {
		if(p1.x > p2.x) {
			return -1;
		} else if(p1.x === p2.x) {
			if(p1.y > p2.y) {
				return -1;
			} else {
				return 0;
			}
		} else {
			return 1;
		}
	});
}

export function rotateSegmentAroundOrigin(segment, rotationOrigin, angleInRadians) {
	const p1 = rotatePointAroundOrigin(segment.ps, rotationOrigin, angleInRadians);
	const p2 = rotatePointAroundOrigin(segment.pe, rotationOrigin, angleInRadians);
	return new Segment(p1, p2);
}

export function rotatePointAroundOrigin(point, rotationOrigin, angleInRadians) {
	const x = Math.cos(angleInRadians) * (point.x - rotationOrigin.x)
		- Math.sin(angleInRadians) * (point.y - rotationOrigin.y) + rotationOrigin.x;
	const y = Math.sin(angleInRadians) * (point.x - rotationOrigin.x)
		+ Math.cos(angleInRadians) * (point.y - rotationOrigin.y) + rotationOrigin.y;

	return new Point(x, y);
}

export function extendSegment(segment, extensionLength) {
	const directed = new DirectedSegment(segment.ps, segment.pe);
	const vector = Vector.createFromDirectedSegment(directed);
	const extensionVector = vector.getUnitVector().multiply(extensionLength);
	const opposing = new Vector(-extensionVector.x, -extensionVector.y);
	const psNew = segment.ps.getOffsetPoint(opposing);
	const peNew = segment.pe.getOffsetPoint(extensionVector);
	return new Segment(psNew, peNew);
}

export function addThicknessToLine(x1, y1, x2, y2, padding) {
	const lineAngle = getAngleBetweenCoordinatesInRadians(x1, y1, x2, y2);
	const oppCathetus = Math.sin(lineAngle) * padding;
	const adjCathetus = Math.cos(lineAngle) * padding;

	const shape = [];

	shape.push(getCornerWithSimplePadding(x1, y1, oppCathetus, adjCathetus));
	shape.push(getCornerWithSimplePadding(x2, y2, oppCathetus, adjCathetus));
	shape.push(getCornerWithSimplePadding(x2, y2, -oppCathetus, -adjCathetus));
	shape.push(getCornerWithSimplePadding(x1, y1, -oppCathetus, -adjCathetus));

	return shape;
}

export function addPaddingToLine(x1, y1, x2, y2, padding) {
	const lineAngle = getAngleBetweenCoordinatesInRadians(x1, y1, x2, y2);
	const oppCathetus = Math.sin(lineAngle) * padding;
	const adjCathetus = Math.cos(lineAngle) * padding;

	const shape = [];

	shape.push(getCornerWithPadding(x1, y1, oppCathetus, -adjCathetus));
	shape.push(getCornerWithPadding(x2, y2, adjCathetus, oppCathetus));
	shape.push(getCornerWithPadding(x2, y2, -oppCathetus, adjCathetus));
	shape.push(getCornerWithPadding(x1, y1, -adjCathetus, -oppCathetus));

	return shape;
}

function getAngleBetweenCoordinatesInRadians(x1, y1, x2, y2) {
	return Math.atan2(y2 - y1, x2 - x1);
}

export function getAngleBetweenPointsInRadians(p1, p2) {
	return Math.atan2(p2.y - p1.y, p2.x - p1.x);
}

export function getAngleBetweenVectors(v1, v2) {
	const t1 = Math.atan2(v1.y, v1.x);
	const t2 = Math.atan2(v2.y, v2.x);
	return (((t2 - t1) % (2 * Math.PI)) + (2 * Math.PI)) % (2 * Math.PI);
}

function getCornerWithPadding(xOriginal, yOriginal, paddingX, paddingY) {
	const x = xOriginal + paddingX + paddingY;
	const y = yOriginal - paddingX + paddingY;
	return { x: x, y: y };
}

function getCornerWithSimplePadding(xOriginal, yOriginal, paddingX, paddingY) {
	const x = xOriginal + paddingX;
	const y = yOriginal + paddingY;
	return { x: x, y: y };
}

export function checkPolygonIntersection(polygon1, polygon2) {
	return !polygonsEqualExceptVertexOrder(polygonCut(polygon1, polygon2)[0], polygon1);
}

export function centerOfPolygon(polygon) {
	const points = polygonToArray(polygon);
	const p = polylabel(points);
	return new Point(p[0], p[1]);
}

export function polygonCut(fromPolygon, cutPolygon) {
	const fromPolygonPoints = polygonToArray(fromPolygon);
	const cutPolygonPoints = polygonToArray(cutPolygon);
	const diff = difference(fromPolygonPoints, cutPolygonPoints);
	if(diff.length > 0) {
		const result = [];
		diff.forEach(polygon => result.push(new Polygon(polygon[0].slice(0, -1))));
		return result;
	} else {
		return [];
	}
}

export function polygonUnion(polygon1, polygon2) {
	const polygon1Points = polygonToArray(polygon1);
	const polygon2Points = polygonToArray(polygon2);
	const polygonUnion = union(polygon1Points, polygon2Points);

	if(polygonUnion.length > 0) {
		const result = [];
		polygonUnion.forEach(polygon => result.push(new Polygon(polygon[0].slice(0, -1))));
		return result;
	} else {
		return [];
	}
}

export function polygonToArray(polygon) {
	const array = []

	polygon.points.forEach(point => {
		array.push([point.x, point.y]);
	});

	return[array];
}

export function polygonsEqualExceptVertexOrder(polygon1, polygon2) {
	let offset = -1

	if(polygon1.points.length !== polygon2.points.length) {
		return false;
	}

	for (let i = 0; i < polygon2.points.length; i++) {
		if(polygon1.points[0].x === polygon2.points[i].x && polygon1.points[0].y === polygon2.points[i].y) {
			offset = i;
			break;
		}
	}

	if(offset === 0) {
		return polygon1.equal(polygon2);
	} else if(offset > 0) {
		const shifted = new Polygon(polygon2.points.slice(offset, polygon2.length).concat(polygon2.points.slice(0, offset)));
		return shifted.equal(polygon1);
	} else {
		return false;
	}
}

