<template>
	<div>
		<div class="container-sm" id="content" style="padding: 0 1em; font-weight: 600; font-family: 'Poppins'; font-size: 1.3em">
			<div id="room-overview" style="margin-bottom: .5em; margin-left: 1.5em">
				My Rooms
			</div>

			<div class="row">
				<div v-for="room in rooms" :key="room.id" class="col-xl-4 col-6" style="margin-bottom: 1rem">
					<div style="width: 100%; height: 20rem; max-height: 50vw; border-radius: .4rem; overflow: hidden; float: left; -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);">
						<viewer-component ref="viewer" v-bind:floorplan="room"></viewer-component>
						<button v-on:click="editFloorplan(room)" style="z-index: 100; position: relative; bottom: 4.5rem; left: 1.5rem; width: 3rem; height: 3rem; border-radius: 1.5rem">
							<i class='bx bx-edit-alt'></i>
						</button>
					</div>
				</div>
			</div>
			<router-link to="/app/presets">
				<button type="button" class="btn btn-light" style="margin-bottom: 6rem; margin-top: .5em; padding-left: 2em; padding-right: 2em; font-family: 'Poppins'; font-weight: 400; background-color: #57039F; color: white">
					Create New Room
				</button>
			</router-link>
		</div>
	</div>
</template>

<script>
import ViewerComponent from "@/components/editor/Viewer.vue";

export default{
	name: "EditorOverview",
	components: {ViewerComponent},
	computed: {
		rooms() {
			return this.$store.getters.getRooms;
		}
	},
	methods: {
		editFloorplan(room) {
			this.$store.commit('setSpace', []);
			this.$store.commit('setRoomId', room.id);
			this.$router.push({ name: 'designer' });
		},
	},
}
</script>


<style scoped>

</style>
