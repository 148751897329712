import InputHandler from "@/components/editor/features/InputHandler";
import Point from "@/components/editor/simple-geometry/Point";
import {
	getSnappingPointAndSnappedWall,
	getSnappingPointAndWallAndNormal
} from "@/components/editor/features/SnappingHelper";
import DirectedSegment from "@/components/editor/simple-geometry/DirectedSegment";
import Vector from "@/components/editor/simple-geometry/Vector";
import Door from "@/components/editor/building-elements/Door";
import {eventBus} from "@/main";

export default class DoorHandler extends InputHandler {

	handleMouseDown(e, data, floorplan) {
		const p = new Point(
			data.canvas.panX + e.offsetX / data.canvas.scaleFactor,
			data.canvas.panY + e.offsetY / data.canvas.scaleFactor
		);

		const snap = getSnappingPointAndWallAndNormal(floorplan, p);

		if((snap.x !== p.y || snap.y !== p.y) && snap.wall) {
			const directed = new DirectedSegment(snap.wall.position.ps, snap.wall.position.pe);
			const vector = Vector.createFromDirectedSegment(directed);
			const unit = vector.getUnitVector().multiply(90);
			const start = snap.point.getOffsetPoint(unit.multiply(-0.5));
			const line = DirectedSegment.createFrom(start, unit);
			let door = null;

			const normal = new Vector(unit.getUnitVector().y, -unit.getUnitVector().x);
			if(snap.normal.getUnitVector().equals(normal)) {
				door = new Door(line.start.x, line.start.y, line.end.x, line.end.y);
			} else {
				door = new Door(line.end.x, line.end.y, line.start.x, line.start.y);
			}

			snap.wall.doors.push(door);
			eventBus.emit('changeMode', 'select');
		}
	}

	handleMouseUp() {
		console.log('mouseup')
	}

	handleMouseMove(e, data, floorplan) {
		const p = new Point(
			data.canvas.panX + e.offsetX / data.canvas.scaleFactor,
			data.canvas.panY + e.offsetY / data.canvas.scaleFactor
		);

		const snap = getSnappingPointAndWallAndNormal(floorplan, p);

		if((snap.point.x !== p.y || snap.point.y !== p.y) && snap.wall) {
			const directed = new DirectedSegment(snap.wall.position.ps, snap.wall.position.pe);
			const vector = Vector.createFromDirectedSegment(directed);
			const unit = vector.getUnitVector().multiply(90);
			const start = snap.point.getOffsetPoint(unit.multiply(-0.5));
			const line = DirectedSegment.createFrom(start, unit);
			let door = null;

			const normal = new Vector(unit.getUnitVector().y, -unit.getUnitVector().x);
			if(snap.normal.getUnitVector().equals(normal)) {
				door = new Door(line.start.x, line.start.y, line.end.x, line.end.y);
			} else {
				door = new Door(line.end.x, line.end.y, line.start.x, line.start.y);
			}

			data.canvas.drawDoor(door, snap.wall.thickness);
		}
	}
}
