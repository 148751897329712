import AbstractBuildingElement from "@/components/editor/building-elements/AbstractBuildingElement";
import Segment from "@/components/editor/simple-geometry/Segment";
import Point from "@/components/editor/simple-geometry/Point";
import {
	addThicknessToLine, extendSegment,
	getAngleBetweenPointsInRadians,
	rotateSegmentAroundOrigin
} from "@/components/editor/simple-geometry/Operations";
import {
	drawArc,
	drawRooms,
	drawSegment,
} from "@/components/editor/drawing/DrawingHelper";
import Polygon from "polygon";

export default class Door extends AbstractBuildingElement {

	constructor(x1, y1, x2, y2, right) {
		super();
		this.position = new Segment(
			new Point(x1, y1),
			new Point(x2, y2),
		);
		this.right = right;
	}

	drawOutline(context, wallThickness) {
		if(this.right) {
			const shortened = extendSegment(this.position, -5);
			const rotated = rotateSegmentAroundOrigin(shortened, shortened.pe, Math.PI/2);
			drawSegment(context, rotated);
			drawArc(context, rotated.pe, rotated.getLength(),
				Math.PI*1.5 + getAngleBetweenPointsInRadians(rotated.pe, rotated.ps),
				Math.PI*2 + getAngleBetweenPointsInRadians(rotated.pe, rotated.ps));
		} else {
			const shortened = extendSegment(this.position, -5);
			const rotated = rotateSegmentAroundOrigin(shortened, shortened.ps, -Math.PI/2);
			drawSegment(context, rotated);
			drawArc(context, rotated.ps, rotated.getLength(),
				Math.PI*1 + getAngleBetweenPointsInRadians(rotated.pe, rotated.ps),
				Math.PI*1.5 + getAngleBetweenPointsInRadians(rotated.pe, rotated.ps));
		}
	}

	drawFilling(context, wallThickness, floorPattern) {
		if(floorPattern) {
			// drawRooms(context, null, [new Polygon(this.getGeometry(wallThickness + 1))], floorPattern, true);
		}
	}

	getGeometry(wallThickness) {
		return addThicknessToLine(this.position.ps.x, this.position.ps.y, this.position.pe.x, this.position.pe.y, wallThickness / 2)
	}

	getGeometryAsPolygon(wallThickness) {
		const geometry = this.getGeometry(wallThickness);
		return new Polygon([
			[geometry[0].x, geometry[0].y],
			[geometry[1].x, geometry[1].y],
			[geometry[2].x, geometry[2].y],
			[geometry[3].x, geometry[3].y]
		]);
	}

	switchSide() {
		this.right = !this.right;
	}

	switchInOut() {
		this.right = !this.right;
		this.position = new Segment(this.position.pe, this.position.ps);
	}

	getPath() {
		return this.position;
	}


}
