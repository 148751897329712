<template>
	<div>
		<div style="text-align: center; margin-bottom: 2rem">
			<h1>Request Sent</h1>
		</div>
		<form v-on:submit="sendPasswordResetRequest($event)" class="needs-validation" novalidate>
			<div class="form-group">
				Please check your email for a link to reset your password.
			</div>
		</form>
	</div>
</template>

<script>
export default {
	name: "reset-request-sent",
}
</script>

<style scoped>
ion-text h1 {
	text-align: center;
	font-size: 2em;
}

.form-group {
	margin-top: .5em;
}
</style>
