<template>
	<div class="container" style="padding: 0.5rem 1.7rem; padding-bottom: 5rem" v-if="selectedProduct">
		<div class="row">
			<div class="col-12 col-xl-4 col-lg-5 col-md-6" style="margin-bottom: 3em">
				<img :src="selectedProduct.img_1 ? selectedProduct.img_1 : product.img_1" style="margin-bottom: 2em">
				<div v-if="product.variation_1_name_en">
					<h4>
						{{ product.variation_1_name_en }}
					</h4>
					<select ref="variation1" class="form-select" v-on:change="selectVariation()">
						<option v-for="(variation1, index1) in variation1Names" :key="index1">{{variation1}}</option>
					</select>
				</div>
				<div v-if="product.variation_2_name_en">
					<h4>
						{{ product.variation_2_name_en }}
					</h4>
					<select ref="variation2" class="form-select" v-on:change="selectVariation()">
						<option v-for="(variation2, index2) in variation2Names" :key="index2">{{variation2}}</option>
					</select>
				</div>
			</div>
			<div class="col px-lg-5">
				<h1 style="margin-top: 0">
					{{ product.name_en }}
				</h1>
				<div style="font-weight: bold">
					{{ (selectedProduct.price_eur ? selectedProduct.price_eur : product.price_eur).toFixed(2) }} €
				</div>
				<div style="margin-top: 2rem">
					{{ product.description_en}}
				</div>
				<div style="margin-top: 2rem">
					<p style="font-weight: bold; margin-bottom: 0">
						Dimensions
					</p>
					<p style="margin-bottom: 0">
						Length: {{ selectedProduct.length ? selectedProduct.length : product.length }} cm
					</p>
					<p style="margin-bottom: 0">
						Width: {{ selectedProduct.width ? selectedProduct.width : product.width }} cm
					</p>
					<p style="margin-bottom: 0">
						Height: {{ selectedProduct.height ? selectedProduct.height : product.height }} cm
					</p>
				</div>
				<a :href="product.link">
					<button class="btn btn-primary" style="background-color: #5E479F; border: 0; margin-top: 1rem">
						Buy now
					</button>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import _ from "lodash";

export default {
	name: "ProductPage",
	data() {
		return {
			selectedProduct: null,
		}
	},
	computed: {
		product() {
			const allFurniture = this.$store.getters.getFurniture;
			if(!allFurniture) return null;
			return allFurniture.find(f => f.uuid === this.$route.params.product_uuid);
		},
		productVariations() {
			const allVariations = this.$store.getters.getFurnitureVariations;
			if(!allVariations) return null;
			return allVariations.filter(v => v.product === this.$route.params.product_uuid);
		},
		variation1Names() {
			if(!this.productVariations) return [];
			return _.uniq(this.productVariations.map(v => v.variation_1_en));
		},
		variation2Names() {
			if(!this.productVariations) return [];
			return _.uniq(this.productVariations.map(v => v.variation_2_en));
		}
	},
	watch: {
		productVariations: {
			handler(newVal) {
				if (newVal && newVal.length > 0) {
					this.selectedProduct = newVal[0];
				}
			},
			immediate: true,
		},
		product: {
			handler(newVal) {
				if (newVal) {
					this.selectedProduct = newVal;
				}
			},
			immediate: true,
		}
	},
	methods: {
		selectVariation() {
			const variation1 = this.$refs.variation1.value;
			const variation2 = this.product.variation_2_name_en ? this.$refs.variation2.value : null;

			this.productVariations.find(v => {
				if(v.variation_1_en === variation1 && v.variation_2_en === variation2) {
					this.selectedProduct = v;
				}
			})
		}
	},
	created() {
		this.$store.dispatch("fetchFurniture");
		this.$store.dispatch("fetchFurnitureVariations");
	},
}

</script>

<style scoped>

</style>
