import Point from "./Point";

export default class DirectedSegment {

	/**
	 * @param start {Point}
	 * @param end {Point}
	 */
	constructor(start, end) {
		this.start = start;
		this.end = end;
	}

	/**
	 * @param startPoint {Point}
	 * @param vector {Vector}
	 */
	static createFrom(startPoint, vector) {
		const start = startPoint;
		const end = new Point(startPoint.x + vector.x, startPoint.y + vector.y);
		return new DirectedSegment(start, end);
	}

	getLength() {
		const lengthOnX = Math.abs(this.start.x - this.end.x);
		const lengthOnY = Math.abs(this.start.y - this.end.y);
		return Math.sqrt(Math.pow(lengthOnX, 2) + Math.pow(lengthOnY, 2));
	}
}
