<template>
	<div>
		<h2 style="font-weight: 600; font-family: 'Poppins'; font-size: 1.3em; margin-bottom: 1.5rem">
			Which of the following styles do you like most? (min. 3)
		</h2>
		<div class="container" style="margin: 0; padding: 0">
			<div class="row gx-3 gy-3" v-if="styles">
				<div class="col-xl-3 col-lg-4 col-md-6" v-for="interiorStyle in styles" :key="interiorStyle">
					<div v-on:click="interiorStyle.selected = !interiorStyle.selected" :class="interiorStyle.selected ? 'styleSelected' : ''" :style="{'background-color': interiorStyle.color, 'padding': 0, 'border-radius': '.3rem', 'overflow': 'hidden', 'cursor': 'pointer', 'color': interiorStyle.textColor ? interiorStyle.textColor : 'black'}">
						<div style="height: 15rem; overflow: hidden; display: flex; justify-content: center;">
							<img :src="interiorStyle.img" style="object-fit: cover; min-width: 100%">
						</div>
						<div style="height: 3rem; display: flex; align-items: center; padding-left: 1rem; font-size: 1.2rem; float: left">
							{{interiorStyle.name_en}}
						</div>
						<div v-if="interiorStyle.selected" style="height: 3rem; display: flex; align-items: center; padding-right: 1rem; font-size: 1.7rem; float: right">
							<i class='bx bx-heart' style="float:right;"></i>
						</div>
					</div>
				</div>
			</div>
		</div>

		<button style="margin: 1rem  1rem 8rem 0" class="btn btn-secondary" v-on:click="navigate('/app/welcome/1')">Go Back</button>
		<button :disabled="styles && styles.filter(style => style.selected).length < 3" style="background-color: #57039F; border-color: #57039F; margin-top: 1rem; margin-bottom: 8rem" class="btn btn-primary" v-on:click="navigate('/app/welcome/3')">Continue</button>
	</div>
</template>


<script>
export default {
	name: "StyleSelection",
	computed: {
		preferenceStyles() {
			return this.$store.getters.getPreferencesStyles;
		},
		styles() {
			return this.$store.getters.getStyles;
		}
	},
	watch: {
		preferenceStyles: {
			handler(newVal) {
				if(newVal && this.styles) {
					this.styles.forEach(style => {
						if(newVal.includes(style.name)) {
							style.selected = true;
						}
					})
				}
			},
			immediate: true,
		}
	},
	methods: {
		navigate(newRoute) {
			this.$store.commit('setPreferencesStyles', this.styles.filter(style => style.selected).map(style => style.id));
			this.$router.push(newRoute);
		},
	},
}
</script>


<style scoped>
.styleSelected {
	outline: #000000 inset 3px !important;
	outline-color: black !important;
}

</style>
