import {getResource} from "@/api/RequestHandler";

export const furniture_store = {
	state: () => ({
		furniture: null
	}),
	actions: {
		fetchFurniture({ commit, state }) {
			if(!state.furniture) {
				return getResource('furniture').then(({ data, error }) => {
					if (!error) {
						commit('setFurniture', data);
					}
				});
			}
		},
	},
	mutations: {
		setFurniture(state, payload) {
			state.furniture = payload
		}
	},
	getters: {
		getFurniture(state) {
			return state.furniture;
		},
	}
}
