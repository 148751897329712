import {getResource} from "@/api/RequestHandler";

export const furniture_category_1_store = {
	state: () => ({
		furniture_category_1: null
	}),
	mutations: {
		set_furniture_categories_1 (state, payload) {
			state.furniture_category_1 = payload
		}
	},
	getters: {
		getFurnitureCategories1(state) {
			if(!state.furniture_category_1) {
				getResource('furniture_category_1').then(({data, error}) => {
					state.furniture_category_1 = data
				})
			}

			return state.furniture_category_1
		}
	}
}
