import {getResource} from "@/api/RequestHandler";

export const furniture_variations_store = {
	state: () => ({
		furniture_variations: null
	}),
	actions: {
		fetchFurnitureVariations({ commit, state }) {
			if(!state.furniture_variations) {
				return getResource('furniture_variation').then(({ data, error }) => {
					if (!error) {
						commit('setFurnitureVariations', data);
					}
				});
			}
		},
	},
	mutations: {
		setFurnitureVariations(state, payload) {
			state.furniture_variations = payload
		}
	},
	getters: {
		getFurnitureVariations(state) {
			return state.furniture_variations;
		},
	}
}
