<template>
	<div id="app-body" style="background-color: #F4F4F4; min-height: 100%">
		<nav v-if="showHeader" ref="nav" class="navbar navbar-expand-lg sticky-top navbar-light bg-light" style="width: 100%; background-color: #F4F4F4 !important;">
			<div class="container" style="max-width: 100%; padding: .5rem 2rem">
				<router-link class="navbar-brand" to="/app">
					<img src="../../../src/assets/logo.png" alt="" width="150" class="d-inline-block align-text-top">
				</router-link>

				<div v-if="!user">
					<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-icon"></span>
					</button>
					<div class="collapse navbar-collapse" id="navbarNavDropdown" style="flex-grow: 0">
						<ul class="navbar-nav" style="float: right">
							<li class="nav-item" style="">
								<button v-on:click="switchToRegister($event, true)" type="button" class="btn btn-light" id="open-modal" style="float: right; margin-top: .5em; margin-left: 1.5em; padding-left: 2em; padding-right: 2em; font-family: 'Poppins'; font-weight: 400">
									Login
								</button>
							</li>
							<li class="nav-item">
								<button v-on:click="switchToRegister($event, false)" type="button" class="btn btn-primary" id="open-modal" style="float:right; margin-top: .5em; margin-left: 1.5em; padding-left: 2em; padding-right: 2em; border: 0; background-color: #57039F; font-family: 'Poppins'">
									Register
								</button>
							</li>
						</ul>
					</div>
				</div>

				<div v-if="user" style="font-family: Poppins; position: relative">
					<div id="expandContainer" :class="{expanded: expanded}" style="border-radius: .3rem; position: absolute;	right: .5rem; top: 1rem;">
						<div :style="{visibility: expanded ? 'visible' : 'hidden'}">
							<p style="text-align: right; color: white; margin: .7rem 1rem; cursor: pointer" @click="toggleExpanded(); this.logout()">
								Logout
							</p>
							<p style="text-align: right; color: white; margin: .7rem 1rem; cursor: pointer" @click="toggleExpanded(); this.$router.push({name: 'profile'})">
								Profile
							</p>
						</div>
					</div>

					<div @click="toggleExpanded" style="cursor: pointer; position: relative; -webkit-user-select: none; -ms-user-select: none; user-select: none;">
					<div style="font-size: 1.2em; position: absolute; top: 50%; -ms-transform: translateY(-50%); transform: translateY(-50%); right: 3.5rem">
							{{ user.user_metadata.first_name }}
						</div>
						<div class="purple-bg" style="border-radius: .3em; width: 2.5em; height: 2.5em; position: absolute; top: 50%; -ms-transform: translateY(-50%); transform: translateY(-50%); right: .5rem">
							<div style="position: absolute; top: 50%; -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%); left: 50%">
								{{ user.user_metadata.first_name[0] }}
							</div>
						</div>

					</div>
				</div>
			</div>
		</nav>

		<div class="main-section">
			<NavBar id="app-navbar" v-if="showHeader"></NavBar>
			<router-view id="app-content" :class="{'spaceForSidebar': (showHeader && !onMobile())}" />
		</div>
	</div>
</template>

<script>
import NavBar from "@/components/layout/app/NavBar.vue";
import {onMobile} from "@/components/layout/MobileDetection.js";
import {supabase} from "@/api/supabase.js";

export default {
	name: "App-Layout",
	components: {
		NavBar,
	},
	data() {
		return {
			isOpen: false,
			login: true,
			register: true,
			canDismiss: false,
			expanded: false
		};
	},
	computed: {
		user() {
			return this.$store.getters.getUser;
		},
		showHeader() {
			return this.$route.name !== 'designer';
		},
		mode() {
			return this.$route.query.modal;
		}
	},
	watch: {
		mode() {
			if(!this.mode) {
				this.isOpen = false;
			} else if(this.mode === 'login') {
				this.login = true;
				this.register = false;
				this.isOpen = true;
			} else if(this.mode === 'register') {
				this.login = false;
				this.register = true;
				this.isOpen = true;
			}
		}
	},
	methods: {
		onMobile,
		switchToRegister(event, toLogin) {
			this.$router.push('?modal=' + (toLogin ? 'login' : 'register'));
			this.isOpen = true;
			event.preventDefault();
			this.login = toLogin;
		},
		closeModal() {
			this.$router.replace({'modal': null});
			this.isOpen = false;
		},
		toggleExpanded() {
			this.expanded = !this.expanded;
		},
		logout() {
			supabase.auth.signOut().then(() => {
				if (this.$route.name === 'home') {
					location.reload();
				} else {
					this.$store.commit('setUser', null);
					this.$store.commit('', null);
					this.$router.push({name: 'home'});
				}
			});
		}
	},
}
</script>

<style scoped>

.expanded {
	position: absolute;
	background-color: #57039F;
	height: 5rem;
	width: 7rem;
}

.white-bg{
	color: black;
}

.purple-bg{
	background-color: #57039F;
	color: white
}

@media screen and (min-width: 800px) {
	#app-navbar {
		position: fixed;
		width: 25rem;
	}

	#app-content {
		width: 85%;
	}

	.spaceForSidebar {
		margin: 0 max(15rem, 17%);
	}

	.main-section {
		display: flex;
	}
}


@media screen and (max-width: 1300px) {
	#app-navbar {
		position: fixed;
		width: 5rem;
		overflow: hidden;
		text-wrap: nowrap;
	}

	#app-content {
		width: 90%;
	}

	.spaceForSidebar {
		margin: 0 6rem;
	}
}

@media screen and (max-width: 800px) {
	#app-navbar {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 5rem;
		z-index: 100;
		background-color: #F4F4F4;
		display: flex;
		justify-content: space-evenly;
	}
}
</style>
