<template>
	<div>
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: "WelcomePage",
}
</script>


<style scoped>

</style>
