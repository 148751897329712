<template>
	<div>
		<div style="text-align: center; margin-bottom: 2rem">
			<h1>Register</h1>
		</div>
		<form v-on:submit="register($event)" class="needs-validation" novalidate>
			<div class="form-group">
				<label for="email">Email</label>
				<input v-model="email" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" required>
				<div class="invalid-feedback">
					Email address invalid.
				</div>
			</div>
			<div class="form-group">
				<label for="first-name">First Name</label>
				<input v-model="firstName" type="text" class="form-control" id="exampleInputFirstName" aria-describedby="emailHelp" placeholder="Enter first name" required>
			</div>
			<div class="form-group">
				<label for="email">Last Name</label>
				<input v-model="lastName" type="text" class="form-control" id="exampleInputLastName" aria-describedby="emailHelp" placeholder="Enter last name" required>
			</div>
			<div class="form-group">
				<label for="password">Password</label>
				<input v-model="password" type="password" class="form-control" id="password" placeholder="Password">
				<div class="invalid-feedback">
					Min. 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number
				</div>
			</div>
			<div class="form-group">
				<div class="form-check">
					<input v-model="newsletter" class="form-check-input" type="checkbox" id="newsletter-check">
					<label class="form-check-label" for="newsletter-check">
						Email me about new features and special offers.
					</label>
				</div>
			</div>
			<div class="form-check">
				<input v-model="terms" class="form-check-input" type="checkbox" value="" id="terms-check" required>
				<label class="form-check-label" for="terms-check">
					Agree to terms and conditions.
				</label>
			</div>
			<button type="submit" class="btn">Register</button>
		</form>
	</div>
</template>

<script>
import {supabase} from "@/api/supabase.js";

export default {
	name: "registration-form",
	data() {
		return {
			email: "",
			firstName: "",
			lastName: "",
			password: "",
			repeatPassword: "",
			newsletter: false,
			terms: false
		}
	},
	methods: {
		async register(event) {
			event.preventDefault()
			// Get the form element
			const form = event.target;

			let formValid = true;

			const termsCheck = document.getElementById('terms-check')

			if(!termsCheck.checked) {
				termsCheck.classList.add('is-invalid');
				formValid = false;
			} else {
				termsCheck.classList.remove('is-invalid');
			}

			Array.from(form.elements).forEach((input) => {
				const passwordRequirements = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
				if(input.id === 'password') {
					if(!passwordRequirements.test(input.value)) {
						input.classList.add('is-invalid');
						input.validity.valid = false;
						formValid = false;
					} else {
						input.classList.remove('is-invalid');
					}
				}

				// Check if the input is valid
				if(!input.validity.valid) {
					// If the input is not valid, add 'is-invalid' class to show the error message
					input.classList.add('is-invalid');
					formValid = false;
				} else {
					// If the input is valid, remove 'is-invalid' class to hide the error message
					input.classList.remove('is-invalid');
				}
			});

			if(!formValid) {
				return;
			}

			console.log(this.newsletter)

			const {data, error} = await supabase.auth.signUp(
				{
					email: this.email,
					password: this.password,
					options: {
						data: {
							first_name: this.firstName,
							last_name: this.lastName,
							communication: this.newsletter
						}
					}
				})
			if(error) {
				console.log(error)
			} else {
				console.log(data)
				this.$emit('switchTo', 'confirm-email')
				this.$router.replace({'modal': 'confirm-email'})
				console.log(this.$route)
			}
		}
	}
}
</script>

<style scoped>
ion-text h1 {
	text-align: center;
	font-size: 2em;
}

.form-group {
	margin-top: .5em;
}

button {
	margin-top: 1em;
	margin-bottom: 1em;
}

button {
	background-color: rgb(87, 3, 159);
	border-color: rgb(87, 3, 159);
	color: white;
	width: 7rem !important;
}

button:hover {
	background-color: rgb(134, 82, 179);
	border-color: rgb(134, 82, 179);
	color: white;
}</style>
