<template>
	<div>
		<FamilySelection v-if="homeType !== 'shared'"/>
		<FlatmateSelection v-else-if="homeType === 'shared'"/>
	</div>
</template>


<script>
import FamilySelection from "@/pages/app/welcome/homeTypes/FamilySelection.vue";
import FlatmateSelection from "@/pages/app/welcome/homeTypes/FlatmateSelection.vue";

export default {
	name: "InhabitantsSelection",
	components: {FlatmateSelection, FamilySelection},
	computed: {
		homeType() {
			return this.$store.getters.getHomeType;
		}
	},
}
</script>


<style scoped>

</style>
