import InputHandler from "@/components/editor/features/InputHandler";
import Point from "@/components/editor/simple-geometry/Point";
import {getSnappingPoint} from "@/components/editor/features/SnappingHelper";
import Wall from "@/components/editor/building-elements/Wall";
import Floorplan from "@/components/editor/building-elements/Floorplan";

export default class RoomHandler extends InputHandler {

	handleMouseDown(e, data) {
		const p = new Point(
			data.canvas.panX + e.offsetX / data.canvas.scaleFactor,
			data.canvas.panY + e.offsetY / data.canvas.scaleFactor
		);

		this.anchor = getSnappingPoint(data.floorplan, p);

		const wall1 = new Wall(this.anchor.x, this.anchor.y, this.anchor.x, this.anchor.y, data.wallThickness);
		const floor = new Floorplan();
		floor.addWall(wall1);

		if(floor.rooms[0]) {
			data.canvas.drawRoom(floor.rooms[0]);
		}

		data.canvas.drawSnappingPoint(this.anchor, e);
	}

	handleMouseUp(e, data) {
		const p = new Point(
			data.canvas.panX + e.offsetX / data.canvas.scaleFactor,
			data.canvas.panY + e.offsetY / data.canvas.scaleFactor
		);

		const snap = getSnappingPoint(data.floorplan, p);

		if(this.anchor) {
			const wall1 = new Wall(this.anchor.x, this.anchor.y, snap.x, this.anchor.y, data.wallThickness);
			const wall2 = new Wall(snap.x, this.anchor.y, snap.x, snap.y, data.wallThickness);
			const wall3 = new Wall(snap.x, snap.y, this.anchor.x, snap.y, data.wallThickness);
			const wall4 = new Wall(this.anchor.x, snap.y, this.anchor.x, this.anchor.y, data.wallThickness);

			data.floorplan.addWall(wall1);
			data.floorplan.addWall(wall2);
			data.floorplan.addWall(wall3);
			data.floorplan.addWall(wall4);

			this.anchor = null;
		}

		data.canvas.drawSnappingPoint(snap, e);
	}

	handleMouseMove(e, data) {
		const p = new Point(
			data.canvas.panX + e.offsetX / data.canvas.scaleFactor,
			data.canvas.panY + e.offsetY / data.canvas.scaleFactor
		);

		const snap = getSnappingPoint(data.room, p);

		if(this.anchor) {
			const wall1 = new Wall(this.anchor.x, this.anchor.y, snap.x, this.anchor.y, data.wallThickness);
			const wall2 = new Wall(snap.x, this.anchor.y, snap.x, snap.y, data.wallThickness);
			const wall3 = new Wall(snap.x, snap.y, this.anchor.x, snap.y, data.wallThickness);
			const wall4 = new Wall(this.anchor.x, snap.y, this.anchor.x, this.anchor.y, data.wallThickness);

			const floor = new Floorplan();
			floor.addWall(wall1);
			floor.addWall(wall2);
			floor.addWall(wall3);
			floor.addWall(wall4);

			if(floor.rooms[0]) {
				data.canvas.drawRoom(floor.rooms[0]);
			}
		}

		data.canvas.drawSnappingPoint(snap, e);
	}
}
