import {getResource} from "@/api/RequestHandler";

export const roomTypeStore = {
	state: () => ({
		roomTypes: []
	}),
	mutations: {
		setRoomTypes (state, payload) {
			state.roomTypes = payload
		}
	},
	getters: {
		getRoomTypes(state) {
			if(state.roomTypes.length === 0) {
				getResource('room_type').then(({data, error}) => {
					state.roomTypes = data.sort((a, b) => a.id - b.id)
				})
			}

			return state.roomTypes
		}
	}
}
