import {getResource} from "@/api/RequestHandler";

export const VendorsStore = {
	state: () => ({
		vendors: null
	}),
	mutations: {
		setVendors(state, payload) {
			state.vendors = payload
		}
	},
	getters: {
		getVendors(state) {
			if(!state.vendors) {
				getResource('vendor').then(({data, error}) => {
					state.vendors = data
				})
			}

			return state.vendors
		}
	}
}
