<template>
	<div>
		<div style="text-align: center; margin-bottom: 2rem">
			<h1>Login</h1>
		</div>
		<form v-on:submit="login($event)" class="needs-validation" novalidate>
			<div class="form-group">
				<label for="email">Email</label>
				<input v-model="email" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
			</div>
			<div class="form-group">
				<label for="password">Password</label>
				<input v-model="password" type="password" class="form-control" id="password" placeholder="Password">
				<div class="invalid-feedback">
					Email or password incorrect.
				</div>
			</div>
			<button type="submit" class="btn">Login</button>
			<div style="position:absolute; left: 50%; transform: translate(-50% , -50%); margin-top: 1rem"><a href="/" v-on:click="switchToReset($event)">Reset password</a></div>
		</form>
	</div>
</template>

<script>
import {supabase} from "@/api/supabase.js";

export default {
	name: "login-form",
	data() {
		return {
			email: '',
			password: '',
		};
	},
	methods: {
		async login(event) {
			event.preventDefault()
			const {data, error} = await supabase.auth.signInWithPassword({
				email: this.email,
				password: this.password,
			})
			if(error) {
				const form = event.target

				Array.from(form.elements).forEach((input) => {
					if(input.id === 'password') {
						input.classList.add('is-invalid');
					}
				});
			} else {
				location.reload();
				console.log(data)
			}
		},
		switchToReset(event) {
			this.$router.push('?modal=reset');
			event.preventDefault();
		}
	},
}
</script>

<style scoped>
ion-text h1 {
	text-align: center;
	font-size: 2em;
}

.form-group {
	margin-top: .5em;
}

button {
	margin-top: 1em;
	margin-bottom: 1em;
}

button {
	background-color: rgb(87, 3, 159);
	border-color: rgb(87, 3, 159);
	color: white;
	width: 7rem !important;
}

button:hover {
	background-color: rgb(134, 82, 179);
	border-color: rgb(134, 82, 179);
	color: white;
}
</style>
