<template>
	<div>
		<div class="container-sm" id="content" style="padding: 0 1em; max-width: 1200px; font-weight: 600; font-family: 'Poppins'; font-size: 1.3em">
			<div id="suggestions" v-if="this.floorplan" style="margin-top: 1rem">
				<ScrollContainer v-for="(suggestion, index) in suggestions" :key="index" v-bind:floorplan="floorplan" v-bind:space="suggestion.space" v-bind:furniture="suggestion.furniture" v-bind:vendorId="suggestion.vendor"></ScrollContainer>
			</div>
		</div>
	</div>
</template>

<script>
import {supabase} from "@/api/supabase.js";
import ScrollContainer from "@/components/common/ScrollContainer.vue";
import {Furniture} from "@/components/editor/building-elements/Furniture.js";

export default {
	name: "Home-page",
	components: {
		ScrollContainer,
	},
	data() {
		return {
			isOpen: false,
			login: true,
			canDismiss: false,
			presentingElement: undefined,
			room: undefined,
			suggestions: [],
		};
	},
	computed: {
		floorplan() {
			return this.$store.getters.getFloorplan;
		},
		rooms() {
			return this.$store.getters.getRooms;
		}
	},
	watch: {
		floorplan() {
			this.loadSuggestions();
		}
	},
	methods: {
		editFloorplan() {
			// this.$store.commit('set', this.floorplan);
			// this.$store.commit('setSpace', []);
			this.$router.push({ name: 'designer' });
		},
		async loadSuggestions() {
			for (let i = 0; i < 10; i++) {
				const { data: suggestion, error: suggestionError } = await supabase.functions.invoke('furnish', {
					body: JSON.stringify({ floorplan: { rooms: this.floorplan.rooms } }),
				})

				console.log(suggestion)
				const space = [];
				for (let i = 0; i < suggestion.space.length; i++) {
					space.push(new Furniture(
						suggestion.space[i].position.x,
						suggestion.space[i].position.y,
						suggestion.space[i].rotation,
						suggestion.space[i].src,
						suggestion.space[i].furniture)
					);
				}

				this.suggestions.push({space: space, furniture: suggestion.furniture, vendor: suggestion.vendor});
			}
		}
	},
	async mounted() {
		this.eventListener = event => {
			const {scrollHeight, scrollTop, clientHeight} = event.target;

			if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
				this.loadSuggestions();
			}
		}

		document.getElementById('app-content').addEventListener('scroll', this.eventListener);

		if(this.floorplan && this.suggestions.length === 0) {
			this.loadSuggestions();
		}
	},
	unmounted() {
		document.getElementById('app-content').removeEventListener('scroll', this.eventListener)
	}
}
</script>

<style scoped>

</style>
