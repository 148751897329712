import {deserializeFloorplan, deserializeRoom, serializeFloorplan} from "@/components/common/RoomDeserialize";
import Floorplan from "@/components/editor/building-elements/Floorplan";
import Wall from "@/components/editor/building-elements/Wall";
import {supabase} from "@/api/supabase";
import {getResource, upsert} from "@/api/RequestHandler";

export const floorplan_store = {
	state() {
		return {
			floorPlans: [],
			selected_room: null,
			selected_id: null,
			selected_space: [],
			selected_walls: null,
			rooms: [],
		}
	},
	mutations: {
		upsertFloorplan(state, payload) {
			console.log(serializeFloorplan(payload.floorplan, payload.userUuid))

			const dbObject =
				{
					floorplan: serializeFloorplan(payload.floorplan, payload.userUuid),
					user_id: payload.userUuid,
				}

			if(payload.floorplan.id) {
				dbObject.id = payload.floorplan.id
			}

			upsert('floorplan',
				dbObject)
				.then(({data, error}) => {
					const deserialized = deserializeFloorplan(data[data.length - 1].floorplan, data[data.length - 1].id)
					state.selected_room = serializeFloorplan(deserialized)
					return deserialized
					// state.selected_walls = JSON.stringify(Array.from(state.selected_room.walls.entries()))
				})
			console.log('upserted')
			//
			// const {data, error} = await supabase
			// .from('floorplan')
			// .upsert([
			// 	{ id: this.$store.getters.getFloorPlanId, floorplan: serializeFloorplan(this.floorplan), user_id: this.$store.getters.getUser.id }
			// ]).select();
			//
		},
		set(state, payload) {
			state.selected_room = payload
			// state.selected_walls = JSON.stringify(Array.from(payload.walls.entries()))
		},
		setRoomId(state, payload) {
			state.selected_id = payload
		},
		setSpace(state, payload) {
			state.selected_space = payload
		},
		setFloorPlanId(state, payload) {
			state.selected_floorplan_id = payload
		},
	},
	getters: {
		getRooms(state) {
			console.log('getRooms')
			if(state.rooms.length === 0) {
				getResource('floorplan').then(({data, error}) => {
					if(!data || data.length === 0) {
						const floorplan = new Floorplan();
						floorplan.addWall(new Wall(100, 200, 400, 200, 20))
						floorplan.addWall(new Wall(400, 200, 400, 500, 20))
						floorplan.addWall(new Wall(400, 500, 600, 500, 20))
						floorplan.addWall(new Wall(600, 500, 600, 700, 20))
						floorplan.addWall(new Wall(600, 700, 100, 700, 20))
						floorplan.addWall(new Wall(100, 700, 100, 200, 20))
						state.rooms.push(serializeFloorplan(floorplan))
						return state.rooms
					}

					console.log(data)
					data.forEach((entry) => {
						const deserialized = deserializeFloorplan(entry.floorplan, entry.id)
						state.rooms.push(serializeFloorplan(deserialized))
					})
					return state.rooms
				})
			} else {
				return state.rooms
			}
		},
		getFloorplan(state) {
			if(!state.selected_room) {
				getResource('floorplan').then(({data, error}) => {
					if(!data || data.length === 0) {
						const floorplan = new Floorplan();
						floorplan.addWall(new Wall(100, 200, 400, 200, 20))
						floorplan.addWall(new Wall(400, 200, 400, 500, 20))
						floorplan.addWall(new Wall(400, 500, 600, 500, 20))
						floorplan.addWall(new Wall(600, 500, 600, 700, 20))
						floorplan.addWall(new Wall(600, 700, 100, 700, 20))
						floorplan.addWall(new Wall(100, 700, 100, 200, 20))
						state.selected_room = serializeFloorplan(floorplan)
						state.rooms.push(serializeFloorplan(floorplan))
						return state.selected_room
					}
					const deserialized = deserializeFloorplan(data[data.length - 1].floorplan, data[data.length - 1].id)
					state.selected_room = serializeFloorplan(deserialized)
					return deserialized
				})
			} else {
				return state.selected_room
			}


			// const floorplan = state.selected_room
			// const walls = new Map(JSON.parse(state.selected_walls))
			// const wallMap = new Map()
			//
			// for (let wall of walls.keys()) {
			// 	const wallObj = new Wall(wall.position.ps.x, wall.position.ps.y, wall.position.pe.x, wall.position.pe.y, wall.thickness)
			// 	wallMap.set(wallObj, walls.get(wall))
			// }
			//
			// floorplan.walls = wallMap
			//
			// const rooms = []
			// for (let room of floorplan.rooms) {
			// 	rooms.push(deserializeRoom(room))
			// }
			// floorplan.rooms = rooms
			//
			// const result = new Floorplan();
			// result.walls = floorplan.walls;
			// result.rooms = floorplan.rooms;
			// result.roomCounter = floorplan.roomCount;
			// result.letterCount = floorplan.letterCount;
			//
			// return result;
		},
		getSpace(state) {
			return state.selected_space
		},
		getRoomId(state) {
			return state.selected_id
		},
		getFloorPlanId(state) {
			return state.selected_floorplan_id
		},
	}
}
