import AbstractBuildingElement from "@/components/editor/building-elements/AbstractBuildingElement";
import Segment from "@/components/editor/simple-geometry/Segment";
import Point from "@/components/editor/simple-geometry/Point";
import {addPaddingToLine, addThicknessToLine} from "@/components/editor/simple-geometry/Operations";
import {drawCircle, drawPolygon, drawSegment, fillPolygon} from "@/components/editor/drawing/DrawingHelper";
import Polygon from "polygon";

export default class Window extends AbstractBuildingElement {

	constructor(x1, y1, x2, y2) {
		super();
		this.position = new Segment(
			new Point(x1, y1),
			new Point(x2, y2),
		);
	}

	drawOutline(context, wallThickness) {
		const outline = addThicknessToLine(this.position.ps.x, this.position.ps.y, this.position.pe.x, this.position.pe.y, wallThickness / 2)
		drawPolygon(context, outline);
	}

	drawFilling(context, wallThickness) {
		const shape = addThicknessToLine(this.position.ps.x, this.position.ps.y, this.position.pe.x, this.position.pe.y, wallThickness / 2)
		fillPolygon(context, shape, '#FFFFFF');
		drawSegment(context, this.position);

		if(this.selected) {
			drawSegment(context, this.position, 8, '000000');
			drawCircle(context, this.position.ps, 10, '000000', 8);
			drawCircle(context, this.position.pe, 10, '000000', 8);
		}
	}

	getGeometry(wallThickness) {
		return addThicknessToLine(this.position.ps.x, this.position.ps.y, this.position.pe.x, this.position.pe.y, wallThickness / 2)
	}

	getGeometryAsPolygon(wallThickness) {
		const geometry = this.getGeometry(wallThickness);
		return new Polygon([
			[geometry[0].x, geometry[0].y],
			[geometry[1].x, geometry[1].y],
			[geometry[2].x, geometry[2].y],
			[geometry[3].x, geometry[3].y]
		]);
	}

	getPath() {
		return this.position;
	}
}
