import { getAngleBetweenVectors } from "@/components/editor/simple-geometry/Operations";
import Line from "@/components/editor/simple-geometry/Line";
import Vector from "@/components/editor/simple-geometry/Vector";
import DirectedSegment from "@/components/editor/simple-geometry/DirectedSegment";
import Segment from "@/components/editor/simple-geometry/Segment";
import Wall from "@/components/editor/building-elements/Wall";
import Room from "@/components/editor/building-elements/Room";

export default class Floorplan {

	constructor() {
		this.id = null;
		this.walls = new Map();
		this.rooms = [];
		this.letterCount = 'a';
		this.roomCount = 1;
	}
	addWall(newWall) {
		const wallArray = Array.from(this.walls.keys());
		for (let i = 0; i < wallArray.length; i++) {
			// if(wallArray[i].position.containsSegment(newWall.position)) {
			// 	return;
			// }
			if(wallArray[i].position.overlaps(newWall.position)) {
				let psNew = null;
				let peNew = null;

				if(wallArray[i].position.containsPoint(newWall.position.ps)) {
					psNew = newWall.position.pe;
				} else if(wallArray[i].position.containsPoint(newWall.position.pe)) {
					psNew = newWall.position.ps;
				}

				if(newWall.position.containsPoint(wallArray[i].position.ps)) {
					peNew = wallArray[i].position.ps;
				} else if(newWall.position.containsPoint(wallArray[i].position.pe)) {
					peNew = wallArray[i].position.pe;
				}

				return this.addWall(new Wall(psNew.x, psNew.y, peNew.x, peNew.y, newWall.thickness));
			}
		}

		let newWallIntersections = [];

		Array.from(this.walls.keys()).forEach(existingWall => {
			if(newWall.position.crosses(existingWall.position) && existingWall.position.crosses(newWall.position)) {
				newWallIntersections.push(existingWall);
				this.insertCrossingWallInSegmentDirection(existingWall, newWall);
			}
		});

		if(newWallIntersections.length > 1) {
			const tempIntersections = []
			newWallIntersections = newWallIntersections.sort((a, b) => {
				return a.position.getCrossingPointWith(newWall.position).distanceTo(newWall.position.ps) > b.position.getCrossingPointWith(newWall.position).distanceTo(newWall.position.ps) ? 1 : -1
			});

			let intersectionCount = 0;
			newWallIntersections.forEach((wall, index) => {
				if(index !== 0) {
					if(wall.position.getCrossingPointWith(newWall.position).distanceTo(newWall.position.ps) === newWallIntersections[index-1].position.getCrossingPointWith(newWall.position).distanceTo(newWall.position.ps)) {
						this.insertInAngleOrder(newWall, wall, tempIntersections[tempIntersections.length-1]);
					} else {
						tempIntersections.push([wall]);
						intersectionCount++;
					}
				} else {
					tempIntersections.push([wall]);
					intersectionCount++;
				}
			});

			newWallIntersections = tempIntersections;
		} else {
			newWallIntersections = newWallIntersections.map(wall => [wall]);
		}

		newWall.letter = this.letterCount;
		this.letterCount = String.fromCharCode(this.letterCount.charCodeAt(0) + 1);

		this.walls.set(newWall, newWallIntersections);

		if(newWallIntersections.length >= 1) {
			this.getAllCycles(newWall);
		}

		return newWall;
	}

	insertCrossingWallInSegmentDirection(existingWall, newWall) {
		const otherCrossingWalls = this.walls.get(existingWall);

		if(otherCrossingWalls.length === 0) {
			otherCrossingWalls.push([newWall]);
		} else {
			const intersectionDistanceFromOrigin = existingWall.position.ps.distanceTo(existingWall.position.getCrossingPointWith(newWall.position));

			let inserted = false;
			otherCrossingWalls.forEach((wall, index) => {
				if(!inserted) {
					const otherIntersectionDistanceFromOrigin = existingWall.position.ps.distanceTo(existingWall.position.getCrossingPointWith(wall[0].position));

					if(otherIntersectionDistanceFromOrigin > intersectionDistanceFromOrigin) {
						otherCrossingWalls.splice(index, 0, [newWall]);
						inserted = true;
					}

					if(otherIntersectionDistanceFromOrigin === intersectionDistanceFromOrigin) {
						this.insertInAngleOrder(existingWall, newWall, otherCrossingWalls[index]);
						inserted = true;
					}
				}
			});

			if(!inserted) {
				otherCrossingWalls.push([newWall]);
			}
		}
	}

	getAdjustedAngleBetweenSegments(segment1, segment2) {
		const angle = this.getAngleBetweenSegments(segment1, segment2) % Math.PI;
		return angle === 0 ? Math.PI : angle;
	}

	insertInAngleOrder(referenceWall, newWall, otherWalls) {
		let inserted = false;

		otherWalls.forEach((wall, index) => {
			if(!inserted && (this.getAdjustedAngleBetweenSegments(referenceWall.position, wall.position) > this.getAdjustedAngleBetweenSegments(referenceWall.position, newWall.position))) {
				otherWalls.splice(index, 0, newWall);
				inserted = true;
			}
		});

		if(!inserted) {
			otherWalls.push(newWall);
		}
	}

	getAllCycles(newWall) {
		const neighbors = this.getInitialCrossingWallsInOrder(newWall);
		const cycles = [];

		neighbors[0].forEach(neighbor => {
			const cycle = this.getCycle(newWall, neighbor, !this.segmentDirectionRelativeLeftOfOther(newWall.position, neighbor.position));
			if(cycle !== null) {
				this.rooms.push(new Room(cycle, this.walls, 'Room ' + (this.rooms.length + 1)));
				this.deleteOverlappingRooms(cycle);
				cycles.push(cycle);
			}
		});

		neighbors[1].forEach(neighbor => {
			const cycle = this.getCycle(newWall, neighbor, this.segmentDirectionRelativeLeftOfOther(newWall.position, neighbor.position));
			if(cycle !== null) {
				this.rooms.push(new Room(cycle, this.walls, 'Room ' + (this.rooms.length + 1)));
				this.deleteOverlappingRooms(cycle);
				cycles.push(cycle);
			}
		});

		return cycles;
	}

	getCycle(wall, previousWall, thisReversed = false) {
		const crossingPoint = wall.position.getCrossingPointWith(previousWall.position);
		const originalAngle = this.getAngleBetweenSegments(wall.position, previousWall.position);

		if(crossingPoint === null) {
			return null;
		}

		let angleSum = 0;
		const walls = [];
		let found = false;

		let currentWall = wall;
		let referenceWall = previousWall;
		let lastReversed = !thisReversed ^ this.segmentDirectionRelativeLeftOfOther(wall.position, previousWall.position);

		while (!found) {
			walls.push(currentWall);

			const temp = currentWall;
			const temp2 = referenceWall;
			const temp3 = lastReversed;
			const result = this.getNextCrossingWallInOrder(currentWall, referenceWall, lastReversed);

			currentWall = result[0];
			referenceWall = temp;
			angleSum += result[2];
			lastReversed = result[1];

			const neighbors = this.walls.get(referenceWall);
			const lastWall = this.walls.get(wall).filter(w => w.includes(temp2))[0];
			let indexLast = neighbors.indexOf(lastWall);
			const nextWall = this.walls.get(wall).filter(w => w.includes(currentWall))[0];
			let indexNext = neighbors.indexOf(nextWall);
			let offset = 1;

			if(temp3) {
				const temp = indexNext;
				indexNext = indexLast;
				indexLast = temp;
				offset = 0;
			}
			const slice = neighbors.slice(indexLast+offset, indexNext+offset);

			if(wall === referenceWall){
				slice.forEach(s => {
					if(s.filter(w => w.position.equals(previousWall.position)).length > 0) {
						found = true;
					}
				});
			}

			if(currentWall.position.getCrossingPointWith(referenceWall.position).equals(crossingPoint)) {
				found = true;
			}
		}

		//TODO: ugly
		if(angleSum >= ((walls.length - 2) * Math.PI) * 0.99 && angleSum <= ((walls.length - 2) * Math.PI) * 1.01) {
			return walls;
		} else {
			return null;
		}
	}

	getAngleBetweenSegments(wall1, wall2) {
		const v1 = Vector.createFromDirectedSegment(new DirectedSegment(wall1.ps, wall1.pe));
		const v2 = Vector.createFromDirectedSegment(new DirectedSegment(wall2.ps, wall2.pe));

		return getAngleBetweenVectors(v1, v2);
	}

	getAlongLeft(wall, referenceWall, intersections, thisReversed) {
		let nextWall, nextReversed;

		for (let i = 0; i < intersections.length; i++) {
			if(!nextWall) {
				if((!thisReversed && this.segmentHasPartLeftOfOther(intersections[i].position, wall.position)) ||
					(thisReversed && this.segmentHasPartRightOfOther(intersections[i].position, wall.position)) ||
					(!this.segmentHasPartLeftOfOther(intersections[i].position, wall.position) && !this.segmentHasPartRightOfOther(intersections[i].position, wall.position))) {
					nextWall = intersections[i];

					nextReversed = !thisReversed ^ this.segmentDirectionRelativeLeftOfOther(nextWall.position, wall.position);
				}
			}
		}

		return [nextWall, nextReversed];
	}

	getAlongRight(wall, referenceWall, intersections, thisReversed) {
		let nextWall, nextReversed;

		for (let i = 0; i < intersections.length; i++) {
			if(!nextWall) {
				if((thisReversed && this.segmentHasPartLeftOfOther(intersections[i].position, wall.position)) ||
					(!thisReversed && this.segmentHasPartRightOfOther(intersections[i].position, wall.position)) ||
					(!this.segmentHasPartLeftOfOther(intersections[i].position, wall.position) && !this.segmentHasPartRightOfOther(intersections[i].position, wall.position))) {
					nextWall = intersections[i];

					nextReversed = thisReversed ^ this.segmentDirectionRelativeLeftOfOther(nextWall.position, wall.position);
				}
			}
		}

		return [nextWall, nextReversed];
	}

	getNextCrossingWallInOrder(wall, previousWall, reversed) {
		let nextWall = null;
		let step = 1;
		let nextReversed = reversed;
		let thisReversed = reversed;
		let angleBetweenWalls = 0;

		if(reversed === null) {
			thisReversed = !this.segmentDirectionRelativeLeftOfOther(wall.position, previousWall.position);
		}

		if(thisReversed) {
			step *= -1;
		}

		const referenceWall = this.walls.get(wall).filter(w => w.includes(previousWall))[0];
		const referenceIndex = this.walls.get(wall).indexOf(referenceWall);
		let count = referenceIndex + step;

		while (count >= 0 && count < this.walls.get(wall).length && !nextWall) {
			const next = this.getAlongLeft(wall, previousWall, this.walls.get(wall)[count], thisReversed);

			if(next[0]) {
				nextWall = next[0];
				nextReversed = next[1];
			}
			count += step;
		}

		step *= -1;
		count += step;

		while (count >= 0 && count < this.walls.get(wall).length && !nextWall) {
			const next = this.getAlongRight(wall, previousWall, this.walls.get(wall)[count], thisReversed);

			if(next[0]) {
				nextWall = next[0];
				nextReversed = next[1];
			}
			count += step;
		}

		step *= -1;
		count += step;

		while (count >= 0 && count < this.walls.get(wall).length && !nextWall) {
			const next = this.getAlongLeft(wall, previousWall, this.walls.get(wall)[count], thisReversed);

			if(next[0]) {
				nextWall = next[0];
				nextReversed = next[1];
			}
			count += step;
		}

		const tempSegment1 = !reversed ? new Segment(wall.position.pe, wall.position.ps) : wall.position;
		const tempSegment2 = nextReversed ? new Segment(nextWall.position.pe, nextWall.position.ps) : nextWall.position;
		angleBetweenWalls += this.getAngleBetweenSegments(tempSegment1, tempSegment2);

		return [nextWall, nextReversed, angleBetweenWalls];
	}

	getInitialCrossingWallsInOrder(wall) {
		const crossingWalls = this.walls.get(wall);
		const orderedCrossingWalls = [[], []];

		for (let i = 0; i < crossingWalls.length; i++) {
			crossingWalls[i].forEach(otherWall => {
				if(this.segmentHasPartLeftOfOther(otherWall.position, wall.position)) {
					orderedCrossingWalls[0].push(otherWall);
				}

				if(!this.segmentHasPartLeftOfOther(otherWall.position, wall.position) &&
					!this.segmentHasPartRightOfOther(otherWall.position, wall.position) &&
					!otherWall.position.ps.equals(wall.position.ps) &&
					!otherWall.position.pe.equals(wall.position.ps)) {
					orderedCrossingWalls[0].push(otherWall);
				}
			});
		}

		for (let i = crossingWalls.length - 1; i >= 0; i--) {
			crossingWalls[i].forEach(otherWall => {
				if(this.segmentHasPartRightOfOther(otherWall.position, wall.position)) {
					orderedCrossingWalls[1].push(otherWall);
				}

				if(!this.segmentHasPartLeftOfOther(otherWall.position, wall.position) &&
					!this.segmentHasPartRightOfOther(otherWall.position, wall.position) &&
					!otherWall.position.ps.equals(wall.position.pe) &&
					!otherWall.position.pe.equals(wall.position.pe)) {
					orderedCrossingWalls[1].push(otherWall);
				}

			});
		}

		return orderedCrossingWalls;
	}

	segmentDirectionRelativeLeftOfOther(targetSegment, referenceSegment) {
		const targetLine = new Line(targetSegment.ps, targetSegment.pe);
		const referenceLine = new Line(referenceSegment.ps, referenceSegment.pe);

		const targetAngle = targetLine.getAngle();
		const referenceAngle = referenceLine.getAngle();

		if((targetAngle > referenceAngle && targetAngle <= referenceAngle + Math.PI) || targetAngle <= referenceAngle - Math.PI) {
			return false;
		} else {
			return true;
		}
	}

	segmentHasPartLeftOfOther(targetSegment, referenceSegment) {
		const p1Left = ((referenceSegment.pe.x - referenceSegment.ps.x) *
			(targetSegment.ps.y - referenceSegment.ps.y) -
			(referenceSegment.pe.y - referenceSegment.ps.y) *
			(targetSegment.ps.x - referenceSegment.ps.x)) < 0;

		const p2Left = ((referenceSegment.pe.x - referenceSegment.ps.x) *
			(targetSegment.pe.y - referenceSegment.ps.y) -
			(referenceSegment.pe.y - referenceSegment.ps.y) *
			(targetSegment.pe.x - referenceSegment.ps.x)) < 0;

		return p1Left || p2Left;
	}


	segmentHasPartRightOfOther(targetSegment, referenceSegment) {
		const p1Left = ((referenceSegment.pe.x - referenceSegment.ps.x) *
			(targetSegment.ps.y - referenceSegment.ps.y) -
			(referenceSegment.pe.y - referenceSegment.ps.y) *
			(targetSegment.ps.x - referenceSegment.ps.x)) > 0;

		const p2Left = ((referenceSegment.pe.x - referenceSegment.ps.x) *
			(targetSegment.pe.y - referenceSegment.ps.y) -
			(referenceSegment.pe.y - referenceSegment.ps.y) *
			(targetSegment.pe.x - referenceSegment.ps.x)) > 0;

		return p1Left || p2Left;
	}

	getWalls() {
		return this.rooms.map(room => room.walls).flat();
	}

	draw(floorContext, textContext, scaleFactor) {
		this.rooms.forEach(room => room.drawFilling(floorContext, textContext, scaleFactor));
	}

	deleteOverlappingRooms(room) {
		this.rooms.forEach(otherRoom => {
			if(this.roomOverlaps(room, otherRoom.walls)) {
				this.rooms.splice(this.rooms.indexOf(otherRoom), 1);
			}
		});
	}

	roomOverlaps(room, otherRoom) {
		if(JSON.stringify(room) !== JSON.stringify(otherRoom)) {
			const sharedWalls = this.getSharedWallsInOrder(room, otherRoom);

			if(sharedWalls.length === 2) {
				const wall1Intersections = this.walls.get(sharedWalls[0]);

				const wall1wall2Intersection = wall1Intersections.find(intersections => intersections.includes(sharedWalls[1]));
				const indexWall2Intersection = wall1Intersections.indexOf(wall1wall2Intersection);

				const roomPreviousWall = room.indexOf(sharedWalls[0]) === 0 ? room[room.length - 1] : room[room.indexOf(sharedWalls[0]) - 1];
				const wall1RoomPreviousWallIntersection = wall1Intersections.find(intersections => intersections.includes(roomPreviousWall));
				const indexWall1RoomPreviousWallIntersection = wall1Intersections.indexOf(wall1RoomPreviousWallIntersection);

				const otherRoomPreviousWall = otherRoom.indexOf(sharedWalls[0]) === 0 ? otherRoom[otherRoom.length - 1] : otherRoom[otherRoom.indexOf(sharedWalls[0]) - 1];
				const wall1OtherRoomPreviousWallIntersection = wall1Intersections.find(intersections => intersections.includes(otherRoomPreviousWall));
				const indexWall1OtherRoomPreviousWallIntersection = wall1Intersections.indexOf(wall1OtherRoomPreviousWallIntersection);

				return !(indexWall1RoomPreviousWallIntersection < indexWall2Intersection < indexWall1OtherRoomPreviousWallIntersection ||
					indexWall1OtherRoomPreviousWallIntersection < indexWall2Intersection < indexWall1RoomPreviousWallIntersection);
			}

			return !(sharedWalls.length <= 1 || (sharedWalls[0] === sharedWalls[sharedWalls.length - 1]));
		}
	}

	getSharedWallsInOrder(str1, str2) {
		const s1 = [...str1, ...str1];
		const s2 = [...str2, ...str2];
		const arr = Array(s2.length + 1).fill(null).map(() => {
			return Array(s1.length + 1).fill(null);
		});
		for (let j = 0; j <= s1.length; j += 1) {
			arr[0][j] = 0;
		}
		for (let i = 0; i <= s2.length; i += 1) {
			arr[i][0] = 0;
		}
		let len = 0;
		let col = 0;
		let row = 0;
		for (let i = 1; i <= s2.length; i += 1) {
			for (let j = 1; j <= s1.length; j += 1) {
				if(s1[j - 1] === s2[i - 1]) {
					arr[i][j] = arr[i - 1][j - 1] + 1;
				} else {
					arr[i][j] = 0;
				}
				if(arr[i][j] > len) {
					len = arr[i][j];
					col = j;
					row = i;
				}
			}
		}
		if(len === 0) {
			return '';
		}
		let res = [];
		while (arr[row][col] > 0) {
			res = [s1[col - 1], ...res];
			row -= 1;
			col -= 1;
		}
		return res;
	}
}
