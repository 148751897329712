import InputHandler from "@/components/editor/features/InputHandler";

export default class PanHandler extends InputHandler {

	handleMouseDown(e, data) {
		this.anchor = { x: e.offsetX, y: e.offsetY };
	}

	handleMouseUp() {
		this.anchor = null;
	}

	handleMouseMove(e, data) {
		if(this.anchor) {
			const mousex = e.offsetX;
			const mousey = e.offsetY;

			data.canvas.contexts.forEach(context => {
				context.translate((mousex - this.anchor.x) / data.canvas.scaleFactor, (mousey - this.anchor.y) / data.canvas.scaleFactor)
			})

			data.canvas.panX -= (mousex - this.anchor.x) / data.canvas.scaleFactor;
			data.canvas.panY -= (mousey - this.anchor.y) / data.canvas.scaleFactor;

			console.log("panX: " + data.canvas.panX)
			console.log("panY: " + data.canvas.panY)


			this.anchor.x = e.offsetX;
			this.anchor.y = e.offsetY;
		}
	}
}
