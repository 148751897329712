import Point from "@/components/editor/simple-geometry/Point";

export default class ZoomPanHandler {
	handleMouseDown(e, data, canvasParent) {
		this.anchor = this.getCenterBetween(e, canvasParent);
		this.previous = e;
		this.scale = 1;
	}

	handleMouseUp() {
		this.anchor = null;
	}

	handleMouseMove(e, data, canvasParent) {
		if(this.anchor) {
			const center = this.getCenterBetween(e, canvasParent);
			data.canvas.zoomMobile(this.anchor, this.getZoomFactor(e));
			this.previous = e;

			data.canvas.contexts.forEach(context => {
				context.translate((center.x - this.anchor.x) / data.canvas.scaleFactor, (center.y - this.anchor.y) / data.canvas.scaleFactor)
			})

			data.canvas.panX -= (center.x - this.anchor.x) / data.canvas.scaleFactor;
			data.canvas.panY -= (center.y - this.anchor.y) / data.canvas.scaleFactor;

			this.anchor = this.getCenterBetween(e, canvasParent);
		}
	}

	getCenterBetween(event, canvasParent) {
		let parent = canvasParent;
		let rect = parent.getBoundingClientRect();

		let offsetXfirst = event[0].offsetX - rect.left;
		let offsetYfirst = event[0].offsetY - rect.top;
		let offsetXsecond = event[1].offsetX - rect.left;
		let offsetYsecond = event[1].offsetY - rect.top;

		const x = offsetXfirst + (offsetXsecond - offsetXfirst) / 2;
		const y = offsetYfirst + (offsetYsecond - offsetYfirst) / 2;

		return { x: x, y: y };
	}

	getZoomFactor(event) {
		const deltaXPrevious = this.previous[1].offsetX - this.previous[0].offsetX;
		const deltaYPrevious = this.previous[1].offsetY - this.previous[0].offsetY;
		const deltaPrevious = Math.sqrt(deltaXPrevious * deltaXPrevious + deltaYPrevious * deltaYPrevious);

		const deltaX = event[1].offsetX - event[0].offsetX;
		const deltaY = event[1].offsetY - event[0].offsetY;
		const delta = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

		return delta / deltaPrevious;
	}
}
