<template>
	<div>
		<div style="text-align: center; margin-bottom: 2rem">
			<h1>New Password</h1>
		</div>
		<form v-on:submit="sendPasswordResetRequest($event)" class="needs-validation" novalidate>
			<div class="form-group">
				<label for="email">Password</label>
				<input v-model="password" type="password" class="form-control" id="password" aria-describedby="emailHelp" placeholder="Enter new password" required>
				<div class="invalid-feedback">
					Min. 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number
				</div>
			</div>
			<button type="submit" class="btn">Confirm</button>
		</form>
	</div>
</template>

<script>
import {supabase} from "@/api/supabase.js";
import {getUser} from "@/components/common/auth/getUser.js";

export default {
	name: "new-password-form",
	data() {
		return {
			password: '',
		};
	},
	methods: {
		async sendPasswordResetRequest(event) {
			event.preventDefault()

			const form = event.target
			let formValid = true
			Array.from(form.elements).forEach((input) => {
				const passwordRequirements = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
				if(input.id === 'password') {
					if(!passwordRequirements.test(input.value)) {
						input.classList.add('is-invalid');
						input.validity.valid = false;
						formValid = false;
					} else {
						input.classList.remove('is-invalid');
					}
				}
			});
			if(!formValid) {
				return;
			}

			const { data, error } = await supabase.auth.updateUser({
				password: this.password,
			})

			if(error) {
				// setLMsg(error.message)
			} else {
				this.$router.push('?modal=password-updated');
			}
		}
	}
}
</script>

<style scoped>
ion-text h1 {
	text-align: center;
	font-size: 2em;
}

.form-group {
	margin-top: .5em;
}

button {
	margin-top: 1em;
	margin-bottom: 1em;
}

button {
	background-color: rgb(87, 3, 159);
	border-color: rgb(87, 3, 159);
	color: white;
}

button:hover {
	background-color: rgb(134, 82, 179);
	border-color: rgb(134, 82, 179);
	color: white;
}
</style>
