<template>
	<div>
		<h2 style="font-weight: 600; font-family: 'Poppins'; font-size: 1.3em; margin-bottom: 1.5rem">
			Congratulations! You are ready for your new personal Interior Designer.
		</h2>
		<div>
			In case you want to change any of the details you provided, you can do that
			in the profile settings. You can find them by clicking your profile icon at the
			top right of the page.
		</div>
		<br>
		<button style="background-color: #57039F; border-color: #57039F" class="btn btn-primary" v-on:click="$router.push('/app')">Get suggestions
		</button>
	</div>
</template>


<script>
export default {
	name: "ReadyMessage",
}
</script>


<style scoped>

</style>
