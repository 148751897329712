import Point from "@/components/editor/simple-geometry/Point";
import Vector from "@/components/editor/simple-geometry/Vector";
import DirectedSegment from "@/components/editor/simple-geometry/DirectedSegment";

const wallSnapDistance = 50;
const cornerSnapDistance = 80;

export function getSnappingPoint(floorplan, coordinate) {
	return getSnappingPointAndSnappedWall(floorplan, coordinate).point;
}

export function getSnappingPointAndWallAndNormal(floorplan, coordinate, snapToOutside) {
	const snap = getSnappingPointAndSnappedWall(floorplan, coordinate);
	let normal = null;

	if(snap.wall) {
		const wallVector = Vector.createFromDirectedSegment(new DirectedSegment(snap.wall.position.ps, snap.wall.position.pe));
		normal = wallVector.getNormalVector().getUnitVector();

		if(isLeft(snap.wall.position, coordinate)) {
			normal.x = -normal.x;
			normal.y = -normal.y;
		}

		if(snapToOutside) {
			snap.point = snap.point.getOffsetPoint(normal.multiply(snap.wall.thickness / 2));
		}
	}

	return { point: snap.point, wall: snap.wall, normal: normal };
}

function roundCoordinate(coordinate) {
	return new Point(Math.round(coordinate.x), Math.round(coordinate.y));
}

function isLeft(directedSegment, point) {
	return ((directedSegment.pe.x - directedSegment.ps.x) * (point.y - directedSegment.ps.y) -
		(directedSegment.pe.y - directedSegment.ps.y) * (point.x - directedSegment.ps.x)) > 0;
}

export function getSnappingPointAndSnappedWall(floorplan, coordinate) {
	let closestPoint = coordinate
	let minimumDistance = Infinity;
	let closestWall = null;

	floorplan.getWalls().forEach(wall => {
		const distanceToPoint = getSegmentPointDistance(coordinate, wall.getPath()) - wallSnapDistance;

		if(distanceToPoint < minimumDistance && distanceToPoint < wallSnapDistance) {
			minimumDistance = distanceToPoint;
			closestPoint = closestPointBetween2D(coordinate, wall.getPath());
			closestWall = wall;
		}
	});

	floorplan.getWalls().forEach(wall => {
		const distanceToFirstEndPoint = coordinate.distanceTo(wall.position.ps) - cornerSnapDistance;
		const distanceToSecondEndPoint = coordinate.distanceTo(wall.position.pe) - cornerSnapDistance;
		const distanceToPoint = Math.min(distanceToFirstEndPoint, distanceToSecondEndPoint);
		const closerEndpoint = distanceToFirstEndPoint < distanceToSecondEndPoint ? wall.position.ps : wall.position.pe;

		if(distanceToPoint < minimumDistance && distanceToPoint < cornerSnapDistance) {
			minimumDistance = distanceToPoint;
			closestPoint = closerEndpoint;
			closestWall = wall;
		}
	});

	return { point: closestPoint, wall: closestWall };
}

const _zero2D = new Point(0, 0);

function closestPointBetween2D(point, segment) {
	var P = point;
	var A = segment.ps;
	var B = segment.pe;

	const v = new Point(B.x - A.x, B.y - A.y);
	const u = new Point(A.x - P.x, A.y - P.y);
	const vu = v.x * u.x + v.y * u.y;
	const vv = v.x ** 2 + v.y ** 2;
	const t = -vu / vv;
	if (t >= 0 && t <= 1) return _vectorToSegment2D(t, _zero2D, A, B);
	const g0 = _sqDiag2D(_vectorToSegment2D(0, P, A, B));
	const g1 = _sqDiag2D(_vectorToSegment2D(1, P, A, B));
	return g0 <= g1 ? A : B;
}

function _vectorToSegment2D(t, P, A, B) {
	return new Point(
		(1 - t) * A.x + t * B.x - P.x,
		(1 - t) * A.y + t * B.y - P.y,
	);
}

function _sqDiag2D(P) { return P.x ** 2 + P.y ** 2 }


function getSegmentPointDistance(point, segment) {
	var x = point.x;
	var y = point.y;
	var x1 = segment.ps.x;
	var y1 = segment.ps.y;
	var x2 = segment.pe.x;
	var y2 = segment.pe.y;


	var A = x - x1;
	var B = y - y1;
	var C = x2 - x1;
	var D = y2 - y1;

	var dot = A * C + B * D;
	var len_sq = C * C + D * D;
	var param = -1;
	if (len_sq !== 0) //in case of 0 length line
		param = dot / len_sq;

	var xx, yy;

	if (param < 0) {
		xx = x1;
		yy = y1;
	}
	else if (param > 1) {
		xx = x2;
		yy = y2;
	}
	else {
		xx = x1 + param * C;
		yy = y1 + param * D;
	}

	var dx = x - xx;
	var dy = y - yy;
	return Math.sqrt(dx * dx + dy * dy);
}
