import { createVNode, getCurrentInstance } from 'vue'

export const createComponent = (component, props) => {
	try {
		if (component?.constructor === String) {
			const instance = getCurrentInstance()
			return createVNode(instance.appContext.components[component], props)
		} else {
			return createVNode(component, props)
		}
	} catch (err) {
		console.error('Unable to create VNode', component, props, err)
	}
}

export default {
	install(APP) {
		APP.$createComponent = createComponent
		APP.config.globalProperties.$createComponent = createComponent
	}
}
