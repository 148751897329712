import AbstractBuildingElement from "@/components/editor/building-elements/AbstractBuildingElement";
import Segment from "@/components/editor/simple-geometry/Segment";
import Point from "@/components/editor/simple-geometry/Point";
import Polygon from "polygon";
import {drawClosed, drawRooms} from "@/components/editor/drawing/DrawingHelper";
import {eventBus} from "@/main.js";

export default class Room extends AbstractBuildingElement {

	constructor(walls, allWalls, roomName) {
		super();

		this.walls = walls;
		if(allWalls) {
			this.shape = this.createRoomShape(walls, allWalls);
		}
		this.selected = false;
		this.roomName = roomName;

		const image = new Image();
		image.src = '/floors/floor_1.jpg';
		this.floorPatternSrc = '/floors/floor_1.jpg';
		this.floorPattern = image;

		this.scaleFactor = 1.00;
	}

	wallsToCoordinateArray(walls, allWalls) {
		const coordinates = [];

		const lastIntersection = allWalls.get(walls[0]).find((intersection) => intersection.includes(walls[walls.length - 1]));
		let lastIndex = allWalls.get(walls[0]).indexOf(lastIntersection);
		const currentIntersection = allWalls.get(walls[0]).find((intersection) => intersection.includes(walls[1]));
		let currentIndex = allWalls.get(walls[0]).indexOf(currentIntersection);

		let thisReversed = lastIndex < currentIndex;

		for (let i = 0; i < walls.length-1; i++) {
			const line1Geometry = walls[i].getGeometry();
			const line2Geometry = walls[i+1].getGeometry();

			const line2LastIntersection = allWalls.get(walls[i + 1]).find((intersection) => intersection.includes(walls[i]));
			let line2LastIndex = allWalls.get(walls[i + 1]).indexOf(line2LastIntersection);
			const line2CurrentIntersection = allWalls.get(walls[i + 1]).find((intersection) => intersection.includes(walls[i < walls.length - 2 ? (i + 2) : 0]));
			let line2CurrentIndex = allWalls.get(walls[i + 1]).indexOf(line2CurrentIntersection);

			let nextReversed = line2LastIndex < line2CurrentIndex;

			const line1InnerLine = new Segment(new Point(line1Geometry[thisReversed ? 0 : 2].x, line1Geometry[thisReversed ? 0 : 2].y),
				new Point(line1Geometry[thisReversed ? 1 : 3].x, line1Geometry[thisReversed ? 1 : 3].y));
			const line2InnerLine = new Segment(new Point(line2Geometry[nextReversed ? 0 : 2].x, line2Geometry[nextReversed ? 0 : 2].y),
				new Point(line2Geometry[nextReversed ? 1 : 3].x, line2Geometry[nextReversed ? 1 : 3].y));

			thisReversed = nextReversed;

			const crossingPoint = line1InnerLine.getCrossingPointWith(line2InnerLine);

			if(crossingPoint) {
				coordinates.push(crossingPoint);
			}
		}

		const line1Geometry = walls[walls.length-1].getGeometry();
		const line2Geometry = walls[0].getGeometry();

		const line2LastIntersection = allWalls.get(walls[0]).find((intersection) => intersection.includes(walls[walls.length - 1]));
		let line2LastIndex = allWalls.get(walls[0]).indexOf(line2LastIntersection);
		const line2CurrentIntersection = allWalls.get(walls[0]).find((intersection) => intersection.includes(walls[1]));
		let line2CurrentIndex = allWalls.get(walls[0]).indexOf(line2CurrentIntersection);

		let nextReversed = line2LastIndex < line2CurrentIndex;

		const line1InnerLine = new Segment(new Point(line1Geometry[thisReversed ? 0 : 2].x, line1Geometry[thisReversed ? 0 : 2].y),
			new Point(line1Geometry[thisReversed ? 1 : 3].x, line1Geometry[thisReversed ? 1 : 3].y));
		const line2InnerLine = new Segment(new Point(line2Geometry[nextReversed ? 0 : 2].x, line2Geometry[nextReversed ? 0 : 2].y),
			new Point(line2Geometry[nextReversed ? 1 : 3].x, line2Geometry[nextReversed ? 1 : 3].y));

		const crossingPoint = line1InnerLine.getCrossingPointWith(line2InnerLine);

		if(crossingPoint) {
			coordinates.push(crossingPoint);
		}

		return coordinates;
	}

	createRoomShape(walls, allWalls) {
		const coordinates = this.wallsToCoordinateArray(walls, allWalls);
		let roomPoly = new Polygon(coordinates);

		return roomPoly;
	}


	drawOutline() {
		throw new Error("Method 'drawOutline()' must be implemented.");
	}

	drawFilling(floorContext, textContext, scaleFactor) {
		drawRooms(floorContext, textContext, [this.shape], this.floorPattern, false, scaleFactor, this.selected);
	}

	setFloorPattern(floorPatternSrc) {
		const image = new Image();
		image.src = floorPatternSrc;
		image.decode().then(() => {
			this.floorPattern = image;
			this.floorPatternSrc = floorPatternSrc;
			eventBus.emit('save');
			eventBus.emit('redraw');
		});
	}

	getPath() {
		return this.shape;
	}
}
