<template>
	<div ref="canvasParent" style="width: 100%; height: 100%">
		<floorplan-canvas
			v-bind:class="{ grab: mode === 'pan' }"
			height="500"
			@wheel.prevent="handleScroll($event)"
			@mousedown.passive="handleMouseDown($event)"
			@touchstart.passive="handleMouseDown($event)"
			@mouseup.passive="handleMouseUp($event)"
			@touchend.passive="handleMouseUp($event)"
			@mousemove.passive="handleMouseMove($event)"
			@touchmove.passive="handleMouseMove($event)"
			ref="canvas"
			v-bind:draw-bg="true"
			v-bind:space="space"
			v-bind:floorplan="floorplan">
		</floorplan-canvas>
	</div>
</template>

<script>
import {eventBus, historyHandler} from "@/main";
import FloorplanCanvas from "@/components/editor/drawing/Canvas";
import ZoomPanHandler from "@/components/editor/features/ZoomPanHandler";
import EmptyHandler from "@/components/editor/features/_EmptyHandler.js";

export default {
	name: "viewer-component",
	components: {FloorplanCanvas},
	props: {
		floorplan: null,
	},
	data() {
		return {
			canvas: null,
			wallThickness: 15,
			space: [],
			mode: 'room',
			handler: null,
			zoomPanHandler: null,
			floorPattern: null
		}
	},
	methods: {
		handleMouseDown(e) {
			this.canvas.clear();
			const event = getEventOrigin(e);

			if(event.length === 1) {
				this.handler.handleMouseDown(getEventOrigin(e)[0], this.$data);
			} else {
				this.handler.anchor = null;
				this.zoomPanHandler.handleMouseDown(getEventOrigin(e), this.$data, this.$refs.canvasParent);
			}
			this.canvas.drawAll();
		},
		handleMouseUp(e) {
			this.canvas.clear();
			const event = getEventOrigin(e);
			if(event.length === 1) {
				this.handler.handleMouseUp(getEventOrigin(e)[0], this.$data);
			} else {
				this.handler.anchor = null;
				this.zoomPanHandler.handleMouseUp(getEventOrigin(e), this.$data, this.$refs.canvasParent);
			}
			this.canvas.drawAll();
		},
		handleMouseMove(e) {
			window.scrollTo(0,0)
			this.canvas.clear();
			const event = getEventOrigin(e);
			if(event.length === 1) {
				this.handler.handleMouseMove(getEventOrigin(e)[0], this.$data);
			} else {
				this.handler.anchor = null;
				this.zoomPanHandler.handleMouseMove(getEventOrigin(e), this.$data, this.$refs.canvasParent);
			}
			this.canvas.drawAll();
		},
		handleScroll(e) {
			let parent = this.$refs.canvasParent;
			let rect = parent.getBoundingClientRect();
			let offsetX = e.clientX - rect.left;
			let offsetY = e.clientY - rect.top;

			this.canvas.zoom({x: offsetX, y: offsetY}, e.deltaY < 0 ? 1 : -1)
		},
	},
	mounted() {
		this.canvas = this.$refs.canvas;
		this.handler = new EmptyHandler();
		this.zoomPanHandler = new ZoomPanHandler();
		this.canvas.drawAll();
	}
}

function getEventOrigin(event) {
	if(event.constructor.name === 'TouchEvent') {
		if(event.targetTouches.length === 2) {
			return [
				{ offsetX: event.targetTouches.item(0).clientX, offsetY: event.targetTouches.item(0).clientY - event.target.offsetTop },
				{ offsetX: event.targetTouches.item(1).clientX, offsetY: event.targetTouches.item(1).clientY - event.target.offsetTop }
			]
		}
		else if(event.changedTouches.length > 1 || (event.targetTouches.length > 0 && event.targetTouches.item(0).identifier !== event.changedTouches.item(0).identifier)) {
			return [
				{ offsetX: event.changedTouches.item(0).clientX, offsetY: event.changedTouches.item(0).clientY - event.target.offsetTop },
				{ offsetX: event.targetTouches.item(0).clientX, offsetY: event.targetTouches.item(0).clientY - event.target.offsetTop }
			]
		} else {
			return [{ offsetX: event.changedTouches.item(0).clientX, offsetY: event.changedTouches.item(0).clientY - event.target.offsetTop - event.target.offsetParent.offsetParent.offsetTop }]
		}
	} else {
		return [event];
	}
}
</script>

<style scoped>
.grab {
	cursor: grab;
}

</style>
