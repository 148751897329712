export default class InputHandler {
	constructor() {
		if (this.constructor === InputHandler) {
			throw new Error("Abstract classes can't be instantiated.");
		}
	}

	initialize() {}
	destroy() {}

	// eslint-disable-next-line no-unused-vars
	handleMouseDown() {
		throw new Error("Method 'handleMouseDown()' must be implemented.");
	}

	// eslint-disable-next-line no-unused-vars
	handleMouseUp() {
		throw new Error("Method 'handleMouseUp()' must be implemented.");
	}

	// eslint-disable-next-line no-unused-vars
	handleMouseMove() {
		throw new Error("Method 'handleMouseMove()' must be implemented.");
	}
}
