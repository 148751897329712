import {drawArc, drawCircle, drawPolygon, drawRotatedSvg, drawSvg} from "@/components/editor/drawing/DrawingHelper";
import {Point} from "@mathigon/euclid";
import {SnapDirection as SnapDirections} from "@/components/editor/drawing/SnapDirections.enum";
import {SnapMode} from "@/components/editor/drawing/SnapMode.enum";
import Vector from "@/components/editor/simple-geometry/Vector";
import Polygon from "polygon";
import {polygonToArray} from "@/components/editor/simple-geometry/Operations";
import {eventBus} from "@/main";

export class Furniture {

	constructor(x, y, rotation, src, item) {
		this.position = new Point(x, y);
		this.rotation = rotation;
		this.direction = new Vector(0, -1);
		this.path = null;
		this.selected = false;
		this.item = item;

		if(!item.asset_path) {
			this.img = null;
			return;
		}

		const img = new Image();

		if(item) {
			fetch(src)
				.then(response => response.text())
				.then(text => {
					const parser = new DOMParser();
					let doc = parser.parseFromString(text, 'image/svg+xml');

					// TODO scale the svg without distorting it
					// const originalWidth = parseFloat(doc.querySelector('svg').getAttribute('width').replace('mm', ''));
					// const originalHeight = parseFloat(doc.querySelector('svg').getAttribute('height').replace('mm', ''));
					//
					// const scaleFactorX = item.width / originalWidth;
					// const scaleFactorY = item.length / originalHeight;
					// const scaleFactor = Math.min(scaleFactorX, scaleFactorY);
					//
					// console.log('scaleFactor', scaleFactor)
					//
					// const newWidth = originalWidth * scaleFactor;
					// const newHeight = originalHeight * scaleFactor;
					//
					// const widthDiff = item.width - newWidth;
					// const heightDiff = item.length - newHeight;
					//
					// console.log('widthDiff', widthDiff);
					// console.log('heightDiff', heightDiff);


					let elements = doc.querySelectorAll('rect, circle');
					elements.forEach(element => {
						// element.setAttribute('width', item.width);
						// element.setAttribute('height', item.length);
						element.style.fill = item.color_1;
						element.style.strokeWidth = '.1px';
					});

					// Find the rect element and update its fill color
					let other = Array.from(doc.querySelectorAll('path'));
					other.forEach(element => {
						// element.setAttribute('width', item.width);
						// element.setAttribute('height', item.length);
						element.style.fill = item.color_1;
						element.style.strokeWidth = '.1px';

						console.log(element.style.stroke)
						if(element.style.stroke !== 'rgb(0, 0, 0)') {
							element.style.stroke = 'none';
						}
					});

					let svgElement = doc.querySelector('svg');
					if (svgElement) {
						// svgElement.setAttribute('viewBox', `-10 -10 50 50 `);
						svgElement.setAttribute('width', item.width);
						svgElement.setAttribute('height', item.length);
						svgElement.setAttribute('preserveAspectRatio', 'none');
					}


					// Serialize the updated SVG back into a string
					const serializer = new XMLSerializer();
					const updatedSvgStr = serializer.serializeToString(doc);

					// Create a Blob from the SVG string
					const blob = new Blob([updatedSvgStr], {type: 'image/svg+xml'});

					// Use createObjectURL to convert the Blob into a URL
					const url = URL.createObjectURL(blob);
					img.src = url;
				});
		} else {
			img.src = src;
		}

		img.onload = () => {
			if(item) {
				// img.width = item.width;
				// img.height = item.length;
			} else {
				img.width = img.width * 1.5;
				img.height = img.height * 1.5;
			}

			this.calculatePath();
			eventBus.emit('redraw');
		}

		this.img = img;
		this.snapDirections = [SnapDirections.BOTTOM];
		this.snapMode = SnapMode.REQUIRED;
	}

	drawOutline(context) {
		if(!this.img) {
			return;
		}

		const localSnap = this.getLocalSnapPoint();
		if(this.path) {
			const polygonArray = polygonToArray(this.path)[0].map(coord => { return {x: coord[0], y: coord[1]} });
			// drawPolygon(context, polygonArray, 3);
		}
		drawRotatedSvg(context, this.img, new Point(this.position.x, this.position.y), this.img.width, this.img.height, localSnap, this.rotation);

		if(this.selected) {
			drawCircle(context, new Point(this.position.x, this.position.y), 70, '#00000033', 15);
		}
	}

	drawFilling() {
	}

	detectRotate(click) {
		const distance = click.distanceTo(new Point(this.position.x, this.position.y));
		if(distance > 63 && distance < 77) {
			return true;
		}
		return false;
	}

	getPath() {
		return this.path;
	}

	calculatePath() {
		const a = new Point(this.position.x - this.img.width / 2, this.position.y - this.img.height / 2);
		const b = new Point(this.position.x + this.img.width / 2, this.position.y - this.img.height / 2);
		const c = new Point(this.position.x + this.img.width / 2, this.position.y + this.img.height / 2);
		const d = new Point(this.position.x - this.img.width / 2, this.position.y + this.img.height / 2);

		this.path = new Polygon([a, b, c, d]);
		this.path.rotate(this.rotation);
	}

	getLocalSnapPoint() {
		switch (this.snapDirections[0]) {
		case SnapDirections.BOTTOM:
			return new Point(0, this.img.height / 2);
		}
	}
}
