<template>
	<div>
		<div>
			<h2 style="font-weight: 600; font-family: 'Poppins'; font-size: 1.3em; margin-bottom: 1.5rem">
				How would you best describe your home?
			</h2>
			<div class="form-check" v-for="type in homeTypes" :key="type">
				<input class="form-check-input" :checked="type.selected" v-on:change="selectHomeType(type)" type="radio" :name="type.id" :id="type.id">
				<label class="form-check-label" :for="type.id">
					{{type.name_en}}
				</label>
			</div>
		</div>

		<button style="margin: 1rem  1rem 0 0" class="btn btn-secondary" v-on:click="navigate('/app/welcome/2')">Go Back</button>
		<button :disabled="homeType === null" style="background-color: #57039F; border-color: #57039F; margin-top: 1rem" class="btn btn-primary" v-on:click="navigate()">Continue</button>
	</div>
</template>


<script>
export default {
	name: "HomeType",
	computed: {
		homeTypeInStore() {
			return this.$store.getters.getHomeType;
		},
		homeTypes() {
			return this.$store.getters.getHomeTypes;
		}
	},
	watch: {
		homeTypeInStore: {
			handler(newVal) {
				if(newVal && this.homeTypes) {
					this.selectHomeType(this.homeTypes.find(type => type.id === newVal));
				}
			},
			immediate: true
		}
	},
	methods: {
		selectHomeType(type) {
			this.homeTypes.forEach(homeType => {
				homeType.selected = false;
			})
			type.selected = true;
			this.homeType = type.id;
		},
		navigate(newRoute) {
			console.log(this.homeTypes)
			this.$store.commit('setHomeType', this.homeType);

			if (newRoute) {
				this.$router.push(newRoute);
			} else {
				if (this.homeType === 'single' || this.homeType === 'couple') {
					this.$router.push('/app/welcome/5');
				} else {
					this.$router.push('/app/welcome/4');
				}
			}
		},
	},
}
</script>


<style scoped>

</style>
