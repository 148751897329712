<template>
	<div id="navbar">
		<div class="menu-item" v-on:click="$router.push({name: 'home'})">
			<i class='bx bx-home bx-sm tool-icon'></i>
			<div class="tool-icon-label">
				Home
			</div>
		</div>
		<div class="menu-item" v-on:click="$router.push({name: 'editor-overview'})">
			<i class='bx bx-wrench bx-sm tool-icon'></i>
			<div class="tool-icon-label">
				Editor
			</div>
		</div>
		<div class="menu-item" v-on:click="$router.push({name: 'vendors'})">
			<img class='bx bx-sm tool-icon' src="../../../assets/icons/furniture.svg"/>
			<div class="tool-icon-label">
				Furniture
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Nav-Bar",
}
</script>


<style scoped>
.menu-item:hover {
	cursor: pointer;
}

@media screen and (min-width: 800px) {
	#app-navbar {
		padding-top: .5rem;
	}

	.tool-icon {
		font-size: 2rem !important;
		height: 2rem !important;
		margin: .5rem 2rem;
	}


	.menu-item {
		display: flex;


	}
	.tool-icon-label {
		margin: auto 0;
		font-size: 1.2rem;
		font-family: Poppins;
		min-width: 40%;
	}
}


@media screen and (max-width: 800px) {
	.tool-icon {
		height: 2rem !important;
		font-size: 2rem !important;
		position: relative;
		top: 50%;
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		justify-content: center;
		align-items: center;
	}

	.tool-icon-label {
		display: none;
	}
}

</style>
