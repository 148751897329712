<template>
	<div>
		<div class="container-sm" id="content" style="padding: 0 1em; max-width: 1000px">

			<div id="room-overview" style="font-weight: 600; font-family: 'Poppins'; font-size: 1.3em; margin-bottom: .5em; font-size: 1.5em">
				Profile Details
			</div>
			<div style="width: 100%; height: 55vh; max-height: 90vw; border-radius: .4rem; overflow: hidden;     -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);">
				<form style="max-width: 50rem; font-family: Poppins; margin-bottom: 1em" v-if="user">
					<div class="row" style="font-weight: 600; font-family: 'Poppins'; font-size: 1.3em">
						<div class="form-group col-md-6">
							<label for="firstname">First Name</label>
							<input type="text" class="form-control" id="firstname" placeholder="First Name" v-bind:value="user.user_metadata.first_name">
						</div>
						<div class="form-group col-md-6">
							<label for="lastname">Last Name</label>
							<input type="text" class="form-control" id="lastname" placeholder="Last Name" v-bind:value="user.user_metadata.last_name">
						</div>
					</div>
					<div class="form-group" style="font-weight: 600; font-family: 'Poppins'; font-size: 1.3em">
						<label for="exampleInputPassword1">Password</label>
						<input type="email" disabled class="form-control" id="email" placeholder="Email Address" v-bind:value="user.email">
					</div>
					<div class="form-group">
						<label for="exampleInputPassword1" style="font-weight: 600; font-family: 'Poppins'; font-size: 1.3em">Newsletter</label>
						<div>
							<input class="form-check-input" type="checkbox" v-bind:checked="user.user_metadata.communication" id="newsletter">
							Email me about new features and special offers.
						</div>
					</div>
					<button style="background-color: #57039F; border-color: #57039F" class="btn btn-primary" v-on:click="save($event)">Save</button>
				</form>
				<button type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#confirmDeleteModal">
					Delete Account
				</button>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	name: "ProductPage",
	computed: {
		user() {
			return this.$store.getters.getUser;
		}
	},
	watch: {
		user: {
			handler: function (val) {
				console.log(val)
			},
			deep: true
		}
	},
	methods: {
		save(event) {
			this.$store.commit('upsertUser', {
				first_name: document.getElementById("firstname").value,
				last_name: document.getElementById("lastname").value,
				communication: document.getElementById("newsletter").checked
			});
			event.preventDefault();
		}
	}
}
</script>

<style scoped>
.form-group {
	margin-bottom: 1rem;
}
ion-modal.auto-height {
	&.bottom {
		align-items: flex-end;
	}

	--height: auto;

	.ion-page {
		position: relative;
		display: block;
		contain: content;

		.inner-content {
			max-height: 80vh;
			overflow: auto;
			padding: 10px;
		}
	}
}

</style>
