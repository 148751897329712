<template>
	<div class="container" style="padding: 0.5rem 1.7rem; padding-bottom: 5rem">
		<div v-for="(category, index) in categories" :key="index" style="margin-bottom: 3rem">
			<div style="font-family: Poppins; font-weight: 600; font-size: 1.2em; margin-bottom: 1rem">
				{{ category.name_en }}
			</div>
			<div style="display: flex; flex-wrap: nowrap; overflow-x: auto;">
				<div v-on:click="$router.push({name: 'productPage', params: {product_uuid: item.uuid }})" v-for="(item, index) in grouped[category.uuid]" :key="index" style="flex: 0 0 auto; width: 40%; max-width: 15rem; height: fit-content; margin-right: 1rem; cursor: pointer">
					<div style="position: relative; height: 0; padding-bottom: 100%; background-color: white; margin-bottom: 1rem; border-radius: .3rem; overflow: hidden">
						<img :src="item.img_1" style="margin: 0; position: absolute; top: 50%; -ms-transform: translateY(-50%); transform: translateY(-50%);"/>
					</div>
					<div class="item-title" style="margin-bottom: .5rem; width: 100%; text-overflow: ellipsis; word-wrap: break-word; white-space:initial; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; max-height: 3.6em; line-height: 1.8em; font-family: Poppins">
						{{ item.name_en }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _ from "lodash";

export default {
	name: "Vendors-Assortment-Page",
	props: {
		vendor_uuid: {
			type: String,
			required: true,
		},
	},
	computed: {
		categories() {
			const categories1 = this.$store.getters.getFurnitureCategories1;
			if(!categories1 || !this.grouped) return [];
			return categories1.filter((category) => Object.keys(this.grouped).includes(category.uuid));
		},
		grouped() {
			let allFurniture = this.$store.getters.getFurniture;
			allFurniture = allFurniture.filter(f => f.vendor === this.vendor_uuid);
			if(!allFurniture) return [];
			return _.mapValues(_.groupBy(allFurniture, 'category_1'));
		},
	},
	created() {
		this.$store.dispatch("fetchFurniture");
	},
};
</script>

<style scoped>

</style>
