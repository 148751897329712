<template>
	<div style="background-color: #F4F4F4; padding: 2em 8% 0 8%; height: 100vh; overflow-y: scroll">
		<div>
			<a href="/public">
				<img src="../../assets/logo.png" style="width: 12em; margin-bottom: 8em">
			</a>
		</div>
		<div
			style="filter: drop-shadow(0 0 25px #C6C6C6); border-radius: 1em; font-family: 'Poppins'; background-color: white; padding: 4em 9%; margin-bottom: 5em">
			<h3 style="font-family: 'Poppins'; font-weight: 600; font-size: 3em; margin-bottom: .5em">
				Terms of Service
			</h3>

			<p>
				These Terms of Service ("Terms") govern your use of the Roomy website, integrated tools, and other products, features, apps, services, technologies, and software we offer, unless expressly stated that separate terms (and not these) apply. By using our services, you agree to these Terms.
			</p>
			<p>
				Roomy develops software that supports interior design for users by integrating furniture provider partners, thereby establishing a connection between furniture consumers and suppliers. Our services are designed to facilitate this connection efficiently and user-friendly, and include the use of our website, our mobile applications, and all other technological solutions we offer.
			</p>
			<p>
				Please read these Terms of Service carefully before using our services. By registering for or using our services, you accept these Terms and agree to be bound by them. If you do not agree to these Terms, you are prohibited from accessing and using our services.
			</p>

			<h4 style="font-family: 'Poppins'; font-weight: 600; font-size: 2em; margin-top: 2em">
				Intellectual Property Ownership
			</h4>
			<p>
				Roomy and its licensors retain ownership over the Services, which include all contents, features, and functionalities such as information, software, text, displays, images, video, and audio, as well as the design, selection, and arrangement thereof ("Materials"). Both the Services and the Materials are protected under United States and international copyright, trademark, patent, trade secret, and other intellectual property laws.
			</p>
			<p>
				The Roomy name, logo, and any related names, logos, product and service names, designs, and slogans are trademarks of Roomy or its affiliates or licensors. You are prohibited from using these marks without prior written permission from Roomy.
			</p>

			<h4 style="font-family: 'Poppins'; font-weight: 600; font-size: 2em; margin-top: 2em">
				Access and Use of Services and Materials
			</h4>
			<p>
				Upon agreeing to these Terms, Roomy grants you authorized access and makes permissible use of the Services and Materials, as outlined below. Except for explicitly stated sections within these Terms, no transfer or license of intellectual property rights to you is implied.
			</p>

			<h4 style="font-family: 'Poppins'; font-weight: 600; font-size: 2em; margin-top: 2em">
				Permitted Uses
			</h4>
			<p>
				Subject to your compliance with these Terms, Roomy grants you a limited, nonexclusive, revocable, non-transferable, non-sublicensable license to:
			</p>
			<div>
				<strong>
					Personal Account Holders:
				</strong>
				<ul>
					<li>Use the Services and Materials for personal, non-commercial purposes.</li>
					<li>Create and manage room designs or floor plans.</li>
					<li>Generate photorealistic renders with Roomy watermark for personal use.</li>
					<li>Participate in Roomy-offered contests and promotions, under specific rules provided for those activities.</li>
				</ul>
			</div>
			<div>
				<strong>
					Commercial Account Holders:
				</strong>
				<ul>
					<li>Utilize the Services and Materials for commercial purposes.</li>
					<li>Create photorealistic renders without the Roomy watermark for commercial use.</li>
				</ul>
			</div>



			Commercial Account Holders:
			Utilize the Services and Materials for commercial purposes.
			Create photorealistic renders without the Roomy watermark for commercial use.
			Educational Account Holders:
			Employ the Services and Materials for educational purposes within a classroom setting.
			Manage and assign licenses to students.
			Create and utilize renders with Roomy watermark for educational uses.
			Prohibited Uses
			You are restricted from using the Services outside the scope of permitted uses. Specifically, you must not:
			Modify, distribute, or create derivative works based on the Services or Materials without authorization.
			Use the Services for any illegal or unauthorized purpose.
			Infringe upon the intellectual property rights or privacy of others.
			By adhering to these terms, you ensure your continued right to access and use Roomy's innovative services. For detailed guidance and updates, please refer to Roomy's official Terms of Service page.


			<button v-on:click="$router.back()" class="btn btn-primary" style="background-color: rgb(94,71,159); border: 0">Return</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "TermsOfServicePage",
}

</script>

<style scoped>

</style>
