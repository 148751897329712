import InputHandler from "@/components/editor/features/InputHandler";
import Point from "@/components/editor/simple-geometry/Point";
import {Furniture} from "@/components/editor/building-elements/Furniture";
import Vector from "@/components/editor/simple-geometry/Vector";

export default class FurnitureMoveHandler extends InputHandler {

	handleMouseDown(p, element) {
		this.offsetFromCenter = new Vector(element.position.x - p.x, element.position.y - p.y);
	}

	handleMouseUp(p, element) {
		element.calculatePath();
	}

	handleMouseMove(p, element) {
		element.position =  p.getOffsetPoint(this.offsetFromCenter);
	}
}
