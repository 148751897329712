export default class Point {

	/**
	 * @param x {int}
	 * @param y {int}
	 */
	constructor(x, y) {
		this.x = x;
		this.y = y;
	}

	getOffsetPoint(vector) {
		return new Point(this.x + vector.x, this.y + vector.y);
	}

	equals(other) {
		return this.x === other.x && this.y === other.y;
	}

	distanceTo(otherPoint) {
		const lengthOnX = Math.abs(this.x - otherPoint.x);
		const lengthOnY = Math.abs(this.y - otherPoint.y);
		return Math.sqrt(Math.pow(lengthOnX, 2) + Math.pow(lengthOnY, 2));
	}
}
