<template>
	<div style="margin-bottom: 1.5em" v-if="vendor">
		<router-link :to="'/app/vendors/' + vendor.uuid" style="color: black; text-decoration: none; display: flex; margin-bottom: .5em">
			<div style="align-items: center; justify-content: center; aspect-ratio: 1/1; height: 2em; display: flex; border-radius: 50%; background-color: white">
				<img style="width: 75%" :src="vendor.logo_url"/>
			</div>
			<div style="display: flex; margin: auto .7em; align-items:center; justify-content:center">{{ vendor.name }}</div>
		</router-link>

		<div class="container-row">
			<div class="viewer-container" style="margin-right: 1em">
				<div class="simpleViewer" :style="'background-color: ' + backgroundColor">
					<div style="margin-left: 5%; width: 90%; height: 80%; float: left">
						<SimpleViewerComponent v-bind:floorplan="floorplan" v-bind:space="space"></SimpleViewerComponent>
					</div>
					<button v-on:click="editFloorplan(floorplan, space)" style="background-color: white; border: 0px solid black; z-index: 10; left: 1.5rem; position: relative; width: 3rem; height: 3rem; border-radius: 1.5rem">
						<i class='bx bx-edit-alt'></i>
					</button>
				</div>
			</div>

			<div class="furniture-container" style=";overflow-x: scroll; display: flex; width: 100%; border-radius: .4rem; text-decoration: none !important;">
				<router-link :to="'/app/products/'+item.uuid" v-for="(item, index) in furniture" :key="index" style="flex: 0 0 auto; float: left; text-decoration: none;">
					<img class="product-img" v-bind:src="item.img_1" style="margin-right: 1em; margin-bottom: 0.5rem; border-radius: 0.3rem"/>
					<div class="product-name" style="font-weight: 200; font-size: 1rem; color: black; text-wrap: normal">
						{{item.name_en}}
					</div>
				</router-link>
			</div>

		</div>

	</div>
</template>

<script>
import SimpleViewerComponent from "@/components/editor/SimpleViewer.vue";
import Floorplan from "@/components/editor/building-elements/Floorplan.js";

export default {
	name: "ScrollContainer",
	components: {SimpleViewerComponent},
	computed: {
		rooms() {
			return this.$store.getters.getRooms;
		},
		vendors() {
			return this.$store.getters.getVendors;
		}
	},
	watch: {
		vendors: {
			handler(newVal) {
				if (newVal && newVal.length > 0) {
					this.vendor = newVal.find(v => v.uuid === this.vendorId);
					console.log(this.vendor)
				}
			},
			immediate: true,
		}
	},
	data() {
		return {
			backgroundColor: "hsl(" + 360 * Math.random() + ',' +
				(25 + 70 * Math.random()) + '%,' +
				(85 + 10 * Math.random()) + '%)',
			vendor: null
		}
	},
	props: {
		floorplan: Floorplan,
		space: Array,
		furniture: Array,
		vendorId: String
	},
	methods: {
		editFloorplan(floorplan, space) {
			this.$store.commit('setRoomId', floorplan.id);
			this.$store.commit('set', floorplan);
			this.$store.commit('setSpace', space);
			this.$router.push('/app/designer');
		}
	}
}
</script>

<style scoped>
@media screen and (min-width: 800px) {
	.container-row {
		display: flex;
	}

	.simpleViewer {
		margin: auto;
		width: 23rem;
		max-width: 90vw;
		height: 100%;
		border-radius: .3em
	}

	.simpleViewer button {
		bottom: -.5em
	}

	.furniture-container {
		height: 23rem;
	}

	.product-img {
		height: 82%;
	}

	.product-name {
		max-width: 23rem;
		text-overflow: ellipsis; word-wrap: break-word; white-space:initial; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; max-height: 3.6em; line-height: 1.8em
	}
}

@media screen and (max-width: 800px) {
	.viewer-container {
		height: 18rem;
		margin-bottom: 1rem;
	}

	.simpleViewer {
		margin: auto;
		width: 23rem;
		max-width: 90vw;
		height: 100%;
		border-radius: .3em
	}

	.simpleViewer button {
		bottom: .5em
	}

	.furniture-container {
		height: 13rem;
		margin-bottom: 1rem;
	}

	.product-img {
		height: 70%;
	}

	.product-name {
		max-width: 8rem;
		text-overflow: ellipsis;
		word-wrap: break-word;
		white-space: initial;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}



	</style>
