<template>
	<div style="background-color: #F4F4F4; padding: 2em 8% 0 8%; height: 100vh; overflow-y: scroll">
		<div>
			<a href="/public">
				<img src="../../assets/logo.png" style="width: 12em; margin-bottom: 2em">
			</a>
		</div>
		<div
			style="filter: drop-shadow(0 0 25px #C6C6C6); border-radius: 1em; font-family: 'Poppins'; background-color: white; padding: 4em 9%; margin-bottom: 5em">
			<h3 style="font-family: 'Poppins'; font-weight: 600; font-size: 3em; margin-bottom: .5em">
				Privacy Policy
			</h3>

			<p>
				Effective as of May 4th, 2024.
			</p>

			<h4 style="font-family: 'Poppins'; font-weight: 600; font-size: 1.5em; margin-top: 2em">
				General information
			</h4>
			<p>
				This privacy policy is intended to inform users of this website about the nature, scope and purpose of the collection and use of personal data by the website operator Roomy.
			</p>
			<p>
				We take your data protection very seriously and treat your personal data confidentially and in accordance with the statutory provisions. As new technologies and the constant further development of this website may result in changes to this data protection declaration, we recommend that you read the data protection declaration again at regular intervals.This Privacy Policy applies to all of the services offered by Roomy. This Privacy Policy is a legal part of the Terms of Service of the Roomy.
			</p>
			<p>
				Definitions of the terms used (e.g. “personal data” or “processing”) can be found in Art. 4 GDPR.
			</p>

			<h4 style="font-family: 'Poppins'; font-weight: 600; font-size: 1.5em; margin-top: 2em">
				Who we are
			</h4>
			<p>
				Roomy is an online platform that enables you to design modern interiors without needing professional expertise. Accessible on various platforms such as Android and Windows, Roomy simplifies the design process for users. In this privacy policy, "Roomy," "we," "our," and "us" refer to Roomy, GesbR, a legally established entity under Austrian law, with its registered office located at Raffaelgasse 25/1/14, 1200 Vienna.
			</p>

			<h4 style="font-family: 'Poppins'; font-weight: 600; font-size: 1.5em; margin-top: 2em">
				How to contact us
			</h4>
			<p>
				If you have any questions, concerns or suggestions about the use by Roomy of your personal data or if any of the provisions of this Privacy Policy is unclear to you, please contact us via e-mail at nico.plattner@roomy.design or by post at Raffaelgasse 25/1/14 1200 Vienna.
			</p>

			<h4 style="font-family: 'Poppins'; font-weight: 600; font-size: 1.5em; margin-top: 2em">
				Collection of information
			</h4>
			<p>
				When using our Roomy platform, various personal data may be collected from you, depending on which services you wish to use. When you register for a Roomy account, we ask you to provide your name and e-mail address. To utilize the full potential of your Roomy account, you may also have the option to upload photos of rooms.
			</p>
			<p>
				If you choose to log in to Roomy through a third-party service that authenticates your identity and connects your social media login information to us, we will collect any necessary information or content that you have allowed the social media provider to share with us, such as your name and email address.
			</p>
			<p>
				Furthermore, we collect data about your access to our website out of legitimate interest and store it as “server log files” on our server. This includes
			</p>
			<ul>
				<li>The website visited</li>
				<li>The time of access</li>
				<li>The amount of data sent in bytes</li>
				<li>The source from which you accessed our site</li>
				<li>The browser used</li>
				<li>The operating system used</li>
				<li>The IP address used</li>
				<li>The sites visited</li>
				<li>Feature related bahaviour (e.g. saving/liking items)</li>
			</ul>
			<p>
				By registering and/or submitting your personal data to Roomy, you consent to the use of this information in accordance with our privacy policy.
			</p>

			<h4 style="font-family: 'Poppins'; font-weight: 600; font-size: 1.5em; margin-top: 2em">
				Collection and processing of personal data
			</h4>
			<p>
				Roomy only collects, uses and passes on your personal data if this is permitted by law or if you consent to the collection of data.
			</p>
			<p>
				Personal data is all information that can be used to identify you personally and that can be traced back to you - for example, your name, e-mail address and telephone number.
			</p>
			<p>
				You can also visit this website without providing any personal data. However, in order to improve our online services, we store your access data to this website (without personal reference). This access data includes, for example, the file you requested or the name of your Internet provider. By anonymizing the data, it is not possible to draw conclusions about your person.
			</p>

			<h4 style="font-family: 'Poppins'; font-weight: 600; font-size: 1.5em; margin-top: 2em">
				How we collect your information
			</h4>
			<p>
				At Roomy, we are committed to transparency in the handling of your personal information. Below are the methods through which we collect data:
			</p>
			<p>
				<strong>Information you provide Directly</strong>
			</p>
			<p>
				This includes data you enter when registering for a Roomy account, such as your username and email address. If you choose to register using social media accounts, we collect the login information authorized by your social media provider. Additionally, you may choose to upload a photo to personalize your account. It's important to note that while you can opt not to provide certain information, this may affect your ability to use some of our services, like account creation or making purchases.
			</p>
			<p>
				<strong>Automatically Collected Information</strong>
			</p>
			<p>
				As you interact with our services, we automatically gather certain data, which is stored in log files. This data includes your Internet Protocol (IP) address, your Internet Service Provider (ISP), and device-specific information such as your operating system and browser type. We also collect navigation and location data, timezone settings, and details about your usage of our site, such as the projects you create.
			</p>
			<p>
				The purpose of collecting this data is to enhance your user experience by analyzing site trends, administering the site, and tracking user movements across our platform. This analysis helps us to continually improve the services we offer. We ensure that none of this automatically collected information is linked to any personally identifiable information.
			</p>

			<h4 style="font-family: 'Poppins'; font-weight: 600; font-size: 1.5em; margin-top: 2em">
				Utilization of Your Information at Roomy
			</h4>
			<p>
				Roomy is dedicated to enhancing your experience and our service offerings through the responsible use of your information. Here’s how we use the data we collect:
			</p>
			<p>
				<strong>Service Provision and Transaction Processing:</strong> We utilize the information gathered to deliver our services effectively, fulfill your requests, and complete transactions. This includes sending you confirmations, updates, security alerts, and support or administrative messages to facilitate both your use and our management of our website and services.
			</p>
			<p>
				<strong>Communication and Customer Support:</strong> We use your information to respond to your inquiries, comments, and requests, address your complaints, and provide customer service. When you communicate with us, we maintain a record of your correspondence to address any issues you might encounter promptly.
			</p>
			<p>
				<strong>Enhancement of User Experience:</strong> Information collected through cookies and other similar technologies helps us monitor and analyze usage trends and user engagement with our projects. This data is crucial for personalizing and improving your experience on our platforms, offering content and features that align with your interests, and enhancing overall site functionality and user-friendliness.
			</p>
			<p>
				<strong>Legal and Contractual Obligations:</strong> We process your personal data for several key reasons:
			</p>
			<ul>
				<li>You have provided your explicit consent for specific uses of your data.</li>
				<li>The processing is necessary to fulfill a contract with you.</li>
				<li>We are required to comply with legal or regulatory obligations.</li>
				<li>The processing is essential for our legitimate business interests, ensuring that this does not compromise your rights or interests as an individual.</li>
			</ul>

			<h4 style="font-family: 'Poppins'; font-weight: 600; font-size: 1.5em; margin-top: 2em">
				Protecting Your Information at Roomy
			</h4>
			<p>
				At Roomy, safeguarding your personal information is paramount. We have instituted robust organizational and technical measures to protect against the loss, misuse, or alteration of your data.
			</p>
			<p>
				<strong>Security Measures:</strong> We deploy advanced security technologies and procedures to ensure the confidentiality and integrity of your data. Our security practices are designed to protect your information from unauthorized access and potential security breaches.
			</p>
			<p>
				<strong>Access Control:</strong> Access to your personal information is strictly limited to Roomy employees, consultants, and affiliated entities who require it to perform their job responsibilities. All individuals who have access to your data are bound by confidentiality obligations and are expected to adhere to our privacy and security policies.
			</p>
			<p>
				<strong>Compliance with Legislation:</strong> Roomy is committed to compliance with applicable laws and regulations governing the protection of personal data. We continually monitor our systems and processes to ensure they meet legal standards and best practices in data security.
			</p>

			<h4 style="font-family: 'Poppins'; font-weight: 600; font-size: 1.5em; margin-top: 2em">
				Data Retention at Roomy
			</h4>
			<p>
				Roomy retains your personal data for as long as necessary to provide you with our services, as long as you remain a registered user. Beyond active usage, we retain your personal data to continue handling any further communications you might initiate.
			</p>
			<p>
				<strong>Compliance with Legal Obligations:</strong> We also retain your information to fulfill our legal, accounting, and regulatory obligations. This includes adhering to requirements for data retention that are mandated by law, which may necessitate keeping data for a specified period even after you have stopped using our services.
			</p>
			<p>
				<strong>Data Review and Deletion:</strong> We regularly review the data we hold to ensure it is not kept longer than necessary. When it is no longer required for the purposes outlined above or to meet our legal obligations, we securely delete or anonymize your data.
			</p>

			<h4 style="font-family: 'Poppins'; font-weight: 600; font-size: 1.5em; margin-top: 2em">
				Sharing Information with Third Parties at Roomy
			</h4>
			<p>
				Roomy is committed to protecting your privacy. We do not sell or share your personal information with unaffiliated third parties, except as explicitly stated in this Privacy Policy.
			</p>
			<p>
				<strong>Circumstances Under Which We Share Information:</strong>
			</p>
			<p>
				<strong>Service Providers:</strong> We may share your information with service providers who assist us in operating our website or conducting our business, under strict confidentiality agreements.
			</p>
			<p>
				<strong>Consent:</strong> We share your information when you provide explicit consent for specific sharing purposes.
			</p>
			<p>
				<strong>Affiliate Partners:</strong> We work with affiliate program partners who may embed our services on their platforms for user tracking and analytics, ensuring that they adhere to our privacy standards.
			</p>
			<p>
				<strong>Business Transitions:</strong> In the event of a business sale, merger, reorganization, dissolution, or similar event, personal information may be part of the transferred assets.
			</p>
			<p>
				<strong>Legal Compliance:</strong> We may disclose information when required by law or to respond to legal processes or lawful requests, including from law enforcement and government agencies.
			</p>
			<p>
				<strong>Non-Personally Identifiable Information:</strong> We may share aggregated or de-identified information with third parties for analytical purposes. This data does not identify individual users and helps us improve user experience and demonstrate the effectiveness of our services.
			</p>
			<p>
				<strong>Caution on User-Initiated Sharing:</strong> If you choose to share your information with others through our services, please be cautious. Once you share your data, we cannot control how it is used or safeguarded. We advise you to only share information with trusted individuals, as we are not responsible for any misuse of your information once it is shared.
			</p>

			<h4 style="font-family: 'Poppins'; font-weight: 600; font-size: 1.5em; margin-top: 2em">
				Your Rights Regarding Personal Information at Roomy
			</h4>
			<p>
				At Roomy, we empower you to manage your personal information effectively. Below are the rights you can exercise at any time:
			</p>
			<p>
				<strong>Right to Access:</strong> You can inquire if we are processing your data and request access to your personal information. You are also entitled to receive a copy of the personal data we hold about you.
			</p>
			<p>
				<strong>Right to Rectification:</strong> You have the right to request that we correct any inaccuracies in your personal data and fill in any incomplete data we hold.
			</p>
			<p>
				<strong>Right to Erasure (Right to be Forgotten):</strong> You may request the deletion of your personal data under certain conditions, such as when the data is no longer necessary for the purposes it was collected. However, there are exceptions, such as when we need to retain your data to comply with legal obligations or for the defense of legal claims.
			</p>
			<p>
				<strong>Right to Restrict Processing:</strong> You can ask us to temporarily halt the processing of your personal data, especially if you believe that the data is inaccurate or our processing is unlawful.
			</p>
			<p>
				<strong>Right to Data Portability:</strong> You can request a copy of your personal data in a structured, commonly used format, and where feasible, we can assist in transferring this data to another entity.
			</p>
			<p>
				<strong>Right to Object:</strong> If we process your data based on legitimate interests or public interest, you have the right to object to this processing. We will cease processing your data unless we demonstrate compelling legitimate reasons for the processing that override your interests, rights, and freedoms.
			</p>
			<p>
				<strong>Rights Related to Automated Decision-Making and Profiling:</strong> You may challenge decisions based solely on automated processing of your data that significantly affect you and request a manual review of the decision.
			</p>
			<p>
				<strong>Right to Withdraw Consent:</strong> If you have given consent for specific data processing activities, you may withdraw this consent at any time. Withdrawing consent does not affect the lawfulness of processing based on consent before its withdrawal.
			</p>
			<p>
				<strong>Exercising Your Rights:</strong> To exercise any of these rights, please contact us using the details provided in this Privacy Policy. For direct actions like deleting your data, you can typically do this through your account settings on our platform.
			</p>
			<p>
				<strong>Concerns and Complaints:</strong> If you believe that our processing of your personal data infringes data protection laws, you have the right to lodge a complaint with a data protection authority in the EU member state of your residence, where we are based, or where the alleged infringement occurred.
			</p>

			<h4 style="font-family: 'Poppins'; font-weight: 600; font-size: 1.5em; margin-top: 2em">
				How We Use Cookies
			</h4>
			<p>
				<strong>Remembering Your Preferences:</strong> Cookies help our website and mobile applications remember your device and your preferences, such as automatic sign-in during future visits.
			</p>
			<p>
				<strong>Consent for Cookies:</strong> Upon your first visit to our site, a pop-up window will ask for your consent to use cookies. If you choose not to allow the use of cookies, we will not track your device for marketing purposes.
			</p>
			<p>
				<strong>Essential Cookies:</strong> Some cookies are essential for the necessary functionality of our website and will not be blocked if you choose to use the notification banner to refuse cookies.
			</p>
			<p>
				<strong>Managing Cookies:</strong>
			</p>
			<p>
				<strong>Adjusting Settings:</strong> You can manage your cookie preferences at any time through your browser settings, where you can delete or refuse cookies.
			</p>
			<p>
				<strong>Impact on Website Experience:</strong> Please note that disabling cookies might affect the functionality of our website, and some sections may not operate as intended.
			</p>

			<h4 style="font-family: 'Poppins'; font-weight: 600; font-size: 1.5em; margin-top: 2em">
				Children’s Privacy at Roomy
			</h4>
			<p>
				At Roomy, we recognize the importance of protecting the privacy of children in the online environment. We do not knowingly collect personal information from children under the age of 16.
			</p>
			<p>
				<strong>Usage by Children Under 16:</strong> If you are under 16 years old, you may only use Roomy’s services with the consent of a parent or guardian. This policy ensures that personal information from children is not collected without parental consent, aligning with our commitment to safeguard children's privacy.
			</p>
			<p>
				<strong>Parental Involvement:</strong> We encourage parents and guardians to take an active role in their children’s online activities and interests. If at any time a parent or guardian believes that their child has provided us with personal information without their consent, they should contact us, and we will take steps to remove such information and terminate the child’s account.
			</p>

			<h4 style="font-family: 'Poppins'; font-weight: 600; font-size: 1.5em; margin-top: 2em">
				External Links Policy at Roomy
			</h4>
			<p>
				Our website and mobile applications, along with our email communications, may include links to external websites not operated or controlled by Roomy. Please be aware that we are not responsible for the content or privacy practices of these other sites.
			</p>
			<p>
				<strong>Personal Data on External Websites:</strong> The personal data that you provide on external sites linked from our platform is not covered by this Privacy Policy. We do not have control over how these websites collect or manage your information.
			</p>
			<p>
				<strong>Reviewing Privacy Policies:</strong> We strongly advise you to review the privacy policies of any external websites you visit through our links before providing any personal data. This helps ensure that you are aware of how these sites will handle your information and allows you to make informed decisions about your privacy.
			</p>

			<h4 style="font-family: 'Poppins'; font-weight: 600; font-size: 1.5em; margin-top: 2em">
				Updates to Our Privacy Policy
			</h4>
			<p>
				At Roomy, we continuously refine our Privacy Policy to align with the latest privacy practices and regulations. Changes to our Privacy Policy will be posted on our dedicated privacy page, which can be accessed directly via Roomy Privacy Updates.
			</p>
			<p>
				<strong>Notification of Changes:</strong> We commit to not reducing your rights under this Privacy Policy without your explicit consent. Any amendments to our policy will be clearly communicated through a prominent notice on our website. Changes will become effective as of the date listed at the top of the policy page.
			</p>
			<p>
				<strong>Engagement of Subprocessors:</strong> To support the delivery of our services, Roomy may engage external service providers, known as Subprocessors. We may need to share your personal information with these Subprocessors as part of service delivery. The list of Roomy’s current Subprocessors, authorized to process user data, is available below. Each Subprocessor is permitted to process user data solely for the purpose of delivering the services we have requested.
			</p>
			<p>
				<strong>Engagement of Subprocessors:</strong> To support the delivery of our services, Roomy may engage external service providers, known as Subprocessors. We may need to share your personal information with these Subprocessors as part of service delivery. The list of Roomy’s current Subprocessors, authorized to process user data, is available below. Each Subprocessor is permitted to process user data solely for the purpose of delivering the services we have requested.
			</p>

			<table class="table">
				<thead>
				<tr>
					<th scope="col">Company</th>
					<th scope="col">Services provided</th>
					<th scope="col">Service location</th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td>Matomo</td>
					<td>Analytics</td>
					<td>Germany</td>
				</tr>
				<tr>
					<td>Twilio Ireland Limited</td>
					<td>Automated Email</td>
					<td>Global</td>
				</tr>
				<tr>
					<td>Microsoft</td>
					<td>Staff Email</td>
					<td>Global</td>
				</tr>
				<tr>
					<td>Vercel Inc.</td>
					<td>Hosting</td>
					<td>Global</td>
				</tr>
				<tr>
					<td>Supabase, Inc.</td>
					<td>Database, Authentication, Hosting</td>
					<td>Germany</td>
				</tr>
				</tbody>
			</table>


			<button v-on:click="$router.back()" class="btn btn-primary" style="background-color: rgb(94,71,159); border: 0; margin-top: 2em">Return</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "PrivacyPolicyPage",
}

</script>

<style scoped>

</style>
