import Room from "@/components/editor/building-elements/Room";
import Wall from "@/components/editor/building-elements/Wall";
import Polygon from "polygon";
import Floorplan from "@/components/editor/building-elements/Floorplan";
import Door from "@/components/editor/building-elements/Door";
import Window from "@/components/editor/building-elements/Window";

export function deserializeFloorplan(floorplanObject, id) {
	const floorplan = floorplanObject
	const walls = new Map(JSON.parse(floorplanObject.walls))
	const wallMap = new Map()

	for (let wall of walls.keys()) {
		const wallObj = new Wall(wall.position.ps.x, wall.position.ps.y, wall.position.pe.x, wall.position.pe.y, wall.thickness)
		wallMap.set(wallObj, walls.get(wall))
	}

	floorplan.walls = wallMap

	const rooms = []
	for (let room of floorplan.rooms) {
		rooms.push(deserializeRoom(room))
	}
	floorplan.rooms = rooms

	const result = new Floorplan();
	result.id = id;
	result.walls = floorplan.walls;
	result.rooms = floorplan.rooms;
	result.roomCounter = floorplan.roomCount;
	result.letterCount = floorplan.letterCount;

	return result;





	// const floorplan = new Floorplan();
	//
	// floorplan.walls = new Map(JSON.parse(floorplanObject.walls));
	// floorplan.rooms = floorplanObject.rooms;
	// floorplan.selectedRoom = floorplanObject.selectedRoom;
	// floorplan.selectedWall = floorplanObject.selectedWall;
	//
	// return floorplan;
}


export function serializeFloorplan(floorplan, userUuid) {
	const serialzed = new Floorplan();

	serialzed.id = floorplan.id;
	serialzed.walls = typeof floorplan.walls === 'string' ? floorplan.walls : JSON.stringify(Array.from(floorplan.walls.entries()));
	serialzed.rooms = floorplan.rooms;
	serialzed.selectedRoom = floorplan.selectedRoom;
	serialzed.selectedWall = floorplan.selectedWall;

	return serialzed;
}


export function deserializeRoom(roomObject) {
	const room = new Room(roomObject.walls, null, roomObject.roomName);

	room.shape = new Polygon(roomObject.shape.points);
	room.selected = roomObject.selected;
	room.scaleFactor = roomObject.scaleFactor;

	const image = new Image();
	image.src = roomObject.floorPatternSrc;
	room.floorPattern = image;
	room.floorPatternSrc = roomObject.floorPatternSrc;

	const roomWalls = [];
	roomObject.walls.forEach((wall) => {
		const tempWall = new Wall(wall.position.ps.x, wall.position.ps.y, wall.position.pe.x, wall.position.pe.y, wall.thickness);

		wall.windows.forEach(window => {
			tempWall.windows.push(new Window(window.position.ps.x, window.position.ps.y, window.position.pe.x, window.position.pe.y))
		})

		wall.doors.forEach(door => {
			console.log(door)
			tempWall.doors.push(new Door(door.position.ps.x, door.position.ps.y, door.position.pe.x, door.position.pe.y, door.right))
		})

		roomWalls.push(tempWall);
	});

	room.walls = roomWalls;
	return room;
}
