<template>
	<div>
		<h2 style="font-weight: 600; font-family: 'Poppins'; font-size: 1.3em; margin-bottom: 1.5rem">
			What rooms would you like to design? (min. 1)
		</h2>
		<div class="container" style="margin: 0; padding: 0">
			<div class="row g-3" style="margin-bottom: 2rem">
				<div class="col-6" v-for="room in roomTypes" :key="room">
					<div style="background-color: #E2E0DB; padding: 1rem; border-radius: .3rem" @click="room.selected = !room.selected" v-if="room.id!==7 || this.hasChildrensRoom">
						<div class="form-check">
							<input :checked="room.selected" class="form-check-input" type="checkbox" id="flexCheckDefault">
							<label class="form-check-label">
								{{room.name_en}}
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>

		<button style="margin: 1rem  1rem 0 0" class="btn btn-secondary" v-on:click="navigate('/app/welcome/4')">Go Back</button>
		<button style="background-color: #57039F; border-color: #57039F; margin-top: 1rem" class="btn btn-primary" v-on:click="navigate('/app/welcome/6')">Finish</button>
	</div>
</template>


<script>
export default {
	name: "RoomSelection",
	data() {
		return {
			hasChildrensRoom: false,
		}
	},
	computed: {
		roomTypes() {
			return this.$store.getters.getRoomTypes;
		},
		roomsInStore() {
			return this.$store.getters.getRoomPreferences;
		},
		familyInStore() {
			return this.$store.getters.getFamily;
		}
	},
	watch: {
		roomsInStore: {
			handler(newVal) {
				if(newVal && this.roomTypes) {
					this.roomTypes.forEach(roomType => {
						if(newVal.includes(roomType.id)) {
							roomType.selected = true;
						}
					})
				}
			},
			immediate: true
		},
		familyInStore: {
			handler(newVal) {
				if ((newVal.babies && newVal.babies > 0) ||
					(newVal.toddlers && newVal.toddlers > 0) ||
					(newVal.preschoolers && newVal.preschoolers > 0) ||
					(newVal.middleschoolers && newVal.middleschoolers > 0) ||
					(newVal.teens && newVal.teens > 0) ||
					(newVal.youngAdults && newVal.youngAdults > 0)) {
					this.hasChildrensRoom = true;
				} else {
					this.hasChildrensRoom = false;
				}
			},
			immediate: true
		}
	},
	methods: {
		navigate(newRoute) {
			let roomPreferences = this.roomTypes.filter(room => room.selected).map(room => room.id);
			if (!this.hasChildrensRoom) {
				roomPreferences = roomPreferences.filter(room => room !== 7);
			}

			this.$store.commit('setRoomPreferences', roomPreferences);
			this.$store.dispatch('upsertPreferences')
			this.$router.push(newRoute);
		},
	},
}
</script>


<style scoped>

</style>
