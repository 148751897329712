<template>
	<div class="container">
		<div style="margin-bottom: 1rem">
			<div class="input-group rounded">
				<input type="search" class="form-control rounded" placeholder="Search"/>
					<i class="fas fa-search"></i>
			</div>
		</div>
		<div v-if="!selectedCategory1" style="display: flex; flex-wrap: wrap; width: 100%">
			<template v-for="item in category1" :key="item">
				<div v-on:click="selectCategory1(item.uuid)" style="width: 50%; height: 10rem; overflow: hidden; text-align: center; padding: .4rem .2rem">
					<div style="height: 100%">
						<img
							v-bind:src="item.image_url"
							style="position: relative; min-height: 8rem; object-fit: cover; border-radius: 0.3rem"/>
					</div>
					<div style="background-color: white; position: relative; bottom: 1rem; font-family: Poppins">
						{{ item.name_en }}
					</div>
				</div>
			</template>
		</div>

		<div style="display: flex; flex-wrap: wrap; width: 100%">

			<template v-for="item in furnitureInCategory" :key="item">
				<div v-on:click="selectFurniture(item.uuid)" :ref="item.uuid" style="width: 50%; height: 10rem; overflow: hidden; text-align: center; padding: .4rem .2rem">
					<div style="height: 100%">
						<img
							v-bind:src="item.img_1"
							style="position: relative; min-height: 8rem; object-fit: cover; border-radius: 0.3rem"/>
					</div>
					<div style="background-color: white; position: relative; bottom: 1rem; font-family: Poppins">
						{{ item.title }}
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import {eventBus} from "@/main";

export default {
	name: "FurnitureOptions",
	data() {
		return {
			wallThickness: 10,
			furnitureInCategory: [],
			selectedUuid: null,
			selectedCategory1: null,
			anon_key: process.env.VUE_APP_SUPABASE_ANON_KEY
		}
	},
	computed: {
		furniture() {
			return this.$store.getters.getFurniture;
		},
		category1() {
			return this.$store.getters.getFurnitureCategories1;
		},
	},
	mounted() {
		eventBus.on('placeFurniture', (itemUid) => {
			if(this.$refs[this.selectedUuid] && this.$refs[this.selectedUuid].length > 0) {
				this.$refs[this.selectedUuid].classList.remove('asset-container-active');
			}
			this.selectedUuid = null;
		});
	},
	methods: {
		selectCategory1(category) {
			this.selectedCategory1 = category;
			this.furnitureInCategory = this.furniture.filter(f => f.category_1 === category);
		},
		selectFurniture(itemUuid) {
			if(this.selectedUuid) {
				document.getElementById(this.selectedUuid).classList.remove('asset-container-active');
			}

			this.selectedUuid = itemUuid;
			this.$refs[itemUuid][0].classList.add('asset-container-active');

			const item = this.furniture.find(f => f.uuid === itemUuid);
			eventBus.emit('selectFurniture', item);
		}
	}
}
</script>

<style scoped>
.asset-container {
	border-radius: .3rem;
	background-color: #7ca6bf;
}

.asset-container:hover:not(.asset-container-active) {
	background-color: #91b1c4;
}

.asset-container-active {
	background-color: #b6c9d4;
}

</style>
