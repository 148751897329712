<template>
	<div>
		<div class="container-sm" id="content" style="padding: 0 1em; max-width: 1000px; margin-top: 4rem">
			<div id="room-overview" style="margin-bottom: .5em; font-weight: 600; font-family: 'Poppins'; font-size: 1.3em">
				Room Type
			</div>
			<select v-model="roomType" style="padding: 0 .5rem; margin-bottom: 2rem; font-family:'Poppins'; border-radius: .3rem; width: 100%; height:2.5rem; border: 0; background-color: white">
				<option value="" disabled>Select your room type</option>
				<option value="living">Living room</option>
				<option value="office">Office</option>
				<option value="bedroom">Bedroom</option>
				<option value="kitchen">Kitchen</option>
				<option value="bathroom">Bathroom</option>
				<option value="toilet">Toilet</option>
				<option value="toilet">Hallway</option>
			</select>

			<div v-if="roomType">
				<div id="room-overview" style="margin-bottom: .5em; font-weight: 600; font-family: 'Poppins'; font-size: 1.3em">
					Room Usage
				</div>
				<div style="display: flex; margin-bottom: 2rem; font-family: 'Poppins'; width: 100%; flex-wrap: wrap; justify-content: center">
					<div v-for="(object, index) in roomUsages" :key="index" style="width: fit-content; margin-bottom: 1rem; margin-right: 1rem; border-radius: .3rem">
						<div class="room-type" v-on:click="object.selected = !object.selected" v-bind:class="object.selected ? 'button-selected' : 'button-deselected'">
							{{ object.name }}
						</div>
					</div>
				</div>
			</div>


			<div v-if="roomType">
				<div id="room-overview" style="margin-bottom: .5em; font-weight: 600; font-family: 'Poppins'; font-size: 1.3em">
					Room Shape
				</div>
				<div style="display: flex; margin-bottom: 2rem;font-family: 'Poppins'; width: 100%; flex-wrap: wrap; justify-content: center">
					<div v-for="(item, index) in shapes" :key="index" style="width: 6rem; height: 6rem; margin-bottom: 1rem; margin-right: 1rem; border-radius: .3rem; background-color: darkgray; color: white">
						<div v-on:click="selectRoomShape(item)" v-bind:class="item === this.selectedShape ? 'button-selected' : 'button-deselected'" class="room-shape">
							<img :src="'/room-shapes/' + item.orientations[0].name + '.svg'" style="width: 60%">
						</div>
					</div>
				</div>
			</div>

			<div v-if="roomType">
				<div id="room-overview" style="margin-bottom: .5em; font-weight: 600; font-family: 'Poppins'; font-size: 1.3em">
					Orientation
				</div>
				<div style="display: flex; font-family: 'Poppins'; width: 100%; flex-wrap: wrap; justify-content: center">
					<div v-for="(item, index) in this.selectedShape.orientations" :key="index" style="width: 4.5rem; height: 4.5rem; margin-bottom: 1rem; margin-right: 1rem; border-radius: .3rem; background-color: darkgray; color: white">
						<div @click="selectOrientation(item)" v-bind:class="item === this.selectedOrientation ? 'button-selected' : 'button-deselected'" class="room-shape">
							<img :src="'/room-shapes/' + item.name + '.svg'" style="width: 60%">
						</div>
					</div>
				</div>
			</div>

			<div v-if="roomType">
				<button type="button" @click="createRoom" class="btn btn-light" style="float: right; margin-top: .5em; padding-left: 2em; padding-right: 2em; font-family: 'Poppins'; font-weight: 400; background-color: #57039F; color: white">
					Create Room
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import Floorplan from "@/components/editor/building-elements/Floorplan.js";
import Wall from "@/components/editor/building-elements/Wall.js";

export default {
	name: "RoomPresetPage",
	data() {
		return {
			isOpen: false,
			login: true,
			user: null,
			roomType: "",
			roomUsages: [],
			shapes: [],
			selectedShape: null,
			selectedOrientation: null,
		}
	},
	methods: {
		switchToRegister(event, toLogin) {
			this.isOpen = true;
			event.preventDefault();
			this.login = toLogin;
		},
		closeModal() {
			this.isOpen = false;
		},
		selectRoomShape(roomShape) {
			if (this.selectedShape !== roomShape) {
				this.selectedShape = roomShape;
				this.selectedOrientation = roomShape.orientations[0];
			}
		},
		selectOrientation(roomOrientation) {
			this.selectedOrientation = roomOrientation;
		},
		createRoom() {
			const floorplan = new Floorplan();

			for (let i = 0; i < this.selectedOrientation.shape.length-1; i++) {
				floorplan.addWall(
					new Wall(
						this.selectedOrientation.shape[i].x,
						this.selectedOrientation.shape[i].y,
						this.selectedOrientation.shape[i+1].x,
						this.selectedOrientation.shape[i+1].y,
						20)
				);
			}

			floorplan.addWall(
				new Wall(
					this.selectedOrientation.shape[this.selectedOrientation.shape.length-1].x,
					this.selectedOrientation.shape[this.selectedOrientation.shape.length-1].y,
					this.selectedOrientation.shape[0].x,
					this.selectedOrientation.shape[0].y,
					20)
			);

			this.$store.commit('set', floorplan);

			this.$router.push({ name: 'designer' });

		}
	},
	async mounted() {
		this.roomUsages.push({ name: 'cooking', selected: false });
		this.roomUsages.push({ name: 'dining', selected: false });
		this.roomUsages.push({ name: 'sleeping', selected: false });
		this.roomUsages.push({ name: 'working', selected: false });
		this.roomUsages.push({ name: 'gatherings', selected: false });
		this.roomUsages.push({ name: 'childcare', selected: false });
		this.roomUsages.push({ name: 'tv', selected: false });
		this.roomUsages.push({ name: 'wardrobe', selected: false });
		this.roomUsages.push({ name: 'storage', selected: false });
		this.roomUsages.push({ name: 'laundry', selected: false });
		this.roomUsages.push({ name: 'hygiene', selected: false });

		// TODO outsource this into the DB
		this.shapes = [
			{
				name: 'rect',
				orientations: [
					{
						name: 'rect_dir_1',
						shape: [
							{x: 0, y: 0},
							{x: 300, y: 0},
							{x: 300, y: 400},
							{x: 0, y: 400},
						]
					},
					{
						name: 'rect_dir_2',
						shape: [
							{x: 0, y: 0},
							{x: 400, y: 0},
							{x: 400, y: 300},
							{x: 0, y: 300},
						]
					}
				]
			},
			{
				name: 'l',
				orientations: [
					{
						name: 'l_dir_1',
						shape: [
							{x: 200, y: 0},
							{x: 400, y: 0},
							{x: 400, y: 400},
							{x: 0, y: 400},
							{x: 0, y: 200},
							{x: 200, y: 200},
						]
					},
					{
						name: 'l_dir_2',
						shape: [
							{x: 0, y: 0},
							{x: 200, y: 0},
							{x: 200, y: 200},
							{x: 400, y: 200},
							{x: 400, y: 400},
							{x: 0, y: 400},
						]
					},
					{
						name: 'l_dir_3',
						shape: [
							{x: 0, y: 0},
							{x: 400, y: 0},
							{x: 400, y: 200},
							{x: 200, y: 200},
							{x: 200, y: 400},
							{x: 0, y: 400},
						]
					},
					{
						name: 'l_dir_4',
						shape: [
							{x: 0, y: 0},
							{x: 400, y: 0},
							{x: 400, y: 400},
							{x: 200, y: 400},
							{x: 200, y: 200},
							{x: 0, y: 200},
						]
					},
				]
			},
			{
				name: 't',
				orientations: [
					{
						name: 't_dir_1',
						shape: [
							{x: 200, y: 0},
							{x: 400, y: 0},
							{x: 400, y: 200},
							{x: 600, y: 200},
							{x: 600, y: 600},
							{x: 0, y: 600},
							{x: 0, y: 200},
							{x: 200, y: 200},
						]
					},
					{
						name: 't_dir_2',
						shape: [
							{x: 0, y: 0},
							{x: 400, y: 0},
							{x: 400, y: 200},
							{x: 600, y: 200},
							{x: 600, y: 400},
							{x: 400, y: 400},
							{x: 400, y: 600},
							{x: 0, y: 600},
						]
					},
					{
						name: 't_dir_3',
						shape: [
							{x: 0, y: 0},
							{x: 600, y: 0},
							{x: 600, y: 400},
							{x: 400, y: 400},
							{x: 400, y: 600},
							{x: 200, y: 600},
							{x: 200, y: 400},
							{x: 0, y: 400},
						]
					},
					{
						name: 't_dir_4',
						shape: [
							{x: 200, y: 0},
							{x: 600, y: 0},
							{x: 600, y: 600},
							{x: 200, y: 600},
							{x: 200, y: 400},
							{x: 0, y: 400},
							{x: 0, y: 200},
							{x: 200, y: 200},
						]
					},
				]
			},
			{
				name: 'u',
				orientations: [
					{
						name: 'u_dir_1',
						shape: [
							{x: 0, y: 0},
							{x: 200, y: 0},
							{x: 200, y: 200},
							{x: 400, y: 200},
							{x: 400, y: 0},
							{x: 600, y: 0},
							{x: 600, y: 600},
							{x: 0, y: 600},
						]
					},
					{
						name: 'u_dir_2',
						shape: [
							{x: 0, y: 0},
							{x: 600, y: 0},
							{x: 600, y: 200},
							{x: 400, y: 200},
							{x: 400, y: 400},
							{x: 600, y: 400},
							{x: 600, y: 600},
							{x: 0, y: 600},
						]
					},
					{
						name: 'u_dir_3',
						shape: [
							{x: 0, y: 0},
							{x: 600, y: 0},
							{x: 600, y: 600},
							{x: 400, y: 600},
							{x: 400, y: 400},
							{x: 200, y: 400},
							{x: 200, y: 600},
							{x: 0, y: 600},
						]
					},
					{
						name: 'u_dir_4',
						shape: [
							{x: 0, y: 0},
							{x: 600, y: 0},
							{x: 600, y: 600},
							{x: 0, y: 600},
							{x: 0, y: 400},
							{x: 200, y: 400},
							{x: 200, y: 200},
							{x: 0, y: 200},
						]
					},
				]
			},
			{
				name: 'z',
				orientations: [
					{
						name: 'z_dir_1',
						shape: [
							{x: 0, y: 0},
							{x: 400, y: 0},
							{x: 400, y: 200},
							{x: 600, y: 200},
							{x: 600, y: 600},
							{x: 200, y: 600},
							{x: 200, y: 400},
							{x: 0, y: 400},
						]
					},
					{
						name: 'z_dir_2',
						shape: [
							{x: 200, y: 0},
							{x: 600, y: 0},
							{x: 600, y: 400},
							{x: 400, y: 400},
							{x: 400, y: 600},
							{x: 0, y: 600},
							{x: 0, y: 200},
							{x: 200, y: 200},
						]
					},
				]
			},
		]

		this.selectRoomShape(this.shapes[0]);
	},
}



</script>

<style scoped>
.room-type {
	padding: 1rem;
	border-radius: .3rem;
	cursor: default
}


.room-shape {
	border-radius: .3rem;
	cursor: default;
	display: flex;
	justify-content: center;
	height: 100%
}

.button-selected {
	background-color: rebeccapurple;
	color: white;
}

.button-deselected {
	background-color: darkgray;
	color: white;
}

@media (hover: hover) and (pointer: fine) {
	.button-selected:hover {
		background-color: #9574B2;
		color: white;
	}

	.button-deselected:hover {
		background-color: darkgray;
		color: white;
	}
}
</style>
