import {getResource} from "@/api/RequestHandler";

export const homeTypeStore = {
	state: () => ({
		homeTypes: []
	}),
	mutations: {
		setHomeTypes (state, payload) {
			state.homeTypes = payload
		}
	},
	getters: {
		getHomeTypes(state) {
			if(state.homeTypes.length === 0) {
				getResource('home_type').then(({data, error}) => {
					state.homeTypes = data.sort((a, b) => a.id - b.id)
				})
			}

			return state.homeTypes
		}
	}
}

