import InputHandler from "@/components/editor/features/InputHandler";
import Point from "@/components/editor/simple-geometry/Point";
import {getSnappingPoint, getSnappingPointAndSnappedWall} from "@/components/editor/features/SnappingHelper";
import DirectedSegment from "@/components/editor/simple-geometry/DirectedSegment";
import Vector from "@/components/editor/simple-geometry/Vector";
import {drawDirectedSegment} from "@/components/editor/drawing/DrawingHelper";
import {addPaddingToLine, addThicknessToLine} from "@/components/editor/simple-geometry/Operations";
import Window from "@/components/editor/building-elements/Window";
import {eventBus} from "@/main";

export default class WindowHandler extends InputHandler {

	handleMouseDown(e, data, floorplan) {
		const p = new Point(
			data.canvas.panX + e.offsetX / data.canvas.scaleFactor,
			data.canvas.panY + e.offsetY / data.canvas.scaleFactor
		);

		const snap = getSnappingPointAndSnappedWall(floorplan, p);

		if((snap.x !== p.y || snap.y !== p.y) && snap.wall) {
			const directed = new DirectedSegment(snap.wall.position.ps, snap.wall.position.pe);
			const vector = Vector.createFromDirectedSegment(directed);
			const unit = vector.getUnitVector().multiply(100);
			const start = snap.point.getOffsetPoint(unit.multiply(-0.5));
			const line = DirectedSegment.createFrom(start, unit);
			const window = new Window(line.start.x, line.start.y, line.end.x, line.end.y);
			snap.wall.windows.push(window);
			eventBus.emit('changeMode', 'select');
		}
	}

	handleMouseUp() {}

	handleMouseMove(e, data, floorplan) {
		const p = new Point(
			data.canvas.panX + e.offsetX / data.canvas.scaleFactor,
			data.canvas.panY + e.offsetY / data.canvas.scaleFactor
		);

		const snap = getSnappingPointAndSnappedWall(floorplan, p);

		if((snap.x !== p.y || snap.y !== p.y) && snap.wall) {
			const directed = new DirectedSegment(snap.wall.position.ps, snap.wall.position.pe);
			const vector = Vector.createFromDirectedSegment(directed);
			const unit = vector.getUnitVector().multiply(100);
			const start = snap.point.getOffsetPoint(unit.multiply(-0.5));
			const line = DirectedSegment.createFrom(start, unit);
			const outline = addThicknessToLine(line.start.x, line.start.y, line.end.x, line.end.y, snap.wall.thickness/2);

			data.canvas.drawWindow(outline, line);
		}
	}
}
