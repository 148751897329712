<template>
	<div>
		<div ref="canvasParent" style="position: absolute; width: 100%" v-bind:style="{height: innerHeight}">
			<router-link to="/app" id="return-button">
				<i class='bx bxs-chevron-left' style="margin-right: .4rem"></i>
				Home
			</router-link>

			<ToolBar ref="toolbar" id="toolbar"></ToolBar>

			<floorplan-canvas
				v-bind:class="{ grab: mode === 'pan' }"
				height="500"
				v-on:wheel="handleScroll($event)"
				v-on:mousedown="handleMouseDown($event)"
				v-on:touchstart="handleMouseDown($event)"
				v-on:mouseup="handleMouseUp($event)"
				v-on:touchend="handleMouseUp($event)"
				v-on:mousemove="handleMouseMove($event)"
				v-on:touchmove="handleMouseMove($event)"
				ref="canvas"
				v-bind:draw-bg="true"
				v-bind:space="space"
				v-bind:floorplan="floorplan">
			</floorplan-canvas>
		</div>
	</div>
</template>

<script>
import {eventBus, historyHandler} from "@/main";
import WallHandler from "@/components/editor/features/WallHandler";
import RoomHandler from "@/components/editor/features/RoomHandler";
import PanHandler from "@/components/editor/features/PanHandler";
import Floorplan from "@/components/editor/building-elements/Floorplan";
import FloorplanCanvas from "@/components/editor/drawing/Canvas";
import WindowHandler from "@/components/editor/features/WindowHandler";
import DoorHandler from "@/components/editor/features/DoorHandler";
import BIFurnitureHandler from "@/components/editor/features/BIFurnitureHandler";
import FurnitureHandler from "@/components/editor/features/FurnitureHandler";
import ZoomPanHandler from "@/components/editor/features/ZoomPanHandler";
import SelectHandler from "@/components/editor/features/SelectHandler";
import {supabase} from "@/api/supabase.js";
import {serializeFloorplan} from "@/components/common/RoomDeserialize.js";
import ToolBar from "@/components/layout/ToolBar.vue";

export default {
	name: "floorplan-editor",
	components: {ToolBar, FloorplanCanvas },
	data() {
		return {
			canvas: null,
			wallThickness: 15,
			space: [],
			// floorplan: null,
			mode: 'room',
			handler: null,
			zoomPanHandler: null,
			floorPattern: null,
			toolbar: null,
			innerHeight: window.innerHeight + 'px',
		}
	},
	computed: {
		furnitureCategory1() {
			return this.$store.getters.getFurnitureCategories1;
		},
		user() {
			return this.$store.getters.getUser;
		},
		floorplan() {
			const rooms = this.$store.getters.getRooms;
			const selected = this.$store.getters.getRoomId;
			if(rooms) {
				return rooms.find(room => room.id === selected);
			}
			return null;
		},
		suggestionSpace() {
			return this.$store.getters.getSpace;
		}
	},
	watch: {
		floorplan() {
			this.canvas = this.$refs.canvas;
			this.canvas.drawAll();
		},
		suggestionSpace(space) {
			this.space = space;
		}
	},
	methods: {
		handleMouseDown(e) {
			e.preventDefault(); // prevents event from firing a second time
			this.canvas.clear();
			this.toolbar.removeOptions();
			const event = getEventOrigin(e);
			if(event.length === 1) {
				this.handler.handleMouseDown(getEventOrigin(e)[0], this.$data, this.floorplan);
			} else {
				this.handler.anchor = null;
				this.zoomPanHandler.handleMouseDown(getEventOrigin(e), this.$data, this.$refs.canvasParent);
			}
			this.canvas.drawAll();
		},
		async handleMouseUp(e) {
			e.preventDefault(); // prevents event from firing a second time
			this.canvas.clear();
			const event = getEventOrigin(e);
			if (event.length === 1) {
				this.handler.handleMouseUp(getEventOrigin(e)[0], this.$data, this.floorplan);
				this.save();
			} else {
				this.handler.anchor = null;
				this.zoomPanHandler.handleMouseUp(getEventOrigin(e), this.$data, this.$refs.canvasParent);
			}
			this.canvas.drawAll();
		},
		handleMouseMove(e) {
			e.preventDefault(); // prevents event from firing a second time
			window.scrollTo(0,0)
			this.canvas.clear();
			const event = getEventOrigin(e);
			if(event.length === 1) {
				this.handler.handleMouseMove(getEventOrigin(e)[0], this.$data, this.floorplan);
			} else {
				this.handler.anchor = null;
				this.zoomPanHandler.handleMouseMove(getEventOrigin(e), this.$data, this.$refs.canvasParent);
			}
			this.canvas.drawAll();
		},
		handleScroll(e) {
			let parent = this.$refs.canvasParent;
			let rect = parent.getBoundingClientRect();
			let offsetX = e.clientX - rect.left;
			let offsetY = e.clientY - rect.top;

			this.canvas.zoom({x: offsetX, y: offsetY}, e.deltaY < 0 ? 1 : -1)
		},
		async save() {
			this.$store.commit('upsertFloorplan', { floorplan: this.floorplan, userUuid: this.user.id })

			// const {data, error} = await supabase
			// 	.from('floorplan')
			// 	.upsert([
			// 		{ id: this.$store.getters.getFloorPlanId, floorplan: serializeFloorplan(this.floorplan), user_id: this.$store.getters.getUser.id }
			// 	]).select();
			//
			// console.log(data)
		}
	},
	mounted() {
		this.canvas = this.$refs.canvas;
		this.handler = new SelectHandler();
		// this.floorplan = new Floorplan();
		this.zoomPanHandler = new ZoomPanHandler();
		this.toolbar = this.$refs.toolbar;

		// if (this.$store.getters.get) {
		// 	this.floorplan = this.$store.getters.get;
		//
		// 	if (this.$store.state.selected_space) {
		// 		this.space = this.$store.getters.getSpace;
		// 	}
		// }
		this.space = this.suggestionSpace;

		eventBus.on('redraw', (args) => {
			this.canvas.clear();
			this.canvas.drawAll();
		});

		eventBus.on('save', async (args) => {
			console.log('save this floorplan')
			await this.save()
		});

		eventBus.on('changeMode', (args) => {
			this.mode = args;
			switch (args) {
			case 'wall': this.handler = new WallHandler(); break;
			case 'room': this.handler = new RoomHandler(); break;
			case 'pan': this.handler = new PanHandler(); break;
			case 'select': this.handler = new SelectHandler(); break;
			case 'window': this.handler = new WindowHandler(); break;
			case 'door': this.handler = new DoorHandler(); break;
			case 'built-in-furniture': this.handler = new BIFurnitureHandler(); break;
			case 'furniture': this.handler = new FurnitureHandler(); break;
			}
			if(this.handler.options) {
				this.toolbar.showOptions(this.handler.getOptions());
			} else {
				this.toolbar.removeOptions();
			}
		});
		// this.canvas.drawAll();

		eventBus.on('historyEvent', (args) => {
			switch (args) {
			// case 'undo': historyHandler.undo(this.space); this.drawScreen(); break;
			// case 'redo': historyHandler.redo(this.space); this.drawScreen(); break;
			case 'trash': this.canvas.clear(); historyHandler.trash(this.$data); this.canvas.drawAll(); break;
			}
		});
	}
}

function getEventOrigin(event) {
	if(event.constructor.name === 'TouchEvent') {
		if(event.targetTouches.length === 2) {
			return [
				{ offsetX: event.targetTouches.item(0).clientX, offsetY: event.targetTouches.item(0).clientY - event.target.offsetTop },
				{ offsetX: event.targetTouches.item(1).clientX, offsetY: event.targetTouches.item(1).clientY - event.target.offsetTop }
			]
		}
		else if(event.changedTouches.length > 1 || (event.targetTouches.length > 0 && event.targetTouches.item(0).identifier !== event.changedTouches.item(0).identifier)) {
			return [
				{ offsetX: event.changedTouches.item(0).clientX, offsetY: event.changedTouches.item(0).clientY - event.target.offsetTop },
				{ offsetX: event.targetTouches.item(0).clientX, offsetY: event.targetTouches.item(0).clientY - event.target.offsetTop }
			]
		} else {
			return [{ offsetX: event.changedTouches.item(0).clientX, offsetY: event.changedTouches.item(0).clientY - event.target.offsetTop - event.target.offsetParent.offsetParent.offsetTop }]
		}
	} else {
		return [event];
	}
}
</script>

<style scoped>

@media screen and (min-width: 800px) {
	#return-button {
		display: none;
	}

	#toolbar {
		position: absolute;
		height: calc(100vh - 2rem);
		width: 25%;
		max-width: 22rem;
		top: 1rem;
		left: 1rem;
		z-index: 1000;
		border-radius: .3rem;
	}
}

@media screen and (max-width: 800px) {
	#toolbar {
		position: absolute;
		left: 5%;
		z-index: 100;
		overflow-x: auto;
		min-height: 3rem;
		border-radius: 0.3rem;
		width: 90%;
		bottom: 6rem;
	}

	#return-button {
		display: block;
		font-family: 'Poppins';
		font-weight: bolder;
		font-size: 1.5rem;
		cursor: pointer !important;
		text-decoration: none;
		color: black;
		position: absolute;
		top: 1rem;
		left: 1rem;
		z-index: 1000;
	}
}

.grab {
	cursor: grab;
}

</style>
