<template>
	<div>
		<h2 style="font-weight: 600; font-family: 'Poppins'; font-size: 1.3em; margin-bottom: 1.5rem">
			Tell us about your flatmates
		</h2>
		<div class="container" style="margin: 0; padding: 0">
			<div class="row g-3" style="margin-bottom: 2rem">
				<div class="col-6">
					<div style="background-color: #E2E0DB; padding: 1rem; border-radius: .3rem" @click="partner = !partner">
						<div class="form-check">
							<input v-model="partner" class="form-check-input" type="checkbox" id="flexCheckDefault">
							<label class="form-check-label">
								My partner
							</label>
						</div>
					</div>
				</div>
			</div>
			<div class="row g-3" v-for="(flatmate, index) in this.flatMates" :key="index" style="margin-bottom: .5rem">
				<div class="col-6">
					<div style="background-color: #E2E0DB; padding: 1rem; border-radius: .3rem">
						<div>
							<label class="form-check-label">
								Flatmate
							</label>
							<div style="float: right; display: flex; font-size: 1.2em">
								<i class="bx bxs-minus-circle" style="font-size: 1.5em; margin: 0 0.2em; cursor: pointer" @click="this.flatMates = this.flatMates.filter(fm => fm !== flatmate)"></i>
							</div>
						</div>
					</div>
				</div>
				<div class="col-6">
					<div style="background-color: #E2E0DB; padding: 1rem; border-radius: .3rem" @click="flatmate.partner = !flatmate.partner">
						<div class="form-check">
							<input v-model="flatmate.partner" class="form-check-input" type="checkbox" id="flexCheckDefault">
							<label class="form-check-label">
								Their partner
							</label>
						</div>
					</div>
				</div>
			</div>
			<div class="row g-3">
				<div class="col-6">
					<div style="background-color: #E2E0DB; padding: 1rem; border-radius: .3rem">
						<div>
							<label class="form-check-label">
								Add Flatmate
							</label>
							<div style="float: right; display: flex; font-size: 1.2em">
								<i class="bx bxs-plus-circle" style="font-size: 1.5em; margin: 0 0.2em; cursor: pointer" @click="this.flatMates.push({partner: false})"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<button style="margin: 1rem  1rem 0 0" class="btn btn-secondary" v-on:click="navigate('/app/welcome/3')">Go Back</button>
		<button style="background-color: #57039F; border-color: #57039F; margin-top: 1rem" class="btn btn-primary" v-on:click="navigate('/app/welcome/5')">Continue</button>
	</div>
</template>


<script>
export default {
	name: "FlatmateSelection",
	data() {
		return {
			partner: false,
			flatMates: [ {partner: false} ]
		}
	},
	computed: {
		flatMatesInStore() {
			return this.$store.getters.getFlatMates;
		}
	},
	watch: {
		flatMatesInStore: {
			handler(newVal) {
				if(newVal) {
					this.partner = newVal.partner ? newVal.partner : false;
					this.flatMates = newVal.flatMates ? newVal.flatMates : [ {partner: false} ];
				}
			},
			immediate: true,
		}
	},
	methods: {
		navigate(newRoute) {
			this.$store.commit('setFlatMates', {
				partner: this.partner,
				flatMates: this.flatMates
			});

			this.$router.push(newRoute);
		},
	},
}
</script>


<style scoped>

</style>
