import {createStore} from "vuex";
import createPersistedState from "vuex-persistedstate";
import {user_store} from "@/store/modules/user_store";
import {furniture_category_1_store} from "@/store/modules/furniture_category_1_store";
import {floorplan_store} from "@/store/modules/floorplan_store";
import {furniture_store} from "@/store/modules/furniture_store";
import {VendorsStore} from "@/store/modules/vendorsStore";
import {furniture_variations_store} from "@/store/modules/furniture_variations_store";
import {preferences_store} from "@/store/modules/preferences_store";
import {styles_store} from "@/store/modules/stylesStore";
import {homeTypeStore} from "@/store/modules/homeTypeStore";
import {roomTypeStore} from "@/store/modules/roomTypeStore";

export const create_store = createStore({
	modules: {
		user: user_store,
		furniture: furniture_store,
		furniture_variations: furniture_variations_store,
		furniture_category_1: furniture_category_1_store,
		floorplan: floorplan_store,
		vendors: VendorsStore,
		preferences: preferences_store,
		styles: styles_store,
		homeTypes: homeTypeStore,
		roomTypes: roomTypeStore
	}
})
