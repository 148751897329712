<template>
	<div>
		<ion-app>
			<ion-content :scroll-y="false">
				<div style="height: 100%; display: flex">
					<div id="app-content" style="width: 100%; height: 100%; overflow-x: auto;">
						<auth-modal></auth-modal>
						<router-view/>
					</div>
				</div>
			</ion-content>
		</ion-app>
		<div class="modal fade" id="confirmDeleteModal" tabindex="1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">Confirm Account Deletion</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						This action <b>CANNOT BE UNDONE</b>. Are you sure you want to delete your account?
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-danger" @click="this.delete">Yes, delete my account</button>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import {IonApp, IonContent} from "@ionic/vue";
import AuthModal from "@/components/common/auth/AuthModal.vue";

export default {
	name: "App-component",
	metaInfo: {
		title: 'Roomy',
		titleTemplate: '%s | Your AI Interior Designer'
	},
	components: {AuthModal, IonContent, IonApp},
	methods: {
		delete() {
			this.$store.commit('deleteUser');
		}
	}
}
</script>


<style scoped>

</style>
