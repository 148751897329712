import {getResource, upsert} from "@/api/RequestHandler";
import {getUser} from "@/components/common/auth/getUser";
import {supabase} from "@/api/supabase";

export const preferences_store = {
	state: () => ({
		id: undefined,
		styles: [],
		homeType: null,
		family: [],
		flatMates: [],
		roomPreferences: [],
	}),
	actions: {
		async upsertPreferences({commit, state}) {
			const user = await getUser()
			const user_id = user.data.user.id

			let preference = {
				home_type: state.homeType,
				user_id: user_id
			}

			preference = await upsert('preference', preference)
			state.id = preference.data[0].id

			let interiorStyles = state.styles.map(style => {
				return {
					preference: preference.data[0].id,
					interior_style: style
				}
			})

			interiorStyles = await upsert('preference_interior_style', interiorStyles)

			if(state.homeType === 3) {
				let family = {
					preference: preference.data[0].id,
					partner: state.family.partner,
					amount_baby: state.family.babies,
					amount_toddler: state.family.toddlers,
					amount_preschooler: state.family.preschoolers,
					amount_gradeschooler: state.family.middleschoolers,
					amount_teen: state.family.teens,
					amount_young_adult: state.family.youngAdults,
				}

				family = await upsert('preference_family', family)
			} else if(state.homeType === 4) {
				let flatmates = {
					preference: preference.data[0].id,
					partner: state.flatMates.partner,
					amount_flatmate: state.flatMates.length,
					amount_flatmate_partner: state.flatMates.filter(flatmate => flatmate.partner).length,
				}

				flatmates = upsert('preference_shared_flat', flatmates)
			}

			let roomPreferences = state.roomPreferences.map(room => {
				return {
					preference: preference.data[0].id,
					room_type: room
				}
			})

			roomPreferences = await upsert('preference_room_type', roomPreferences)

			const { data, error } = await supabase.auth.updateUser({
				data: { welcome_done: true }
			})
		}
	},
	mutations: {
		setPreferencesStyles(state, payload) {
			state.styles = payload
		},
		setHomeType(state, payload) {
			state.homeType = payload
		},
		setFamily(state, payload) {
			state.family = payload
		},
		setFlatMates(state, payload) {
			state.flatMates = payload
		},
		setRoomPreferences(state, payload) {
			state.roomPreferences = payload
		}
	},
	getters: {
		getPreferences(state) {
			if(!state.id) {
				getResource('preference').then(({data, error}) => {
					console.log(data)
				})
			}

			return state;
		},
		getPreferencesStyles(state) {
			return state.styles;
		},
		getHomeType(state) {
			return state.homeType;
		},
		getFamily(state) {
			return state.family;
		},
		getFlatMates(state) {
			return state.flatMates;
		},
		getRoomPreferences(state) {
			return state.roomPreferences;
		}
	}
}
