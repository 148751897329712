import InputHandler from "@/components/editor/features/InputHandler";
import {eventBus} from "@/main";

export default class WindowScaleHandler extends InputHandler {

	constructor() {
		super();
	}

	handleMouseDown(point, window) {
		this.p = point;
		this.window = window;
	}

	handleMouseUp(e, data) {
		eventBus.emit('changeMode', 'select');
	}

	handleMouseMove(e, data) {
		if(this.window.position.ps.x === this.window.position.pe.x) {
			this.p.y = e.y;
		} else {
			this.p.x = e.x;
		}
	}
}
