import {centerOfPolygon} from "@/components/editor/simple-geometry/Operations";

export function drawSvg(context, svg, point, width, height) {
	context.drawImage(svg, point.x, point.y, width, height);
}

export function drawRotatedSvg(context, svg, point, width, height, rotationOrigin, angle) {
	const radians = angle;

	context.translate(point.x, point.y);
	context.rotate(radians);
	context.translate(-point.x, -point.y);
	context.drawImage(svg, point.x - svg.width / 2, point.y - svg.height / 2, width, height);
	context.translate(point.x, point.y);
	context.rotate(-radians);
	context.translate(-point.x, -point.y);
}

export function drawPoint(context, point, color) {
	context.beginPath();
	context.arc(point.x, point.y, 20, 0, 2 * Math.PI);
	context.closePath();
	context.fillStyle = color ? color : '#AA0000';
	context.fill();
}

export function drawRectangle(context, start, width, height, color) {
	context.beginPath();
	context.rect(start.x, start.y, width, height);
	context.closePath();
	context.fillStyle = color ? color : '#000000';
	context.fill();
}

export function drawCross(context, point, color, size, lineWidth = 1) {
	context.beginPath();
	context.moveTo(point.x - size/2, point.y - size/2);
	context.lineTo(point.x + size/2, point.y + size/2);
	context.moveTo(point.x + size/2, point.y - size/2);
	context.lineTo(point.x - size/2, point.y + size/2);
	context.strokeStyle = color ? color : '#000000';
	context.lineWidth = lineWidth;
	context.stroke();
}

export function drawArc(context, center, radius, start, end, color, lineWidth = 1) {
	context.beginPath();
	context.arc(center.x, center.y, radius, start, end);
	context.strokeStyle = color ? color : '#000000';
	context.lineWidth = lineWidth;
	context.stroke();
}

export function drawCircle(context, center, radius, color, lineWidth = 1) {
	context.beginPath();
	context.arc(center.x, center.y, radius, 0, 2 * Math.PI);
	context.strokeStyle = color ? color : '#000000';
	context.lineWidth = lineWidth;
	context.stroke();
}

export function fillCircle(context, center, radius, color, lineWidth = 1) {
	context.beginPath();
	context.arc(center.x, center.y, radius, 0, 2 * Math.PI);
	context.fillStyle = color ? color : '#000000';
	context.lineWidth = lineWidth;
	context.fill();
}

export function drawSegment(context, segment, lineThickness = 1, color) {
	context.beginPath();
	context.moveTo(segment.ps.x, segment.ps.y);
	context.lineTo(segment.pe.x, segment.pe.y);
	context.closePath();
	context.strokeStyle = color ? color : '#000000';
	context.lineWidth = lineThickness;
	context.stroke();
}

export function drawDirectedSegment(context, segment, lineThickness = 1, color) {
	context.beginPath();
	context.moveTo(segment.start.x, segment.start.y);
	context.lineTo(segment.end.x, segment.end.y);
	context.closePath();
	context.strokeStyle = color ? color : '#000000';
	context.stroke();
}

export function drawPolyLine(context, polyline, lineThickness = 1, color) {
	context.beginPath();
	context.moveTo(polyline[0].x, polyline[0].y);
	polyline.slice(1).forEach(vertex => context.lineTo(vertex.x, vertex.y))
	context.strokeStyle = color ? color : '#000000';
	context.stroke();
}

export function drawPolygon(context, polygon, lineThickness = 1, color) {
	context.beginPath();
	context.moveTo(polygon[0].x, polygon[0].y);
	polygon.slice(1).forEach(vertex => context.lineTo(vertex.x, vertex.y))
	context.closePath();
	context.strokeStyle = color ? color : '#000000';
	context.lineWidth = lineThickness;
	context.stroke();
}

export function drawClosed(context, polygon, lineThickness = 1, color) {
	context.beginPath();
	context.moveTo(polygon.points[0].x, polygon.points[0].y);
	polygon.points.slice(1).forEach(vertex => context.lineTo(vertex.x, vertex.y))
	context.closePath();
	context.fillStyle = color ? color : '#DDDDDD';
	context.lineWidth = lineThickness;
	context.fill();
}

export function fillPolygon(context, polygon, color) {
	context.beginPath();
	context.moveTo(polygon[0].x, polygon[0].y);
	polygon.slice(1).forEach(vertex => context.lineTo(vertex.x, vertex.y))
	context.closePath();
	context.fillStyle = color ? color : '#DDDDDD';
	context.fill();
}

export function drawRooms(drawingContext, textContext, rooms, floorPattern, hideArea, scaleFactor, selected) {
	rooms.forEach(polygon => {
		fillPolygonWithPattern(drawingContext, polygon, floorPattern, selected);

		if (scaleFactor < 0.3) {
			hideArea = true;
		}

		if(!hideArea && textContext) {
			const center = centerOfPolygon(polygon);

			textContext.font = 15/scaleFactor + "px Poppins";
			textContext.fontWeight = 400;
			textContext.fillStyle = 'black';
			textContext.fillText((Math.abs(polygon.area() / 10000).toFixed(2) + ' m²'), center.x - 25/scaleFactor, center.y + 5/scaleFactor);
		}
	});
}
let pat = null;
let patSrc = null;

const patterns = {};

export function fillPolygonWithPattern(context, polygon, patternImage, selected) {
	context.beginPath();
	context.moveTo(polygon.points[0].x, polygon.points[0].y);
	polygon.points.slice(1).forEach(vertex => context.lineTo(vertex.x, vertex.y))
	context.closePath();

	if(patterns[patternImage.src]) {
		context.fillStyle = context.createPattern(patterns[patternImage.src], "repeat");
		context.fill();

		if(selected) {
			drawClosed(context, polygon, 5, 'rgba(255, 255, 255, 0.2)');
		}
	} else {
		patternImage.decode().then(() => {
			const pattern = createPattern(context, patternImage);
			context.fillStyle = context.createPattern(pattern, "repeat");
			context.fill();

			if(selected) {
				drawClosed(context, polygon, 5, 'rgba(255, 255, 255, 0.2)');
			}
		});
	}

}


function createPattern(context, patternImage) {
	var patt = document.createElement('canvas');
	patt.width = patternImage.width/3;
	patt.height = patternImage.height/3;
	patt.getContext('2d').drawImage(patternImage, 0,0, patt.width, patt.height);
	pat = patt;
	patSrc = patternImage;
	patterns[patternImage.src] = patt;
	return patt;
}

