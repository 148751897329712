import {drawRotatedSvg, drawSvg} from "@/components/editor/drawing/DrawingHelper";
import {Point} from "@mathigon/euclid";
import {SnapDirection as SnapDirections} from "@/components/editor/drawing/SnapDirections.enum";
import {SnapMode} from "@/components/editor/drawing/SnapMode.enum";
import Vector from "@/components/editor/simple-geometry/Vector";

export class BuiltInFurniture {

	constructor(position) {
		this.position = position;
		this.rotation = 0;
		this.direction = new Vector(0, -1);

		const img = new Image();
		img.src = '/furniture/toilet.svg';

		img.onload = function() {
			img.width = img.width * 0.3;
			img.height = img.height * 0.3;
		}

		this.img = img;
		this.snapDirections = [SnapDirections.BOTTOM];
		this.snapMode = SnapMode.REQUIRED;
	}

	drawOutline(context) {
		const localSnap = this.getLocalSnapPoint();
		drawRotatedSvg(context, this.img, new Point(this.position.x, this.position.y), this.img.width, this.img.height, localSnap, this.rotation);
	}

	drawFilling() {
	}

	getPath() {
	}


	getLocalSnapPoint() {
		switch (this.snapDirections[0]) {
		case SnapDirections.BOTTOM:
			return new Point(0, this.img.height / 2);
		}
	}
}
