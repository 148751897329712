import InputHandler from "@/components/editor/features/InputHandler";

export default class EmptyHandler extends InputHandler {

	constructor() {
		super();
	}

	handleMouseDown(e, data) {
	}

	handleMouseUp(e, data) {
	}

	handleMouseMove(e, data) {
	}
}
