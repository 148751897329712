import InputHandler from "@/components/editor/features/InputHandler";
import Room from "@/components/editor/building-elements/Room";
import Polygon from "polygon";

export default class MoveWallHandler extends InputHandler {

	handleMouseDown(e, wall, floorplan) {
		this.anchor = { x: e.x, y: e.y };
		this.wall = wall;

		const connectedWalls = floorplan.getWalls().filter(w => {
			return w !== wall && (wall.position.containsPoint(w.position.ps) || wall.position.containsPoint(w.position.pe))
		})

		this.connections = connectedWalls.map(w => {
			return wall.position.containsPoint(w.position.ps) ? w.position.ps : w.position.pe;
		})

		// this.wallArray = Array.from(floorplan.walls.keys());
		this.walls = floorplan.walls;
		this.floorplan = floorplan;

		this.rooms = floorplan.rooms;
	}

	handleMouseUp() {
		this.anchor = null;
		this.wall = null;
	}

	handleMouseMove(e, data, floorplan) {
		if(this.anchor && this.wall) {
			if(this.wall.position.ps.x === this.wall.position.pe.x) {
				this.wall.position.ps.x += (e.x - this.anchor.x);
				this.wall.position.pe.x += (e.x - this.anchor.x);

				this.wall.windows.forEach(window => {
					window.position.ps.x = this.wall.position.ps.x;
					window.position.pe.x = this.wall.position.ps.x;
				})
				this.wall.doors.forEach(door => {
					door.position.ps.x = this.wall.position.ps.x;
					door.position.pe.x = this.wall.position.ps.x;
				})

				this.connections.forEach(connection => {
					connection.x += (e.x - this.anchor.x);
				})
			} else {
				this.wall.position.ps.y += (e.y - this.anchor.y);
				this.wall.position.pe.y += (e.y - this.anchor.y);

				this.wall.windows.forEach(window => {
					window.position.ps.y = this.wall.position.ps.y;
					window.position.pe.y = this.wall.position.ps.y;
				})
				this.wall.doors.forEach(door => {
					door.position.ps.y = this.wall.position.ps.y;
					door.position.pe.y = this.wall.position.ps.y;
				})
				this.connections.forEach(connection => {
					connection.y += (e.y - this.anchor.y);
				})
			}

			const connectedRooms = this.rooms.filter(room => {
				return room.walls.includes(this.wall)
			})

			connectedRooms.forEach(room => {
				const wallIndex = room.walls.indexOf(this.wall)
				const points = room.shape.toArray()
				//TODO change so it only goes to the edge of the wall
				points[wallIndex] = this.wall.position.ps
				points[wallIndex === 0 ? points.length-1 : wallIndex-1] = this.wall.position.pe
				room.shape = new Polygon(points)
			})

			this.anchor.x = e.x;
			this.anchor.y = e.y;
		}
	}
}
