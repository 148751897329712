<template>
	<div id="container" style="height: 100%">
		<div style="height: 100%">
			<FloorplanEditor id="Body" style="position: absolute; width: 100%; height: 100vh"></FloorplanEditor>
		</div>
	</div>
</template>

<script>
import FloorplanEditor from "@/components/editor/Editor.vue";

export default {
	name: "designer-page",
	components: {FloorplanEditor}
};
</script>

<style>
</style>
